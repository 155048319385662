// NOTE: ERROR DICT FORMAT
// {
// error.doctype,
// error.docnum,
// error.operator,
// error.amount,
// error.xrefType,
// error.discrepancy
// }

// how to validate Rev xrefs

// orchestrator for export formatted statements
export function validateXrefs(rows = []) {
	console.log("validating Xrefs");
	const client = rows.at(0)?.original.client;

	const xrefErrors = [];

	if (client) {
		rows.forEach((row, i) => {
			const invoice = row.original;
			if (invoice.docType === "REVENUE") {
				const invoiceErrors = handleRevenueRow(invoice, client);
				if (invoiceErrors.length > 0) {
					xrefErrors.push(...invoiceErrors);
				}
			} else if (invoice.docType === "JIB") {
				const invoiceErrors = handleJibRow(invoice, client);
				if (invoiceErrors.length > 0) {
					xrefErrors.push(...invoiceErrors);
				}
			}
		});
	} else {
		console.log("No client found in rows");
	}

	console.log("setting xrefErrors: ", xrefErrors);
	return xrefErrors;
}

// Doc Handlers ------------------------------------------

const handleRevenueRow = (invoice) => {
	const invoiceurl = invoice.invoiceUrl;
	const doctype = invoice.docType;
	const docnum = invoice.docNum;
	const operator = invoice.operator;

	const errors = [];

	invoice.statements.forEach((statement) => {
		// REVENUES HAVE "total" field - JIBS DO NOT
		const amount = statement.total;
		const statementurl = statement.statementUrl;

		// todo - may need to add more cases for revs other than missing value
		// check for cc error
		if (statement.cc === null) {
			errors.push({
				doctype: doctype,
				docnum: docnum,
				operator: operator,
				amount: amount,
				xrefType: "CC",
				discrepancy: "No CC found",
				invoiceurl: invoiceurl,
				statementurl: statementurl,
			});
		} else if (statement.cc.length !== 5) {
			errors.push({
				doctype: doctype,
				docnum: docnum,
				operator: operator,
				amount: amount,
				xrefType: "CC",
				discrepancy: "CC is not 5 characters",
				invoiceurl: invoiceurl,
				statementurl: statementurl,
			});
		}
	});
	return errors;
};

const handleJibRow = (invoice) => {
	const invoiceurl = invoice.invoiceUrl;
	const doctype = invoice.docType;
	const docnum = invoice.docNum;
	const operator = invoice.operator;

	const errors = [];

	invoice.statements.forEach((statement) => {
		// JIBS HAVE "amtOriginal" field - REVENUES DO NOT
		const amount = statement.amtOriginal;
		const statementurl = statement.statementUrl;

		// check for No AFE Xref
		if (statement.afe === "No AFE Xref") {
			errors.push({
				doctype: doctype,
				docnum: docnum,
				operator: operator,
				amount: amount,
				xrefType: "AFE",
				discrepancy: "No AFE Xref",
				invoiceurl: invoiceurl,
				statementurl: statementurl,
			});
		}

		// check for No CC Xref
		if (statement.cc === "No CC Xref") {
			errors.push({
				doctype: doctype,
				docnum: docnum,
				operator: operator,
				amount: amount,
				xrefType: "CC",
				discrepancy: "No CC Xref",
				invoiceurl: invoiceurl,
				statementurl: statementurl,
			});
		}

		// check for CC is null - different from above
		if (statement.cc === null) {
			errors.push({
				doctype: doctype,
				docnum: docnum,
				operator: operator,
				amount: amount,
				xrefType: "CC",
				discrepancy: "CC is null",
				invoiceurl: invoiceurl,
				statementurl: statementurl,
			});
		}


	});
	return errors;
};

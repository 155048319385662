import React, { useState, useEffect } from "react";
import axios from "axios";

/* example schema:
const formSchema = [
  { fieldName: "firstName", displayName: "First Name", dataType: "text" },
  { fieldName: "lastName", displayName: "Last Name", dataType: "text" },
  { fieldName: "email", displayName: "Email", dataType: "email" },
  { fieldName: "age", displayName: "Age", dataType: "number" },
];
*/

const DynamicForm = ({
	schema,
	submitURL,
	onClose,
	handleAppendData,
	data,
}) => {
	const [formData, setFormData] = useState(
		schema.reduce((acc, field) => ({ ...acc, [field.fieldName]: "" }), {})
	);
	const [errors, setErrors] = useState({});
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

	useEffect(() => {
		// Check for errors when form data changes
		const newErrors = {};
		schema.forEach((field) => {
			const error = validateField(field, formData[field.fieldName]);
			if (error) newErrors[field.fieldName] = error;
		});
		setErrors(newErrors);
		setIsSubmitDisabled(Object.keys(newErrors).length > 0);
	}, [formData]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const validateField = (field, value) => {
		if (field.dataType === "number") {
			const parsedValue = parseFloat(value);
			if (isNaN(parsedValue) || parsedValue.toFixed(2) !== value) {
				return `${field.displayName} must be a number with two decimal places.`;
			}
		}
		if (field.formatRegex && !field.formatRegex.test(value)) {
			if (field.fieldName === "cashDate") {
				return `${field.displayName} must be in the format MM/DD/YYYY.`;
			}
			if (field.fieldName === "docDate" || field.fieldName === "receivedDate") {
				return `${field.displayName} must be in the format YYYY-MM-DD.`;
			}
			if (field.fieldName === "amtOriginal") {
				return `${field.displayName} must be a number with two decimal places, commas for thousands, and and enclosing parentheses for negative values.`;
			}
			return `${field.displayName} is invalid.`;
		}
		return "";
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const newErrors = {};
		schema.forEach((field) => {
			const error = validateField(field, formData[field.fieldName]);
			if (error) newErrors[field.fieldName] = error;
		});

		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}

		try {
			let completeFormData = { ...formData };
			if (handleAppendData) {
				completeFormData = handleAppendData(formData, data);
			}
			console.log("Submitting form:", completeFormData);
			const response = await axios.post(submitURL, completeFormData);
			console.log("Form submitted successfully:", response.data);
			onClose();
		} catch (error) {
			console.error("Error submitting form:", error);
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			{schema.map(({ fieldName, displayName, dataType }) => (
				<div className="form-group" key={fieldName}>
					<label htmlFor={fieldName}>{displayName}</label>
					<div className="input-container">
						<input
							type={dataType}
							name={fieldName}
							id={fieldName}
							value={formData[fieldName]}
							onChange={handleChange}
						/>
						{errors[fieldName] && (
							<span className="error">{errors[fieldName]}</span>
						)}
					</div>
				</div>
			))}
			<button type="submit" className="submit-button">
				Submit
			</button>
		</form>
	);
};

export default DynamicForm;

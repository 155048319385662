import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
// third party
import { Stack } from "@mui/material";
import { Person2TwoTone } from "@mui/icons-material";
// custom
import { StateContext } from "../../state-management/app/useContext/StateContext";
// import { DispatchContext } from "../../state-management/app/useContext/DispatchContext"
import { PageContainer } from "../../components/layout/PageContainer";
import { PageTitle } from "../../components/layout/PageTitle";
import { LoadingModal } from "../../components/utils/LoadingModal";
import { ButtonStyled } from "../../components/utils/ButtonStyled";
import { useFetchDocsById } from "../../utils/hooks/useFetchDocsById";
import { useFetchDocById } from "../../utils/hooks/useFetchDocById";
import { useScrapeInvoices } from "../../utils/hooks/webscraping/useScrapeInvoices";
import { TableInvoices } from "./components/TableInvoices";
import { SCRAPETYPE } from "../../utils/constants";
// Cade
import DocumentFormModal from "../../cc_/components/DocumentFormModal";
import axios from "axios";
import UploadStatementsModal from "../../cc_/components/UploadStatementsModal";
import UploadWellIndexModal from "../../cc_/components/UploadWellIndexModal";
import { getCustomStatementsTemplateFromS3 } from "../../cc_/utils/s3client";

const app_env = process.env.REACT_APP_ENV;

export default function Client() {
	const id = useParams().id;
	const appState = useContext(StateContext);
	const [project, setProject] = useState(true); // true = get statements.length (instead of all statements/statementdetails) // change to false for error deeper checking
	const {
		docs: docsOriginal,
		isFetching: isFetchingDocsOriginal,
		isRefetching: isRefetchingDocsOriginal,
		setIsRefetching: setIsRefetchingDocsOriginal,
	} = useFetchDocsById(id, "clientELid", "invoices", {
		project: project,
		consoleLog: true,
	});
	const [docsFinal, setDocsFinal] = useState([]);

	// DOCSORIGINAL MODIFICATIONS
	const { doc: client, isFetching: isFetchingClient } = useFetchDocById(
		id,
		"ELid",
		"clients"
	);

	// ADDITIONAL
	const [isLoadingTable, setIsLoadingTable] = useState(true);
	const { scrapeInvoices, scrapedInvoices, isScrapingInvoices } =
		useScrapeInvoices(SCRAPETYPE.getInvoicesOfClients, client, appState);

	// CREATE DOC MODAL
	// todo - add modal for creating new docs (note - this approach was being used when a form was going to be used to input data for custom statements)
	// const [showModal, setShowModal] = useState(false);
	// const [docType, setDocType] = useState("");
	// const openModal = (docType) => {
	// 	setDocType(docType);
	// 	setShowModal(true);
	// };

	// const closeModal = () => {
	// 	setShowModal(false);
	// 	setDocType("");
	// 	// todo cade - add logic to update docsFinal with new doc
	// };
	// END CREATE DOC MODAL

	const [isWellIndexModalOpen, setWellIndexModalOpen] = useState(false);
	const [isStatementsModalOpen, setStatementsModalOpen] = useState(false);

	const handleOpenModal = (modalType) => {
		if (modalType === "wellIndex") {
			setWellIndexModalOpen(true);
		} else if (modalType === "customDocs") {
			setStatementsModalOpen(true);
		}
	};

	const handleCloseModal = (modalType) => {
		if (modalType === "wellIndex") {
			setWellIndexModalOpen(false);
		} else if (modalType === "customDocs") {
			setStatementsModalOpen(false);
		}
	};

	const handleDownloadFile = async (fileType) => {
		if (fileType === "customDocsTemplate") {
			const customFileData = await getCustomStatementsTemplateFromS3();
			const blob = new Blob([customFileData.Body], {
				type: customFileData.ContentType,
			});
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "CustomStatements-Client-Year-Month.xlsx");
			document.body.appendChild(link);
			link.click();
			link.remove();
		}
	};

	// UPDATE DOCSFINAL WITH DOCSORIGINAL (UNMODIFIED)
	useEffect(() => {
		if (isFetchingDocsOriginal) return;

		if (docsOriginal.length > 0 && modificationsNotPresent(docsOriginal)) {
			// if initial page load (!modificationsPresent) && docs exist, start showing table to user (no modifications present, but they aren't needed until user clicks something)
			setDocsFinal(docsOriginal);
			setIsLoadingTable(false); // start showing table to user
		} else if (docsOriginal.length === 0) {
			// if initial page load && no docs, no modifications to be done (show table -- "no data" version)
			setIsLoadingTable(false);
		}
	}, [docsOriginal]);

	// UPDATE DOCSFINAL WITH MODIFICATIONS
	useEffect(() => {
		if (docsFinal.length === 0) return;

		let modificationsMade = 0;
		let docsModified = docsFinal;

		// Modification 1 (add doc.client property)
		const clientReady = client._id;
		const clientNotPresent =
			clientReady && modificationsNotPresent(docsFinal, "client");
		if (clientNotPresent) {
			docsModified = docsModified.map((doc) =>
				!doc.client ? { ...doc, client: client } : doc
			);
			modificationsMade++;
			// console.log("docsModifiedWithClient", docsModified)
		}

		// Set State (after modifications)
		if (modificationsMade > 0) {
			setDocsFinal(docsModified);
		}
	}, [docsFinal, client]);

	// UPDATE DOCSFINAL WITH NEWLY SCRAPED DOCS (INVOICES)
	useEffect(() => {
		if (scrapedInvoices.length === 0) return;

		const newDocs = [];
		scrapedInvoices.forEach((scrapedInvoice) => {
			if (docsFinal.some((doc) => doc.docNum === scrapedInvoice.docNum)) return;
			newDocs.push(scrapedInvoice);
		});
		if (newDocs.length > 0) {
			setDocsFinal([...docsFinal, ...newDocs]);
		}
	}, [scrapedInvoices]);

	// FUNCTIONS
	function modificationsNotPresent(docs, modification) {
		// modifications should be considered "not present" even if only one doc is missing the modification (could mess up future functions/events for that doc)
		if (modification === "client") {
			const noClient = docs.some((doc) => !doc.client);
			return noClient;
		}
		const noClient = docs.some((doc) => !doc.client);
		const modificationsNotPresent = noClient;
		return modificationsNotPresent;
	}

	// RENDER
	let pageTitle = client.name;
	// {/* TODO:  invoice.errors */}
	let pageTitleComponent = (
		<PageTitle
			icon={<Person2TwoTone sx={{ fontSize: "4rem" }} />}
			pageTitleName={pageTitle}
			contentBelowTitle={
				client._id ? (
					client.initialInvoicesFetched ? (
						<Stack direction="row" gap={2}>
							<ButtonStyled
								onClick={scrapeInvoices}
								disabled={isScrapingInvoices}
								// disabled={true}
								sx={{ ml: 1 }}
							>
								Find New Invoices
							</ButtonStyled>

							<ButtonStyled
								onClick={() => handleOpenModal("customDocs")}
								sx={{ ml: 1 }}
							>
								Add Custom Statements to Cloud
							</ButtonStyled>

							<ButtonStyled
								onClick={() => handleDownloadFile("customDocsTemplate")}
								sx={{ ml: 1 }}
							>
								Download Custom Statements template
							</ButtonStyled>

							<ButtonStyled
								onClick={() => handleOpenModal("wellIndex")}
								sx={{ ml: 1 }}
							>
								Add Well Index to Cloud
							</ButtonStyled>

							{/* <ButtonStyled
								disabled={true}
								onClick={() => openModal("JIB")}
								sx={{ ml: 1 }}
							>
								Create New JIB
							</ButtonStyled> */}

							{/* <ButtonStyled
								disabled={true}
								onClick={() => openModal("REVENUE")}
								sx={{ ml: 1 }}
							>
								Create New Revenue
							</ButtonStyled> */}

							{/* {app_env !== "PROD" && (
								<ButtonStyled
									onClick={async () => {
										const pingres = await axios.get("/python/ping");
										console.log("pingres: ", pingres);
									}}
									sx={{ ml: 1 }}
								>
									Test Ping
								</ButtonStyled>
							)} */}
						</Stack>
					) : (
						<Stack direction="row" gap={2}>
							<ButtonStyled
								onClick={scrapeInvoices}
								disabled={isScrapingInvoices}
								sx={{ ml: 1 }}
							>
								Get Initial Invoices
							</ButtonStyled>
						</Stack>
					)
				) : (
					<></>
				)
			}
		/>
	);

	return (
		<>
			<PageContainer title={pageTitleComponent}>
				<TableInvoices
					mainPage
					docsOriginal={docsFinal} // dataOriginal (MRT)
					setDocsOriginal={setDocsFinal} // setDataOriginal (MRT)
					isLoading={isLoadingTable} // allows skeleton while true
					setIsLoading={setIsLoadingTable}
					// additional data
					client={client}
				/>
			</PageContainer>

			<LoadingModal open={isScrapingInvoices} />
			<LoadingModal open={isRefetchingDocsOriginal} />
			{/* manual doc modal */}
			{/* {showModal && (
				<DocumentFormModal
					docType={docType}
					onClose={closeModal}
					clientELid={id}
				/>
			)} */}
			{/* well index modal */}
			<UploadWellIndexModal
				open={isWellIndexModalOpen}
				onClose={() => handleCloseModal("wellIndex")}
			/>
			{/* statements modal */}
			<UploadStatementsModal
				open={isStatementsModalOpen}
				onClose={() => handleCloseModal("customDocs")}
				clientELid={id}
			/>
		</>
	);
}

import React, { useState, useEffect, useContext } from "react"
import PropTypes  from "prop-types"
import { Box, Stack, Typography } from "@mui/material"
import { keyframes } from '@mui/system'
import { StateContext } from "../../state-management/app/useContext/StateContext"
import { APP_NAME, logoIcon, logoIconDark, logoText, logoTextDark, logoTextWhite } from "../../utils/constants"


export function LogoText({
  // width = "44px",
  // width = "100px",
  width = "80px",
  label = false,
  color = "primary.main",
  typographyVariant = "h6",
  fontWeight = 700,
  animate = false,
  sx,
  whiteVersion = false,
}) {

  // const logoUrl = "https://res.cloudinary.com/dxnehm70c/image/upload/v1661308010/MB_logo_bluem_ikg6lp.png"

  // const logoIcon = "https://res.cloudinary.com/dxnehm70c/image/upload/v1679111946/CCG_logo_icon_lcv5gc.svg"
  // const logoText = "https://res.cloudinary.com/dxnehm70c/image/upload/v1679111946/CCG_logo_text_vso5qp.svg"
  // const logoFull = "https://res.cloudinary.com/dxnehm70c/image/upload/v1679111946/CCG_logo_main_ugpl7x.svg"
  // const logoTextWhite = "https://res.cloudinary.com/dxnehm70c/image/upload/v1679111946/CCG_logo_text_white_r3dkiz.svg"

  const appState = useContext(StateContext)

  // const logoUrl = logoIcon
  let logoUrl = appState.theme === "dark" ? logoTextDark : logoText
  if (whiteVersion) logoUrl = logoTextWhite

  
  // ANIMATION
  // https://stackoverflow.com/questions/58948890/how-to-apply-custom-animation-effect-keyframes-in-mui
  // https://animista.net/play/basic/scale-up/scale-up-center

  const pulse = keyframes`
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  `
  const animation = `${pulse} 1.1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite alternate both`
  

  return (
    <>
      {/* LOGO */}
      <Stack direction="row" alignItems="center" justifyContent="center" gap={1} sx={sx} >
        {/* <Box component="img" sx={{width: width, animation: (animate && animation)}} */}
        <Box component="img" sx={{width: width, animation: (animate && animation)}}
            src={logoUrl} // normal
            // src="https://res.cloudinary.com/dxnehm70c/image/upload/v1661308009/MB_logo_bluem-split_rvqge8.png" // split
          />
        
        {/* <Typography variant="button" sx={{color: "#fff", opacity: 0.8, fontWeight: 600}}> */}

        {/* {label && 
          <Typography 
            // display="none"
            variant={typographyVariant}
            sx={{fontFamily: "Quicksand", color: color, fontWeight: fontWeight, letterSpacing: "1px" }}>
              {APP_NAME}
          </Typography>
        } */}
      </Stack>
    </>
  )
}

// /**
//  * Function to determine if a year is a leap year
//  * @param {number} year - The year to check
//  * @returns {boolean} - true if the year is a leap year, false otherwise
//  */
// function isLeapYear(year) {
//   return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
// }

import { filter } from "lodash";

// /**
//  * Function to get the number of days in a given month and year
//  * @param {number} month - The month (1-12)
//  * @param {number} year - The year
//  * @returns {number} - The number of days in the month
//  */
// function getDaysInMonth(month, year) {
//   switch (month) {
//     case 2:
//       return isLeapYear(year) ? 29 : 28;
//     case 4:
//     case 6:
//     case 9:
//     case 11:
//       return 30;
//     default:
//       return 31;
//   }
// }

// /**
//  * Function to get the start and end dates for a given month and year
//  * @param {number} month - The month (1-12)
//  * @param {number} year - The year
//  * @returns {Object} - An object containing the start and end dates in mm/dd/yyyy format
//  */
// function getMonthDateRange(month, year) {
//   const startDate = `${month.toString().padStart(2, '0')}/01/${year}`;
//   const endDate = `${month.toString().padStart(2, '0')}/${getDaysInMonth(month, year).toString().padStart(2, '0')}/${year}`;
//   return { startDate, endDate };
// }

// export function filterMonthSelect(row, id, filterValue) {
//   const { month, year } = filterValue;
//   const { startDate, endDate } = getMonthDateRange(month, year);
//   return filterCashDates(row, id, [startDate, endDate]);
// }

export function filterCashDates(row, id, filterValue) {
  // console.log(`filterCashDates value: ${filterValue}`);

  const rowValue = row.getValue(id);
  try {

    // TEST FOR CASE WHERE THERE ARE NO FILTER VALUES FIRST
    if (!filterValue[0] && !filterValue[1]) {
      return true;
    }

    // IF THERE IS A FILTER APPLIED BUT NO VALUE IN THE ROW, RETURN FALSE
    if (rowValue === null || rowValue === undefined || rowValue === "") {
      return false;
    }

    if (filterValue[0] && !filterValue[1]) {
      return isDateOrLater(rowValue, filterValue[0]);
    }
    if (!filterValue[0] && filterValue[1]) {
      return isDateOrEarlier(rowValue, filterValue[1]);
    }
    if (filterValue[0] && filterValue[1]) {
      return isWithinDateRange(rowValue, filterValue[0], filterValue[1]);
    }
  } catch (error) {
    if (rowValue !== null) {
      console.log(`error in row id: ${id} with value: ${rowValue}`)
      console.error(error);
      return false;
    } else {
      console.log(`error in row id: ${id} with null value`)
      console.error(error);
      return false;
    }
  }
}

export function isDateOrLater(rowDate, fromDate) {
  rowDate = parseDate(rowDate);
  fromDate = parseDate(fromDate);
  // console.log(`fromDate: ${fromDate} - rowDate: ${rowDate}`);
  return rowDate.getTime() >= fromDate.getTime();
}

export function isDateOrEarlier(rowDate, toDate) {
  rowDate = parseDate(rowDate);
  toDate = parseDate(toDate);
  // console.log(`rowDate: ${rowDate} - toDate: ${toDate}`);
  return rowDate.getTime() <= toDate.getTime();
}

export function isWithinDateRange(date, fromDate, toDate, inclusive = true) {
  return isDateOrLater(date, fromDate) && isDateOrEarlier(date, toDate);
}


function parseDate(dateStr) {
  // Check for 'yyyy-mm-dd' format first
  if (/\d{4}-\d{2}-\d{2}/.test(dateStr)) {
    const [year, month, day] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  // Otherwise, handle the 'm/d/yyyy', 'm/dd/yyyy', 'mm/d/yyyy', 'mm/dd/yyyy' formats
  const dateParts = dateStr.split(/[/-]/);

  // Check if the dateParts array has exactly 3 elements
  if (dateParts.length !== 3) {
    throw new Error('Invalid date format');
  }

  // Parse the parts based on their lengths to handle leading zeros
  let month, day, year;

  if (dateParts[2].length === 4) { // m/d/yyyy, m/dd/yyyy, mm/d/yyyy, mm/dd/yyyy
    month = parseInt(dateParts[0], 10);
    day = parseInt(dateParts[1], 10);
    year = parseInt(dateParts[2], 10);
  } else { // yyyy-mm-dd
    year = parseInt(dateParts[0], 10);
    month = parseInt(dateParts[1], 10);
    day = parseInt(dateParts[2], 10);
  }

  // Check if the parsed values are valid numbers
  if (isNaN(month) || isNaN(day) || isNaN(year)) {
    throw new Error('Invalid date format');
  }

  // Create and return the Date object
  return new Date(year, month - 1, day);
}
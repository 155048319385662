import React from 'react'
import { ForgotPassword as ForgotPasswordComponent } from './components/ForgotPassword'
import { PageContainer } from '../../components/layout/PageContainer'

export default function ForgotPassword() {


  return (
    <>
      <PageContainer justifyContent="center" toolbarSpacing={false}>
        <ForgotPasswordComponent />
      </PageContainer>
    </>
  )
}

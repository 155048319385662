import React from "react"
import { Stack, Typography } from "@mui/material"
import { TextSnippetTwoTone } from "@mui/icons-material"


MRT_NoData.propTypes = {
  
}
export function MRT_NoData({
  docsPlural = "data"
}) {
  
  

  return (
    <>
      <Stack justifyContent="center" alignItems="center" gap={1}>
        <TextSnippetTwoTone
          // onClick={() => {setSelectedDoc({_id: "new", type: "general", createdDate: new Date(), updatedDate: new Date()}); setOpen(true)}}
          sx={{
            color: "#00000015",
            // opacity: 0.6,
            // fontSize: "80px",
            // cursor: "pointer",
            width: "50%!important",
            height: "50%!important",
            maxWidth: 120,
            maxHeight: 120,
            "&:hover": {
              // backgroundColor: "#00000005",
              // color: "#00000030",
            }
          }}
        />
        <Typography variant="body" sx={{fontWeight: 500, opacity: 0.2, mb: "8px"}}>
          No {docsPlural}
        </Typography>
      </Stack>
    </>
  )
}

import React, { useContext } from "react";
// third party
import axios from "axios";
// custom
import { DispatchContext } from "../../state-management/app/useContext/DispatchContext";


export async function deleteInvoiceById(id, controller = undefined) {
  try {
    const response = await axios.delete(`/invoices/${id}`, {
      signal: controller?.signal,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function deleteStatementByInvoiceId(id, controller = undefined) {
  try {
    const response = await axios.delete(`/statements/${id}`, {
      signal: controller?.signal,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
}


export async function deleteInvoiceWithStatements(
  id,
  invoiceId,
  controller = undefined
) {
  try {
    const invoiceRes = await deleteInvoiceById(id, controller);
    if (invoiceId !== "MANUAL") {
      const statementsRes = await deleteStatementByInvoiceId(invoiceId, controller);
    }
    
    if (invoiceRes) {
      return invoiceRes;
    } else {
      throw new Error("Error deleting invoice and statements");
    }
  } catch (e) {
    throw e; // Or handle the error as needed
  }
}

export async function handleDeleteInvoices(table, rows, appDispatch) {
  const promises = rows.map(async (row, i) => {
    const controller = new AbortController();
    const rowRes = await deleteInvoiceWithStatements(
      row.original._id,
      row.original.invoiceId,
      controller
    );
    return rowRes;
  });
  let rowsResults = await Promise.all(promises);
  rowsResults = rowsResults.filter((rowRes) => rowRes); // remove any undefineds
  console.log("rowsResults", rowsResults);
  // cade todo - error check for all rows deleted
  if (rowsResults.length > 0) {
    appDispatch({type: "flashMessage", value: {message: `Successfully deleted ${rowsResults.length} invoices`, severity: "success"} })
    table.resetRowSelection()
  }
  else {
    appDispatch({type: "flashMessage", value: {message: `Error pulling invoice(s).`, severity: "error"} })
  }
}

import React, { useState, useEffect } from "react"
import { Box, Stack, Typography, Button, TextField } from "@mui/material"
import { PageContainer } from "../../components/layout/PageContainer"
import { StateContext } from "../../state-management/app/useContext/StateContext"
import { DispatchContext } from "../../state-management/app/useContext/DispatchContext"
import { AppStateAppDispatch } from "../../components/testing/AppStateAppDispatch"
import Clients from "../clients/Clients"
import { LoadingScreen } from "../../components/utils/LoadingScreen"
import { LoadingModal } from "../../components/utils/LoadingModal"


export function UserDashboard({
  
}) {
  

  return (
    <>
      {/* <PageContainer>
        Logged in home (Dashboard...)
      </PageContainer> */}
      <Clients />
      {/* <LoadingScreen /> */}
    </>
  )
}

import React from "react"
import { useLocation } from "react-router-dom"
import { motion } from "framer-motion"

ComponentTransition.propTypes = {
  
}
export function ComponentTransition({
  children,
}) {
  
  const url = useLocation().pathname

  return (
    <>
        <motion.div
          key={url}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {children}
        </motion.div>
    </>
  )
}

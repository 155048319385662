import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"


// PageHeader.propTypes = {
//   title: PropTypes.string || PropTypes.object,
//   hidden: PropTypes.bool,
// }
export function PageHeader({
  title,
  hidden
}) {

 return (
   <>
      <Box
        mt={2.5}
        mb={2}
        // ml={3}
        pl={3}
        display={hidden && "none"}
        width={1}
        sx={{
          // border: "1px solid red",
        }}
      >
        {title}
    </Box>

   </>
 )
}

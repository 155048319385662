import React, { useContext } from "react"
import PropTypes from "prop-types"
import { colors, Paper, Box, Typography, Button, IconButton, Stack} from "@mui/material"
import {
  EditOutlined, RefreshRounded,
  CheckCircleOutlineRounded, CheckCircleRounded,
} from "@mui/icons-material";
import { StateContext } from "../../state-management/app/useContext/StateContext";


Pod.propTypes = {
  title: PropTypes.string,
  adjacenttitle: PropTypes.string,
  subtitle: PropTypes.string,

  buttons: PropTypes.array,
  hoverOutline: PropTypes.bool,

  sx: PropTypes.object,
  bgColor: PropTypes.string,
  onClick: PropTypes.func,

  classes: PropTypes.object,
  hidden: PropTypes.bool,
}
export function Pod({
  title,
  chip,
  adjacenttitle,
  subtitle,

  buttonVariant = "text",
  buttons = [],
  hoverOutline,

  elevation = 6,
  sx,
  bgColor,
  onClick,

  ref,

  classes,
  hidden = false,

  BoxProps = {},

  children
}) {

  // let buttonVariant = "outlined"
  // let buttonVariant = "contained"

  const appState = useContext(StateContext)

  let icons = {
    edit: <EditOutlined fontSize="small" />,
    refresh: <RefreshRounded fontSize="small" />,
    check: <CheckCircleOutlineRounded fontSize="small" />
  }

  function buttonsStartBeforeAdjacentText(buttons) {
    return displayButtons(buttons.filter(button => button.position == "startBefore" || button.position == null))
  }

  function buttonsStartAfterAdjacentText(buttons) {
    return displayButtons(buttons.filter(button => button.position == "startAfter"))
  }

  function buttonsEnd(buttons) {
    return displayButtons(buttons.filter(button => button.position == "end"))
  }

  function displayButtons(buttons) {
    if (buttons.length == 0) return
    else {
      return (
        <Stack direction="row" mr={3} gap={buttonVariant != "text" && 0.5}>
          {/* ICON BTNS */}
          {buttons.filter(button => button.type == "icon").map(button => ( 
              <IconButton
                variant={button.variant || buttonVariant}
                color={button.color || "primary"}
                size={button.size || "small"}
                component="label"
                onClick={button.onClick}
                disabled={button.disabled || false}
                sx={{
                  marginTop: "-2px"
                }}
              >
                {/* {button.icon && icons[button.icon]} */}
                {button.icon}
              </IconButton>
          ))}
  
          {/* STANDARD BTNS */}
          <Stack direction="row" gap={1}>
            {buttons.filter(button => !button.type).map(button => (
                <Button
                  variant={button.variant || buttonVariant}
                  color={button.color || "primary"}
                  size={button.size || "small"}
                  startIcon={button.icon && icons[button.icon]}
                  onClick={button.onClick || null}
                  disabled={button.disabled || false}
                  sx={{
                    borderRadius: 200,
                    ...button.sx
                  }}
                >
                  {button.label}
                </Button>
            ))}
          </Stack>
        </Stack>
      )
    }
  }

  function displayAdjacentTitle(str) {
    if (!str) return
    return (
      <Typography
        variant="caption"
        display="block"
        pt={0.6}
        pl={1.5}
        pr={buttonVariant != "text" && 0.5}
        // color={colors.neutral}
        color={"neutral.main"}
      >
        {str}
      </Typography>
      )
  }

  return (
      <Paper
        ref={ref}
        classes={classes}
        onClick={onClick}
        elevation={elevation}
        sx={{
          display: hidden ? "none" : "inline-block", // 'hidden' only works in sx, not display prop for some reason
          borderRadius: 4,
          backgroundColor: bgColor ? bgColor : appState.theme == "dark" ? "transparent" : "#fff",
          border: hoverOutline && 2,
          borderColor: hoverOutline && "white",
          ':hover': {
            // boxShadow: 20, // theme.shadows[20]
            // boxShadow: 0, // theme.shadows[20]
            border: hoverOutline && 2,
            borderColor: hoverOutline && "primary.lightest",
          },
          p: "1rem",
          pt: "0.9rem",
          ...sx,
        }}
      >
        <Box
          // p={"1rem"}
          // pt={"0.9rem"}
          {...BoxProps}
        >
          {(title || buttons.length > 0) &&
            <Stack
              direction="row"
              spacing={1}
              mb={1.25} 
              // divider={<Divider orientation="vertical" flexItem />}
            >
              {title && 
                <Typography 
                  fontWeight={600}
                  fontSize={"1rem"}
                  variant="button" 
                  whiteSpace="nowrap"
                  // variant="overline" 

                  display="block" 
                  // color="primary.main" 
                  // gutterBottom
                >
                  {title}
                </Typography>
              }
              {chip && chip}
              {buttons.length > 0 && 
                <Stack
                  direction="horizontal"
                  flexGrow={1}
                  sx={{
                    justifyContent: "space-between"
                  }}
                >
                  <Stack
                    direction="horizontal"
                  >
                    {buttonsStartBeforeAdjacentText(buttons)}
                    {displayAdjacentTitle(adjacenttitle)}
                    {buttonsStartAfterAdjacentText(buttons)}
                  </Stack>
                  <Stack>
                    {buttonsEnd(buttons)}
                  </Stack>
                </Stack>
              }
            </Stack>
          }
          {children}
        </Box>
      </Paper>
  )
}


import { MenuItem, TextField } from "@mui/material"
import { createRef, useEffect, useRef, useState } from "react"


export function TextFieldStyled({
  value,
  setValue,
  autoFocus = false,
  fullWidth = false,
  multiline = false,
  maxRows = 4,
  select = false,
  selectArray = [],
  label = "Label",
  size = "small",
  inputRef,
  onChange = (e) => setValue(e.target.value),
  onKeyDown,
  property,
  sx = {}
}) {
  // const [value, setValue] = useState(initialValue)

  return (
    

    <TextField
      autoFocus={autoFocus}
      inputRef={inputRef}
      fullWidth={fullWidth}
      multiline={multiline}
      maxRows={maxRows}
      select={select}
      label={label}
      size={size}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      sx={{
        // width: "120px",
        // '& legend': { display: 'none' },
        // '& fieldset': { top: 0 },
        ...sx
      }}
    >
      {selectArray && selectArray.map(el => <MenuItem value={el}>{el}</MenuItem>)}
    </TextField>
  )
}




{/* <TextField
  // fullWidth
  label="Township"
  size="small"
  value={updatedDoc.t}
  onChange={(e) => setUpdatedDoc({...updatedDoc, t: e.target.value})}
  select

  >
  {appState.townships.map(t => <MenuItem value={t}>{t}</MenuItem>)}
</TextField> */}
export function handleErrorCatching(e, requestType, dispatch = () => {}) {
  // pass appDispatch (for dispatch) as needed for flash messages, etc.

  if (e.code == "ERR_CANCELED") {
    return
  }
  else {
    console.log(`ERROR (${requestType})`, e)

    if (requestType == "checkToken") {
      dispatch({type: "flashMessage", value: "Your session has expired. Please log in again..."})
    }

    console.log(`There was a problem or the request was cancelled. (${requestType})`)
  }
  
}
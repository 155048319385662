export function convertPercentStringToDecimal(str="") {
  if (!str) return ""

  try {
    str = str.replace("%", "").trim()
    let num = Number(str) / 100
    return num
  }
  catch {
    return ""
  }
}
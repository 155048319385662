import React from "react";
import "./Modal.css";

const SimpleModal = ({ children, onExit = null }) => {
	return (
		<div className="modal">
			<div className="model-container">
				<div className="modal-content">
					{onExit && (
						<span className="exit-x" onClick={onExit}>
							&times;
						</span>
					)}
					{children}
				</div>
			</div>
		</div>
	);
};

export default SimpleModal;

import React, { useState, useMemo, useContext } from "react";
import { Stack, Typography, Skeleton } from "@mui/material";
import {
	Box,
	Button,
	TextField,
	MenuItem,
	Modal,
	FormControl,
	InputLabel,
	Select,
} from "@mui/material";

import MaterialReactTable from "material-react-table";
import {
	AutorenewRounded,
	FileDownloadRounded,
	DeleteForever,
	Rule,
} from "@mui/icons-material";
import { getFacetedUniqueValues } from "@tanstack/react-table";
import { set } from "lodash";
// custom
import { ButtonStyled } from "./ButtonStyled";
import { handleExportRows, handlePullRows } from "../../utils/JsonToCsv";
import { handlePrintCsvMany } from "../../utils/handlePrintCsv";
import { LoadingModal } from "./LoadingModal";
import { StateContext } from "../../state-management/app/useContext/StateContext";
import { DispatchContext } from "../../state-management/app/useContext/DispatchContext";
import { getDocById } from "../../services/axios";
import { getInvoiceWithStatements } from "../../utils/hooks/getInvoiceWithStatements";
// import { ViewSingleDoc } from "../../zSimpleDocumentFunctionality/components/ViewSingleDoc"; // GENERIC/PLACEHOLDER/TEMPLATE
// cc
import { handleDeleteInvoices } from "../../utils/hooks/deleteInvoiceWithStatements";
import { getObjectSchema } from "../../cc_/utils/getSchema";
import { handleFormattedCsvExport } from "../../cc_/hooks/exportFormattedStatements";
import SimpleModal from "../../cc_/components/SimpleModal";
import XrefModalTable from "../../cc_/components/XrefModalTable";
import { validateXrefs } from "../../cc_/hooks/validateXrefs";

export function MRT({
	// associatedPage = {},
	renderModals = true,
	tableType = "",
	data = [],
	dataOriginal = [], // not used for MRT (just for updating documents/notes after creating new ones? (maybe deleting/updating, too))
	setDataOriginal = () => {}, // not used for MRT (just for updating documents/notes after creating new ones? (maybe deleting/updating, too))
	columns,
	isLoading,
	setIsLoading,
	isRefetching,
	setIsRefetching,
	defaultColumnVisibility = {},
	columnVisibility = {},
	setColumnVisibility = () => {},
	tableView = "table",
	setTableView = () => {},
	rowActions = [],
	rowActionMenuItems = [],
	enableRowVirtualization = true, // TODO (possibly change to true?)
	enableColumnActions = false,
	enableColumnFilterModes = false,
	enableToolbarInternalActions = true,
	enableGlobalFilter = true,
	enableFilters = true,
	enableHiding = true,
	enableDensityToggle = true,
	enableFullScreenToggle = true,
	enableRowSelection = false,
	enableExpanding = false,
	enableRowNumbers = false,
	enablePagination = false,
	enableTableHead = true,
	enableTopToolbar = true,
	enableColumnResizing = true,
	enableBottomToolbar = false,
	enableRowHover = true,
	estimatedNumOfRows = 6,
	initialDensity = "compact",
	open = false,
	setOpen = () => {},
	isDocOpen, // VSDoc (GENERIC/PLACEHOLDER/TEMPLATE)
	setIsDocOpen, // VSDoc (GENERIC/PLACEHOLDER/TEMPLATE)
	selectedDoc = {},
	setSelectedDoc = () => {},
	globalButtons = <></>,
	filterDocs,
	filterDocsStatus,
	isFetchingLocally,
	setIsFetchingLocally = () => {},
	handleClick = () => {},
}) {
	// SKELETON LOADERS (https://github.com/TanStack/table/discussions/2386)
	const tableData = useMemo(
		() => (isLoading ? Array(estimatedNumOfRows).fill({}) : data),
		[isLoading, data]
	);
	const tableColumns = useMemo(
		() =>
			isLoading
				? columns.map((column) => ({
						...column,
						Cell: <Skeleton />,
				  }))
				: columns,
		[isLoading, columns]
	);

	// STATE
	// const [showGlobalFilter, setShowGlobalFilter] = useState(initialShowGlobalFilter)
	const [isError, setIsError] = useState(false);
	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState("");
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 2000, // creates MRT error (max = 100... but this allows all rows to be shown at once (required for certain CSV exports))
	});
	const [modalOpen, setModalOpen] = useState(false);
	const [displayXRows, setDisplayXRows] = useState(Infinity);
	const [displayedData, setDisplayedData] = useState(tableData);
	const [showFileModal, setShowFileModal] = useState(false);
	const [resolveUpload, setResolveUpload] = useState(null);
	const [showXrefModal, setShowXrefModal] = useState(false);
	const handleToggleXrefModal = () => setShowXrefModal(!showXrefModal);
	const [xrefErrors, setXrefErrors] = useState([]);
	const [customStatementsYear, setCustomStatementsYear] = useState("");
	const [customStatementsMonth, setCustomStatementsMonth] = useState(0);
	const [showCustomStatementsModal, setShowCustomStatementsModal] =
		useState(false);
	const [resolveCustomStatements, setResolveCustomStatements] = useState(null);
	const [cancelExport, setCancelExport] = useState(false);

	// CONTEXT
	const appState = useContext(StateContext);
	const appDispatch = useContext(DispatchContext);

	// GLOBALS
	const MAX_ROWS_PULLING = 30;
	const MAX_ROWS_EXPORTING = Infinity;
	const MAX_ROWS_FORMATTED_EXPORTING = 100;
	const MAX_ROWS_XREF_VALIDATION = 100;
	const months = [
		{ name: "January", value: 1 },
		{ name: "February", value: 2 },
		{ name: "March", value: 3 },
		{ name: "April", value: 4 },
		{ name: "May", value: 5 },
		{ name: "June", value: 6 },
		{ name: "July", value: 7 },
		{ name: "August", value: 8 },
		{ name: "September", value: 9 },
		{ name: "October", value: 10 },
		{ name: "November", value: 11 },
		{ name: "December", value: 12 },
	];
	const currentYear = new Date().getFullYear();
	const minYear = 2000;

	// FUNCTIONS
	function maxSelectedRowsReached(table, type) {
		const selectedRows = table.getSelectedRowModel();
		let maxSelectedRows = Infinity;
		if (type == "pull") {
			maxSelectedRows = MAX_ROWS_PULLING;
			// table.getSelectedRowModel()
		} else if (type == "export") {
			maxSelectedRows = MAX_ROWS_EXPORTING; // was 200 (Ross asked about why maxed 200 JIBs export)
		}

		const maxReached = selectedRows.rows.length > maxSelectedRows;

		return { maxReached, maxSelectedRows };
	}

	function isMaxFormattedSelectedRowsReached(table) {
		const selectedRows = table.getSelectedRowModel();
		const maxReached = selectedRows.rows.length > MAX_ROWS_FORMATTED_EXPORTING;
		return maxReached;
	}

	function isMaxRowsXrefValidationReached(table) {
		const selectedRows = table.getSelectedRowModel();
		const maxReached = selectedRows.rows.length > MAX_ROWS_XREF_VALIDATION;
		return maxReached;
	}

	function moreThanOneTypeOfInvoice(rows = []) {
		const docTypes = [];
		rows.forEach((row) => {
			row = row.original; //  get row's/doc's actual data
			if (!docTypes.includes(row.docType)) {
				docTypes.push(row.docType);
			}
		});
		return docTypes.length > 1;
	}

	// EVENT HANDLERS
	async function handleExportInvoices(rows) {
		const promises = rows.map(async (row, i) => {
			const client = { ...row.original.client };
			const controller = new AbortController();
			row.original = await getInvoiceWithStatements(
				row.original._id,
				controller
			);
			row.original.client = client;
			return row;
		});
		rows = await Promise.all(promises);

		handlePrintCsvMany(rows, setIsFetchingLocally);
	}

	// ---- cade handlers
	const handleYearChange = (event) => {
		console.log("year", event.target.value);
		setCustomStatementsYear(event.target.value);
	};

	const handleMonthChange = (event) => {
		console.log("month", event.target.value);
		setCustomStatementsMonth(event.target.value);
	};

	const handleCustomStatementsSelection = (selectionType) => {
		if (selectionType === "none") {
			if (resolveCustomStatements) {
				// NOTE: DO NOT resolve with empty object, as it will cause an error in the export function
				resolveCustomStatements({ year: "", month: 0});
			}
			setShowCustomStatementsModal(false);
			return;
		}
		if (!customStatementsYear || !customStatementsMonth) {
			alert("Please select a year and month");
			return;
		}
		if (customStatementsYear < minYear || customStatementsYear > currentYear) {
			alert("Year must be between 2000 and current year");
			console.log("Year must be between 2000 and current year");
			return;
		}
		if (resolveCustomStatements) {
			resolveCustomStatements({
				year: customStatementsYear,
				month: customStatementsMonth,
			});
		}
		setShowCustomStatementsModal(false);
	};

	const waitForCustomStatementsSelection = () => {
		return new Promise((resolve) => {
			setResolveCustomStatements(() => resolve);
			setShowCustomStatementsModal(true);
		});
	};

	const handleExitCustomStatementsModal = () => {
		setCancelExport(true);
		setShowCustomStatementsModal(false);
		if (resolveCustomStatements) {
			resolveCustomStatements(null);
		}
	};

	const handleFileUpload = (event) => {
		const referenceFile = event.target.files[0];
		if (resolveUpload) {
			resolveUpload(referenceFile);
		}
		setShowFileModal(false);
	};

	const handleExitFileModal = () => {
		setCancelExport(true);
		setShowFileModal(false);
		if (resolveUpload) {
			resolveUpload(null);
		}
	};

	const handleCloudWellIndexSelection = () => {
		if (resolveUpload) {
			resolveUpload("s3");
		}
		setShowFileModal(false);
	};

	const waitForFileUpload = () => {
		return new Promise((resolve) => {
			setResolveUpload(() => resolve);
			setShowFileModal(true);
		});
	};

	async function handleExportFormatted(rows) {
		const wellIndexReference = await waitForFileUpload();
		if (!wellIndexReference) {
			console.log("no well index reference - must have cancelled");
			return;
		}
		const customStatements = await waitForCustomStatementsSelection();
		if (customStatements === null) {
			console.log("no custom statements - must have cancelled");
			return;
		}

		setIsFetchingLocally(true);
		const promises = rows.map(async (row, i) => {
			const client = { ...row.original.client };
			const controller = new AbortController();
			row.original = await getInvoiceWithStatements(
				row.original._id,
				controller
			);
			row.original.client = client;
			return row;
		});
		rows = await Promise.all(promises);
		console.log("resolved fetching rows");

		handleFormattedCsvExport(
			rows,
			setIsFetchingLocally,
			wellIndexReference,
			customStatements.year,
			customStatements.month
		);
	}

	async function handleXrefValidation(rows) {
		setIsFetchingLocally(true);
		const promises = rows.map(async (row, i) => {
			const client = { ...row.original.client };
			const controller = new AbortController();
			row.original = await getInvoiceWithStatements(
				row.original._id,
				controller
			);
			row.original.client = client;
			return row;
		});
		rows = await Promise.all(promises);

		const errors = validateXrefs(rows, setIsFetchingLocally, setXrefErrors);
		setIsFetchingLocally((prev) => false);
		setXrefErrors((prevErrors) => errors);
		setShowXrefModal((prev) => true);
	}

	return (
		<>
			<MaterialReactTable
				enableColumnFilterModes={true}
				localization={{
					noRecordsToDisplay:
						tableType == "invoices"
							? "No invoices set for this well."
							: "No data found.",
				}}
				data={tableData}
				columns={tableColumns}
				getFacetedUniqueValues={getFacetedUniqueValues()}
				muiTableProps={{
					sx: {
						tableLayout: "fixed",
					},
				}}
				muiTableBodyProps={({ table, row }) => ({
					sx: {
						"&& .MuiTableRow-hover:hover": {
							backgroundColor: appState.theme == "dark" && "#ffffff05",
						},
					},
				})}
				muiTableBodyRowProps={({ table, row }) => ({
					hover: enableRowHover,
				})}
				displayColumnDefOptions={{
					// https://www.material-react-table.com/docs/guides/display-columns
					"mrt-row-select": {
						size: 40,
					},
					"mrt-row-actions": {
						size: 100,
						muiTableHeadCellProps: {
							// align: 'center', //change head cell props
						},
					},
				}}
				// ENABLING
				enableBottomToolbar={enableBottomToolbar}
				enableColumnResizing={enableColumnResizing}
				enableTableHead={enableTableHead}
				enableTopToolbar={enableTopToolbar}
				enableToolbarInternalActions={enableToolbarInternalActions}
				enableGlobalFilter={enableGlobalFilter}
				enableFilters={enableFilters}
				enableHiding={enableHiding}
				enableDensityToggle={enableDensityToggle}
				enableFullScreenToggle={enableFullScreenToggle}
				// CONDITIONAL RENDERING
				enablePagination={enablePagination}
				enableRowVirtualization={enableRowVirtualization}
				enableColumnActions={enableColumnActions}
				enableExpanding={enableExpanding}
				renderDetailPanel={enableExpanding && (({ row }) => <></>)}
				enableRowActions={
					rowActionMenuItems.length || rowActions.length ? true : false
				}
				renderRowActions={rowActions.length && (({ row }) => <div></div>)}
				renderRowActionMenuItems={
					rowActionMenuItems.length && (({ closeMenu }) => [<></>])
				}
				enableRowSelection={enableRowSelection}
				enableRowNumbers={enableRowNumbers}
				enableStickyHeader
				// Fixes vertical alignment for Table Footer pagination labels
				muiTablePaginationProps={{
					sx: {
						"& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
							{
								margin: "auto",
							},
					},
					rowsPerPageOptions: [10, 30, 50, 100, 1000, 2000],
					// showFirstButton: false,
					// showLastButton: false,
				}}
				muiTableContainerProps={{ sx: { maxHeight: "70vh" } }} // was previously trying to use maxHeight prop -- was causing SLOW RENDERING/TABLE ACTIONS (unsure exactly why...)
				getRowId={(row) => row._id}
				muiToolbarAlertBannerProps={
					isError
						? {
								color: "error",
								children: "Error loading data",
						  }
						: undefined
				}
				onColumnFiltersChange={setColumnFilters}
				onGlobalFilterChange={setGlobalFilter}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				initialState={{
					showColumnFilters: true,
					density: initialDensity,
				}}
				state={{
					columnFilters,
					globalFilter,
					isLoading,
					pagination,
					showSkeletons: isRefetching || isLoading,
					showAlertBanner: isError,
					showProgressBars: isRefetching || isLoading,
					columnVisibility: columnVisibility,
					sorting,
				}}
				onColumnVisibilityChange={setColumnVisibility}
				renderTopToolbarCustomActions={({ table }) => {
					// https://www.material-react-table.com/docs/examples/data-export
					const totalRows = data.length; // TODO: should use appropriate MRT/TanStack table method instead...
					const filteredRows = table.getFilteredRowModel().rows;
					const filteredRowsLength = filteredRows.length;
					const selectedRows = table.getSelectedRowModel().rows;
					const notYetPulled = selectedRows.some(
						(row) =>
							!row.original.statements || row.original.statements.length == 0
					);
					const moreThanOneTypeOfInvoiceBool = moreThanOneTypeOfInvoice(
						table.getSelectedRowModel().rows
					);

					return tableType === "invoices" ? (
						<Stack
							direction="row"
							gap={1}
							alignItems="center"
							// sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
						>
							<Typography variant="body2" sx={{ opacity: 0.6, mr: 1 }}>
								{isLoading
									? "Loading..."
									: `Showing ${filteredRowsLength} of ${totalRows} rows`}
							</Typography>

							{/* PULL BUTTON */}
							<ButtonStyled
								disabled={
									(!table.getIsSomeRowsSelected() &&
										!table.getIsAllRowsSelected()) ||
									maxSelectedRowsReached(table, "pull")?.maxReached ||
									isFetchingLocally
								}
								onClick={() => {
									setIsFetchingLocally(true);
									handlePullRows(
										table,
										setDataOriginal,
										setIsFetchingLocally,
										appDispatch
									);
								}}
								startIcon={<AutorenewRounded />}
								variant="contained"
							>
								{isFetchingLocally
									? "Pulling Rows..."
									: maxSelectedRowsReached(table, "pull").maxReached
									? `Pull Selected Rows (max ${
											maxSelectedRowsReached(table, "pull").maxSelectedRows
									  })`
									: `Pull Selected Rows`}
							</ButtonStyled>

							{/* EXPORT BUTTON */}
							<ButtonStyled
								disabled={
									(!table.getIsSomeRowsSelected() &&
										!table.getIsAllRowsSelected()) ||
									maxSelectedRowsReached(table, "export")?.maxReached ||
									isFetchingLocally ||
									notYetPulled ||
									moreThanOneTypeOfInvoiceBool
								}
								onClick={() => {
									setIsFetchingLocally(true);
									handleExportInvoices(table.getSelectedRowModel().rows);
								}}
								startIcon={<FileDownloadRounded />}
								variant="contained"
							>
								{isFetchingLocally
									? "Exporting Selected Rows..."
									: maxSelectedRowsReached(table, "export").maxReached
									? `Export Selected Rows (max ${
											maxSelectedRowsReached(table, "export").maxSelectedRows
									  })`
									: notYetPulled
									? `Export Selected Rows (all rows must be pulled)`
									: moreThanOneTypeOfInvoiceBool
									? `Export Selected Rows (cannot export JIB & REVENUE)`
									: `Export Selected Rows`}
							</ButtonStyled>

							{/* EXPORT FORMATTED BUTTON */}
							<ButtonStyled
								disabled={
									(!table.getIsSomeRowsSelected() &&
										!table.getIsAllRowsSelected()) ||
									isMaxFormattedSelectedRowsReached(table) ||
									isFetchingLocally ||
									notYetPulled
								}
								onClick={async () => {
									// todo cade
									handleExportFormatted(table.getSelectedRowModel().rows);

									// ---
									// console.log(getObjectSchema(table.getSelectedRowModel().rows));
									// let invoice = table.getSelectedRowModel().rows.at(0).original;
									// const client = { ...invoice.client };
									// const controller = new AbortController();
									// invoice = await getInvoiceWithStatements(invoice._id, controller);
									// invoice.client = client;
									// console.log("statement", invoice.statements.at(0));
									// ---
								}}
								startIcon={<FileDownloadRounded />}
								variant="contained"
							>
								{isFetchingLocally
									? "Exporting Selected Rows..."
									: isMaxFormattedSelectedRowsReached(table)
									? `Formatted Export Selected Rows (max ${isMaxFormattedSelectedRowsReached(
											table
									  )})`
									: notYetPulled
									? `Formatted Export Selected Rows (all rows must be pulled)`
									: `Formatted Export Selected Rows`}
							</ButtonStyled>

							{/* VALIDATE XREFS BUTTON */}
							<ButtonStyled
								disabled={
									(!table.getIsSomeRowsSelected() &&
										!table.getIsAllRowsSelected()) ||
									isMaxRowsXrefValidationReached(table) ||
									isFetchingLocally ||
									notYetPulled
								}
								onClick={() => {
									handleXrefValidation(table.getSelectedRowModel().rows);
								}}
								startIcon={<Rule />}
								variant="contained"
								sx={{ ml: 1 }}
							>
								Validate Xrefs
							</ButtonStyled>

							{globalButtons}

							{/* DELETE BUTTON */}
							<ButtonStyled
								disabled={
									(!table.getIsSomeRowsSelected() &&
										!table.getIsAllRowsSelected()) ||
									maxSelectedRowsReached(table, "delete")?.maxReached ||
									isFetchingLocally
								}
								onClick={() => {
									setIsFetchingLocally(true);
									handleDeleteInvoices(
										table,
										table.getSelectedRowModel().rows,
										appDispatch
									);
									setIsFetchingLocally(false);
								}}
								startIcon={<DeleteForever />}
								variant="contained"
							>
								{isFetchingLocally
									? "DELETE Selected Rows..."
									: maxSelectedRowsReached(table, "export").maxReached
									? `DELETE Selected Rows (max ${
											maxSelectedRowsReached(table, "export").maxSelectedRows
									  })`
									: `DELETE Selected Rows`}
							</ButtonStyled>
						</Stack>
					) : (
						<Stack direction="row" gap={1} alignItems="center">
							<Typography variant="body2" sx={{ opacity: 0.6, mr: 1 }}>
								{isLoading
									? "Loading..."
									: `Showing ${filteredRowsLength} of ${totalRows} rows`}
							</Typography>

							{globalButtons}
						</Stack>
					);
				}}
			></MaterialReactTable>

			{/* MODAL FOR FILE UPLOAD */}
			{showFileModal && (
				<SimpleModal
					onExit={() => {
						handleExitFileModal();
					}}
				>
					<div className="modal-content">
						<h2>Well Index Reference File</h2>
						<p>Use Cloud stored Well Index</p>
						<Button
							variant="contained"
							color="primary"
							onClick={handleCloudWellIndexSelection}
						>
							Use Cloud Well Index
						</Button>
						<p>OR</p>
						<p>Upload a file</p>
						<input type="file" onChange={handleFileUpload} />
					</div>
				</SimpleModal>
			)}
			{showCustomStatementsModal && (
				<SimpleModal onExit={() => handleExitCustomStatementsModal()}>
					<div className="modal-content">
						<Typography id="modal-title" variant="h6" component="h2">
							Add Custom Statements (optional)
						</Typography>

						<Box component="form" sx={{ mt: 2 }}>
							{/* <TextField
								fullWidth
								label="Year"
								value={customStatementsYear}
								onChange={handleYearChange}
								margin="normal"
								type="number"
								inputProps={{ min: 1900, max: new Date().getFullYear() }}
							/> */}

							<div
								style={{
									display: "flex",
									width: "100%",
									border: "1px solid #cccccc50",
									padding: "12px",
									borderRadius: "5px",
									marginTop: "12px",
									alignContent: "center",
									justifyContent: "center",
									alignItems: "center",
									gap: "12px",
								}}
							>
								<label htmlFor="yearInput">Year:</label>
								<input
									type="number"
									id="yearInput"
									value={customStatementsYear}
									onChange={handleYearChange}
									style={{
										width: "100%",
										border: "1px solid #ccccccc50",
										padding: "12px",
										borderRadius: "5px",
										backgroundColor: "#aaaaaa50",
										color: "#cccccc",
										fontSize: "16px",
									}}
								/>
							</div>
							<FormControl fullWidth margin="normal">
								<InputLabel>Month</InputLabel>
								<Select
									value={customStatementsMonth}
									onChange={handleMonthChange}
									// displayEmpty
								>
									<MenuItem key={0} value={0}>
										<em>None</em>
									</MenuItem>
									{months.map((month) => (
										<MenuItem key={month.value} value={month.value}>
											{month.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<Button
								variant="outlined"
								color="primary"
								fullWidth
								onClick={() => handleCustomStatementsSelection("custom")}
								sx={{ mt: 2 }}
							>
								Use Custom Statements
							</Button>
						</Box>

						<Button
							variant="contained"
							color="primary"
							fullWidth
							onClick={() => handleCustomStatementsSelection("none")}
							sx={{ mt: 4 }}
						>
							DO NOT Use Custom Statements
						</Button>
					</div>
				</SimpleModal>
			)}

			{/* MODAL FOR XREF VALIDATION */}
			{showXrefModal && (
				<SimpleModal onClose={() => setShowXrefModal(false)}>
					<XrefModalTable xrefErrors={xrefErrors} />
					<button onClick={() => setShowXrefModal(false)}>Exit</button>
				</SimpleModal>
			)}

			{renderModals && (
				<>
					{/* <LoadingModal open={isFetching} setOpen={setIsFetching} /> */}
					{/* <ViewSingleDocument doc={selectedDoc} open={open} setOpen={setOpen} documents={dataOriginal} setDocuments={setDataOriginal} />
          <ViewSingleNote doc={selectedDoc} open={isNoteOpen} setOpen={setIsNoteOpen} notes={dataOriginal} setNotes={setDataOriginal} />
          <ViewSingleDoc doc={selectedDoc} open={isDocOpen} setOpen={setIsDocOpen} docs={dataOriginal} setDocs={setDataOriginal} /> */}
				</>
			)}
		</>
	);
}

import React, { useContext } from "react"
import { Card, Modal, Stack, Typography } from "@mui/material"
import { Logo } from "./Logo"
import { COLORS } from "../../utils/constants"
import { StateContext } from "../../state-management/app/useContext/StateContext"



export function LoadingModal({
  open,
  setOpen = ()=>{}
}) {

  const appState = useContext(StateContext)

  return (
    <>
      <Modal
        open={open}
        // onClose={() => setOpen(false)}
        sx={{
          color: "transparent",
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0,0,0,0.2)",
            // backgroundColor: appState.theme == "dark" ? "rgba(0,0,0,0.2)" : "rgba(255,255,255,0.2)"
          },
          "& .MuiPaper-root": {
            backgroundColor: "transparent",
            backgroundImage: "none",
            boxShadow: "none"
          }
        }}
      >
        <Card
          sx={{ 
            position: 'absolute',
            top: '15%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: {xs: "70%", sm: "400px"},
            width: {sm: "400px"},
            backgroundColor: "transparent"
          }}
        >

          <Stack alignItems="center" gap={2} >
            <Logo animate={true} />
            {/* <Typography
              sx={{
                color: COLORS.PRIMARY.main,
                fontWeight: 600,
                fontSize: "1.2rem"
              }}
            >
              Loading...
            </Typography> */}
          </Stack>

        </Card>
      </Modal>
    </>
  )
}

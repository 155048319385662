import { getDocs } from '../../services/axios'
import { useState, useEffect } from 'react'
import { handleErrorCatching } from '../handleErrorCatching'


export function useFetchDocsMany(collection, options={}) {  
  const {
    project = false,
    consoleLog = false, 
    sortFn = null
  } = options

  const [docs, setDocs] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    if (isFetching) {
      const controller = new AbortController()
      async function fetchDocs() {
        try {
          let fetchedDocs = await getDocs(collection, controller, consoleLog)
          if (sortFn) {
            fetchedDocs = fetchedDocs.sort(sortFn)
          }
          setDocs(fetchedDocs)
        } catch(e) {
          handleErrorCatching(e, collection)
        }
        finally {
          setIsFetching(false)
        }
      }
      fetchDocs()
      return () => {
        controller.abort()
      }
    }
  }, [])

  return { docs, isFetching }
}

import React, { useState, useEffect } from "react"
import PropTypes  from "prop-types"
import HomeLoggedOut from "../../pages/home/HomeLoggedOut"
import { Box, Stack, Typography } from "@mui/material"
import { ComponentTransition } from "./animations/ComponentTransition"

SiteLoggedOut.propTypes = {
  
}
export function SiteLoggedOut({
  
}) {

  return (
    <>
      <ComponentTransition>
        <HomeLoggedOut />
      </ComponentTransition>
    </>
  )
}

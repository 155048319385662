import React from "react"
import { Stack, Tooltip, Typography } from "@mui/material"


export function TypographyFirstAndSecond({
  first = "",
  second = "",
  right,
  detailsPage,
  firstTooltip = "",
}) {
  
  

  return (
    <Stack direction="row" gap={1}
      sx={{
        borderTop: first == "Total" && "1px solid",
        borderBottom: right && (typeof first == "string" && first.includes("Invoice #")) && "1px solid",
      }}
    >
      <Tooltip title={firstTooltip} placement="top">
        <Typography variant="body2" textAlign={right && "right"} sx={{fontWeight: "bold", minWidth: detailsPage ? 150 : 150, mr: 1}}>{first}</Typography>
      </Tooltip>  
      <Typography variant="body2">{second}</Typography>
    </Stack>
  )
}

import { getDocsById } from '../../services/axios'
import { useState, useEffect } from 'react'
import { handleErrorCatching } from '../handleErrorCatching'


export function useFetchDocsById(id, idType, collection, options={}) {  
  const {
    project = false,
    consoleLog = false, 
  } = options

  const [docs, setDocs] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const [isRefetching, setIsRefetching] = useState(false)

  useEffect(() => {
    if (id && (isFetching || isRefetching)) {
      setIsFetching(true)
      console.log("getting docs again!, project: ", project)
      const controller = new AbortController()
      async function getDocs() {
        try {

          console.log(collection, idType, id , project, consoleLog)
          let fetchedDocs = await getDocsById(collection, idType, id, controller, project, consoleLog)
          console.log("fetchedDocs!", fetchedDocs)
          // if (isRefetching) {
          //   setDocs([...fetchedDocs])
          // }
          // else {
            setDocs(fetchedDocs)
          // }
        } catch(e) {
          handleErrorCatching(e, collection)
        }
        finally {
          setIsFetching(false)
          setIsRefetching(false)
        }
      }
      getDocs()
      return () => {
        controller.abort()
      }
    }
  }, [isRefetching])

  return { docs, isFetching, isRefetching, setIsRefetching }
}
import { useEffect } from "react"
import { useTimeout } from "./useTimeout"


// source: https://www.youtube.com/watch?v=0c6znExIqRw&ab_channel=WebDevSimplified
// USE:
// const [value, setValue] = useState("")
// useDebounce(() => alert(value), 1000, [value])

// run (1) callback after (2) delay when (3) dependencies change
export function useDebounce(callback, delay, dependencies) {
  const { reset, clear } = useTimeout(callback, delay)
  useEffect(reset, [...dependencies, reset])
  useEffect(clear, []) // cleared out initially (so callback doesn't run on first iteration (page load))
}
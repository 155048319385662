import React, { useState, useEffect } from "react";
import { MenuItem, Select, TextField, Box } from "@mui/material";
import { months, years } from "../utils/dateOptions";

const DateRangeFilter = ({ column, onFilterChange }) => {
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");

	useEffect(() => {
    const handleFilterChange = () => {
      onFilterChange([fromDate, toDate]);
    };
    handleFilterChange();
	}, [fromDate, toDate]);

	return (
		<Box sx={{ display: "flex", gap: "0.5rem", margin: "4px"}}>
			<TextField
				label="From"
				type="date"
				value={fromDate}
				onChange={(e) => {
					setFromDate(e.target.value);
					console.log(`changing From ${e.target.value}`);
				}}
				InputLabelProps={{ shrink: true }}
			/>
			<TextField
				label="To"
				type="date"
				value={toDate}
				onChange={(e) => {
					setToDate(e.target.value);
					console.log(`changing To ${e.target.value}`);
				}}
				InputLabelProps={{ shrink: true }}
			/>
		</Box>
	);
};

const MonthYearFilter = ({ column, onFilterChange }) => {
	const [selectedMonth, setSelectedMonth] = useState("");
	const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

	const handleFilterChange = () => {
		onFilterChange({ month: selectedMonth, year: selectedYear });
	};

	return (
		<Box sx={{ display: "flex", gap: "0.5rem" }}>
			<Select
				value={selectedMonth}
				onChange={(e) => {
					setSelectedMonth(e.target.value);
					handleFilterChange();
				}}
				displayEmpty
			>
				<MenuItem value="" disabled>
					Select Month
				</MenuItem>
				{months.map((month) => (
					<MenuItem key={month.value} value={month.value}>
						{month.text}
					</MenuItem>
				))}
			</Select>
			<Select
				value={selectedYear}
				onChange={(e) => {
					setSelectedYear(e.target.value);
					handleFilterChange();
				}}
			>
				{years.map((year) => (
					<MenuItem key={year} value={year}>
						{year}
					</MenuItem>
				))}
			</Select>
		</Box>
	);
};

export { DateRangeFilter, MonthYearFilter };

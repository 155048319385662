import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Modal,
  Typography,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
// custom imports
import { uploadWellIndexToS3 } from '../utils/s3client';

const UploadWellIndexModal = ({ open, onClose }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setFile(selectedFile);
      console.log("set file");
    } else {
      alert('Please select a valid .xlsx file');
    }
  };

  const handleSubmit = async () => {
    if (file) {
      const result = await uploadWellIndexToS3(file);
      if (result) {
        alert('File uploaded successfully');
        setFile(null);
        onClose();
      }
      else {
        alert('Error uploading file');
        setFile(null);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          Upload Well Index
        </Typography>
        <Box component="form" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            component="label"
            disabled={file}
          >
            {!file ? "Select File" : "File Selected"}
            <input
              type="file"
              hidden
              onChange={handleFileChange}
              accept=".xlsx"
              disabled={file}
            />
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={!file}
            sx={{ mt: 2 }}
          >
            Upload
          </Button>

          <Button
						variant="outlined"
						color="primary"
						fullWidth
						onClick={onClose}
						sx={{ mt: 2 }}
					>
						Cancel
					</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UploadWellIndexModal;
import React, { useState, useEffect, useContext, useMemo } from "react";
import axios from "axios";
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { StateContext } from "../../state-management/app/useContext/StateContext";
import { DispatchContext } from "../../state-management/app/useContext/DispatchContext";
import { LoadingDotsIcon } from "../../components/utils/LoadingDotsIcon";
import { PageContainer } from "../../components/layout/PageContainer";

import { TableGeneric } from "../clients/components/_TableGeneric";
import { TableClients } from "../clients/components/TableClients";
import { TableInvoices } from "../clients/components/TableInvoices";
import { handleErrorCatching } from "../../utils/handleErrorCatching";
import { MyDivider } from "../../components/utils/MyDivider";
import { Button, Stack, Typography } from "@mui/material";
import { ChipNavItem } from "../../components/utils/ChipNavItem";
import { ButtonStyled } from "../../components/utils/ButtonStyled";
import { LinkWrapped } from "../../components/utils/LinkWrapped";
import { formatDate } from "../../utils/formatDate";
import { SCRAPETYPE } from "../../utils/constants";
import { isFalsyFromLocalStorage } from "../../utils/isFalsyFromLocalStorage";
import { arrayToCsv, downloadBlob } from "../../utils/JsonToCsv";
import { TypographyError } from "../../components/utils/TypographyError";

// import { TableClients } from "./components/TableClients"

export default function Admin({}) {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [clients, setClients] = useState([]);
  const [getClientsCount, setGetClientsCount] = useState(0);
  const [showClients, setShowClients] = useState(false);
  const [isFetchingClients, setIsFetchingClients] = useState(false);

  const [invoices, setInvoices] = useState([]);
  const [getInvoicesCount, setGetInvoicesCount] = useState(0);
  const [showInvoices, setShowInvoices] = useState(false);
  const [isFetchingInvoices, setIsFetchingInvoices] = useState(false);

  const [invoicesPost, setInvoicesPost] = useState([]);
  const [scrapeInvoicesRequestCount, setScrapeInvoicesRequestCount] =
    useState(0);
  const [showInvoicesPost, setShowInvoicesPost] = useState(false);
  const [isFetchingInvoicesPost, setIsFetchingInvoicesPost] = useState(false);

  // LOCAL WEB SCRAPED JSON FILE
  const [isUploading, setIsUploading] = useState(false);
  const [hasUploaded, setHasUploaded] = useState(false);

  async function uploadWebScrapedJson() {
    const controller = new AbortController();
    async function uploadLocalFile() {
      try {
        setIsUploading(true);
        const response = await axios.post(
          `/python/upload-web-scraped-json`,
          { token: appState.user.token },
          { signal: controller.signal }
        ); // TODO: getDocs or getDocsById  // const docs = await getDocsById("clients", "ELid", id, controller, true)
        console.log("result (uploadWebScrapedJson): ", response.data);
        if (response.data.success) {
          setHasUploaded(true);
        }
      } catch (e) {
        handleErrorCatching(e, "uploadWebScrapedJson");
      } finally {
        setIsUploading(false);
      }
    }
    await uploadLocalFile();
    return () => {
      controller.abort();
    };
  }

  // GET CLIENTS
  useEffect(() => {
    if (getClientsCount > 0) {
      const controller = new AbortController();
      async function getDocs() {
        try {
          const response = await axios.get(`/python/clients?testing=true`, {
            signal: controller.signal,
          }); // TODO: getDocs or getDocsById  // const docs = await getDocsById("clients", "ELid", id, controller, true)
          console.log("retrievededDocs (getClients): ", response.data);
          if (Array.isArray(response.data)) {
            setClients(response.data);
          }
        } catch (e) {
          handleErrorCatching(e, "retrievedDocs, getClients");
        } finally {
          setIsFetching(false);
        }
      }
      getDocs();
      return () => {
        controller.abort();
      };
    }
  }, [getClientsCount]);

  // GET INVOICES (GET)
  useEffect(() => {
    if (getInvoicesCount > 0) {
      const controller = new AbortController();
      async function getDocs() {
        try {
          // const response = await axios.get(`/python/invoices?testing=true`, {signal: controller.signal}) // TODO: getDocs or getDocsById  // const docs = await getDocsById("Invoices", "ELid", id, controller, true)
          // const response = await axios.get(`/python/chron/clientsandinvoices?chron=true`, {signal: controller.signal}) // TODO: getDocs or getDocsById  // const docs = await getDocsById("Invoices", "ELid", id, controller, true)
          // const response = await axios.get(`/python/chron/clientsandinvoices?chron=true&testing=true`, {signal: controller.signal}) // TODO: getDocs or getDocsById  // const docs = await getDocsById("Invoices", "ELid", id, controller, true)
          const response = await axios.get(
            `/python/chron/clientsandinvoices?chron=true&newInvoicesOnly=true`,
            { signal: controller.signal }
          ); // TODO: getDocs or getDocsById  // const docs = await getDocsById("Invoices", "ELid", id, controller, true)
          console.log("retrievededDocs (getInvoices): ", response.data);
          if (Array.isArray(response.data)) {
            setInvoices(response.data);
          } else if (
            response.data.success &&
            Array.isArray(response.data.docs)
          ) {
            setInvoices(response.data.docs);
          }
        } catch (e) {
          handleErrorCatching(e, "retrievedDocs, getInvoices");
        } finally {
          setIsFetching(false);
        }
      }
      getDocs();
      return () => {
        controller.abort();
      };
    }
  }, [getInvoicesCount]);

  // SCRAPE INVOICES (POST)
  useEffect(() => {
    if (scrapeInvoicesRequestCount > 0) {
      const controller = new AbortController();
      async function getDocs() {
        try {
          let clientId = "71479"; // 71479 = TTT MINERALS LLC
          let payload = {
            // "undefined", "null" = d/t messing with localStorage (they are strings, not null/undefined datatypes/"objects")
            username: isFalsyFromLocalStorage(
              appState.user?.requests?.EL?.username
            )
              ? process.env.REACT_APP_EL_USER
              : appState.user?.requests?.EL?.username,
            password: isFalsyFromLocalStorage(
              appState.user?.requests?.EL?.password
            )
              ? process.env.REACT_APP_EL_PASS
              : appState.user?.requests?.EL?.password,
            userId: isFalsyFromLocalStorage(appState.user?.requests?.EL?.userId)
              ? process.env.REACT_APP_EL_USERID
              : appState.user?.requests?.EL?.userId,
            scrapeType: SCRAPETYPE.getInvoicesOfClients,
            reqClientIds: [clientId],
            reqClientsAndInvoices: [], // [ { ELid: "", invoiceIds: ["172385", "12798539"] } ]
          };
          console.log("payload", payload);
          const response = await axios.post(
            `/python/invoices?testing=true`,
            { payload: payload, token: appState.user.token },
            { signal: controller.signal }
          ); // TODO: getDocs or getDocsById  // const docs = await getDocsById("clients", "ELid", id, controller, true)
          console.log(
            `retrievededDocs (scrapeInvoices - ${clientId}): `,
            response.data
          );

          if (Array.isArray(response.data)) {
            setInvoicesPost(response.data);
          }
        } catch (e) {
          handleErrorCatching(e, "retrievedDocs, scrapeInvoices");
        } finally {
          setIsFetchingInvoices(false);
        }
      }
      getDocs();
      return () => {
        controller.abort();
      };
    }
  }, [scrapeInvoicesRequestCount]);

  let pageTitleName = "Admin";
  let pageTitle = pageTitleName;

  const columnsClients = useMemo(
    () => [
      // {
      //   accessorKey: 'createdDate',
      //   header: 'Created',
      //   size: 40,
      //   enableColumnFilter: false,
      //   Cell: ({ row }) => row.original.createdDate ? <Box component="span" sx={{color: row.original.status == "inactive" ? "#00000080" : "inherit", fontStyle: row.original.status == "inactive" ? "italic" : "normal"}}>{format(new Date(row.original.createdDate), 'MM/dd/yyyy')} {zonedTimeToUtc(row.original.updatedDate, 'America/Chicago').toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}</Box> : ""        // Sort by date (or even string ?)
      // },
      // {
      //   accessorKey: 'updatedDate',
      //   header: 'Last Updated',
      //   size: 40,
      //   enableColumnFilter: false,
      //   // Cell: ({ row }) => row.original.updatedDate ? <Box component="span" sx={{color: row.original.status == "inactive" ? "#00000080" : "inherit", fontStyle: row.original.status == "inactive" ? "italic" : "normal"}}>{format(new Date(row.original.updatedDate), 'MM/dd/yyyy')} {format(parseISO(row.original.updatedDate.slice(0, -5)), 'h:mm a')}</Box> : ""
      //   Cell: ({ row }) => row.original.updatedDate ? <Box component="span" sx={{color: row.original.status == "inactive" ? "#00000080" : "inherit", fontStyle: row.original.status == "inactive" ? "italic" : "normal"}}>{format(new Date(row.original.updatedDate), 'MM/dd/yyyy')} {zonedTimeToUtc(row.original.updatedDate, 'America/Chicago').toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}</Box> : ""
      //   // Cell: ({ row }) => row.original.updatedDate ? format(new Date(row.original.updatedDate), 'MM/dd/yyyy') : ""
      //   // Sort by date (or even string ?)
      // },
      // {
      //   accessorKey: 'clientBaId',
      //   header: 'clientBaId',
      //   size: 20
      // },
      {
        accessorKey: "ELid",
        header: "ELid",
        size: 20,
      },
      {
        accessorKey: "name",
        header: "name",
        // size: 20,
        Cell: ({ row }) => (
          <LinkWrapped to={`/clients/${row.original.ELid}`}>
            {row.original.name}
          </LinkWrapped>
        ),
      },
      // {
      //   accessorKey: 'results',
      //   header: 'Invoices',
      //   size: 20,
      //   Cell: ({ row }) => <LinkWrapped to={`/clients/${row.original._id}`}>{row.original.results?.length}</LinkWrapped>
      // },
    ],
    [clients]
  );

  const columnsInvoices = useMemo(
    () => [
      // {
      //   accessorKey: 'createdDate',
      //   header: 'Created',
      //   size: 40,
      //   enableColumnFilter: false,
      //   Cell: ({ row }) => row.original.createdDate ? <Box component="span" sx={{color: row.original.status == "inactive" ? "#00000080" : "inherit", fontStyle: row.original.status == "inactive" ? "italic" : "normal"}}>{format(new Date(row.original.createdDate), 'MM/dd/yyyy')} {zonedTimeToUtc(row.original.updatedDate, 'America/Chicago').toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}</Box> : ""        // Sort by date (or even string ?)
      // },
      // {
      //   accessorKey: 'updatedDate',
      //   header: 'Last Updated',
      //   size: 40,
      //   enableColumnFilter: false,
      //   // Cell: ({ row }) => row.original.updatedDate ? <Box component="span" sx={{color: row.original.status == "inactive" ? "#00000080" : "inherit", fontStyle: row.original.status == "inactive" ? "italic" : "normal"}}>{format(new Date(row.original.updatedDate), 'MM/dd/yyyy')} {format(parseISO(row.original.updatedDate.slice(0, -5)), 'h:mm a')}</Box> : ""
      //   Cell: ({ row }) => row.original.updatedDate ? <Box component="span" sx={{color: row.original.status == "inactive" ? "#00000080" : "inherit", fontStyle: row.original.status == "inactive" ? "italic" : "normal"}}>{format(new Date(row.original.updatedDate), 'MM/dd/yyyy')} {zonedTimeToUtc(row.original.updatedDate, 'America/Chicago').toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}</Box> : ""
      //   // Cell: ({ row }) => row.original.updatedDate ? format(new Date(row.original.updatedDate), 'MM/dd/yyyy') : ""
      //   // Sort by date (or even string ?)
      // },
      // {
      //   accessorKey: 'clientBaId',
      //   header: 'clientBaId',
      //   size: 20
      // },
      {
        accessorKey: "pulled",
        header: "Pulled",
        Cell: ({ row }) => {
          const statements = row.original.statements || [];
          const pulled = statements.length > 0;

          return (
            <Stack>
              <ButtonStyled
                state={true}
                activeState={pulled ? false : true}
                label={pulled ? "Repull" : "Pull"}
                // color = {pulled ? "success" : "primary"}
                sx={{
                  opacity: pulled ? 1 : 1,
                }}

                // disabled
                // onClick
              />

              {row.original.statementsRepulled?.bool && (
                <Typography variant="body2">
                  {formatDate(new Date(row.original.statementsRepulled?.date))}
                </Typography>
              )}
            </Stack>
          );
          // <LinkWrapped to={`/invoices/${row.original._id}`}>{row.original.amtOriginal}</LinkWrapped>
        },
      },
      {
        accessorKey: "invoiceId",
        header: "invoiceId",
      },
      {
        accessorKey: "orgNum",
        header: "orgNum",
      },
      {
        accessorKey: "docType",
        header: "docType",
      },
      {
        accessorKey: "operator",
        header: "operator",
      },
      {
        accessorKey: "opOwnerNum",
        header: "opOwnerNum",
      },
      {
        accessorKey: "docNum",
        header: "docNum",
      },
      {
        accessorKey: "docDate",
        header: "docDate",
      },
      {
        accessorKey: "receivedDate",
        header: "receivedDate",
      },
      {
        accessorKey: "amtOriginal",
        header: "amtOriginal",
        Cell: ({ row }) => (
          <LinkWrapped to={`/invoices/${row.original._id}`}>
            {row.original.amtOriginal}
          </LinkWrapped>
        ),
      },
    ],
    [invoices]
  );

  if (isLoading)
    return (
      <PageContainer title={pageTitleName}>
        <LoadingDotsIcon />
      </PageContainer>
    );

  const csv = arrayToCsv([
    [1, "2", '"3"'],
    [true, null, undefined],
  ]);

  return (
    <>
      <PageContainer
        title={pageTitle}
        navIsHidden={false}
        // navItems={navItems}
        // navState={navState}
        // setNavState={setNavState}
      >
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          sx={{ mb: 8, mt: 4 }}
        >
          <ButtonStyled
            // init push develop
            onClick={uploadWebScrapedJson}
            disabled={true || isUploading} // remove 'true' if needed
            label={
              !hasUploaded && !isUploading
                ? "Upload Local Json"
                : isUploading
                ? "Uploading..."
                : "Uploaded"
            }
            sx={{
              backgroundColor: hasUploaded ? "green" : undefined,
            }}
          />
          <ButtonStyled
            onClick={() =>
              downloadBlob(csv, "blob.csv", "text/csv;charset=utf-8;")
            }
          >
            Download CSV
          </ButtonStyled>
        </Stack>

        {/* CLIENTS */}
        <DataSection
          section="Clients"
          showSection={showClients}
          setShowSection={setShowClients}
          docs={clients}
          setDocs={setClients}
          setGetDocsCount={setGetClientsCount}
          isFetching={isFetchingClients}
          setIsFetching={setIsFetchingClients}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          columns={columnsClients}
        />

        {/* INVOICES */}
        <DataSection
          section="Invoices (GET)"
          showSection={showInvoices}
          setShowSection={setShowInvoices}
          docs={invoices}
          setDocs={setInvoices}
          setGetDocsCount={setGetInvoicesCount}
          isFetching={isFetchingInvoices}
          setIsFetching={setIsFetchingInvoices}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          columns={columnsInvoices}
        />

        {/* INVOICES */}
        <DataSection
          section="Invoices (POST)"
          showSection={showInvoicesPost}
          setShowSection={setShowInvoicesPost}
          docs={invoicesPost}
          setDocs={setInvoicesPost}
          setGetDocsCount={setScrapeInvoicesRequestCount}
          isFetching={isFetchingInvoicesPost}
          setIsFetching={setIsFetchingInvoicesPost}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          columns={columnsInvoices}
        />
      </PageContainer>
    </>
  );
}

function DataSection({
  section = "section",
  showSection,
  setShowSection,
  docs,
  setDocs,
  setGetDocsCount,
  isFetching,
  setIsFetching,
  isLoading,
  setIsLoading,
  columns,
}) {
  return (
    <Stack sx={{ width: 1, mb: showSection ? 4 : 1 }}>
      <Stack gap={1} alignItems="start" sx={{ width: 0.9 }}>
        <Stack direction="row" gap={2} alignItems="center">
          <ButtonStyled
            onClick={() => setGetDocsCount((prev) => prev + 1)}
            disabled={isFetching}
            label="Fetch Data"
          />

          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="h5" component="h1">
              {section}
            </Typography>
            {isFetching ? (
              <TypographyError>
                Running Queries (may take up to 1min)
              </TypographyError>
            ) : (
              <ChipNavItem
                docs={docs}
                color={docs.length > 0 ? "primary" : "default"}
              />
            )}
          </Stack>

          <Button variant="text" onClick={() => setShowSection(!showSection)}>
            {showSection ? "Hide" : "Show"}
          </Button>
        </Stack>
        <MyDivider color="#00000090" thickness={3} />
      </Stack>

      {
        showSection && (
          <TableGeneric
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isFetching={isFetching}
            setIsFetching={setIsFetching}
            columns={columns}
            initialSettings={{
              tableView: "table",
            }}
            docsOriginal={docs}
            setDocsOriginal={setDocs}
          />
        )
        // section == "Clients" ?
        // <TableClients
        //   isLoading={isLoading}
        //   setIsLoading={setIsLoading}
        //   isFetching={isFetching}
        //   setIsFetching={setIsFetching}

        //   initialSettings={{
        //     tableView: "table",
        //   }}

        //   docsOriginal={docs}
        //   setDocsOriginal={setDocs}
        // />
        // : section == "Invoices" ?
        //   <TableInvoices
        //     isLoading={isLoading}
        //     setIsLoading={setIsLoading}
        //     isFetching={isFetching}
        //     setIsFetching={setIsFetching}

        //     initialSettings={{
        //       tableView: "table",
        //     }}

        //     docsOriginal={docs}
        //     setDocsOriginal={setDocs}
        //   />
        // :
        // <>No Table...</>
      }
    </Stack>
  );
}

import React, { useContext } from "react"

import { ThemeProvider, CssBaseline, IconButton } from "@mui/material"
import { SnackbarProvider } from "notistack"

import { StateContext } from "../../state-management/app/useContext/StateContext"
import { DispatchContext } from "../../state-management/app/useContext/DispatchContext"

// import { state, dispatch } from "../../state-management/app/useContext/InitialState"
import { theme, themeLight, themeDark } from "../../theme"
import { TaskAltRounded } from "@mui/icons-material"


export function SiteProvidersWrapper({
  state,
  dispatch,
  children
}) {
  
  // const appState = useContext(StateContext)
  const theme = state.theme ? state.theme == "dark" ? themeDark : themeLight : themeLight
  

  return (
    <ThemeProvider theme={theme} >

      <SnackbarProvider
          maxSnack={3}
          variant="success"
          iconVariant={{
            success: <IconButton color="inherit" sx={{'&:hover': {background: "none", cursor: "default"}}} ><TaskAltRounded /></IconButton>,
            error: '✖️',
            warning: '⚠️',
            info: 'ℹ️',
          }}
          // ref={myRef}
          // action={(snackbarId) => (
          //   <IconButton onClick={() => closeSnackbar(snackbarId)}>
          //     <ClearRounded />
          //   </IconButton>
          // )}
          // Components={{
          //   styledSuccess: styledSuccess,
          // }}
        >

        <CssBaseline />

        <StateContext.Provider value={state}>
          <DispatchContext.Provider value={dispatch}>
        
            {children}

          </DispatchContext.Provider>
        </StateContext.Provider>

      </SnackbarProvider>

    </ThemeProvider>
  )
}

import { useContext } from 'react'

import { Stack, Toolbar } from '@mui/material'
import SiteDrawer from './SiteDrawer'
import { SiteLoggedIn } from './SiteLoggedIn'
import { StateContext } from '../../state-management/app/useContext/StateContext'
import SiteTopNav from './SiteTopNav'
import { COLORS } from '../../utils/constants'
import { SiteLoggedOut } from './SiteLoggedOut'

import { keyframes } from "@mui/system"
import { motion } from 'framer-motion'
import { ComponentTransition } from './animations/ComponentTransition'


export default function SiteContainer({
  children
}) {

  const appState = useContext(StateContext)

  // #cb4e01
  const colorDark = "#cb4e01"
  const colorNormal = "#fe6201"
  const colorLight = '#ff8533'
  const colors = ['#fe6201', '#ff8b3d', '#ff944d'];
  const linearGradient = `linear-gradient(45deg, ${colorDark}, ${colorNormal}, ${colorLight}, ${colorNormal}, ${colorDark});`
  // const linearGradientRotated = `linear-gradient(90deg, ${colorDark}, ${colorNormal}, ${colorLight}, ${colorNormal}, ${colorDark});`
  // const linearGradient = `linear-gradient(45deg, ${colors[0]}, ${colors[1]}, ${colors[2]}, ${colors[1]}, ${colors[0]});`
  const size = '800%'
  const gradient = keyframes`
      0% {
          background: ${linearGradient}
          background-size: ${size} ${size};
          background-position: 20% 50%;
      }

      50% {
          background: ${linearGradient}
          background-size: ${size} ${size};
          background-position: 80% 50%;
      }

      100% {
          background: ${linearGradient}
          background-size: ${size} ${size};
          background-position: 20% 50%;
      }
  `
  const gradientAnimation = `${gradient} 12s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite alternate both`
  // const animation = `${gradient} 15s ease-in-out infinite alternate both`

  const fadeIn = keyframes`
    0% {
      background-color: black;
    }
    100% {
      background-color: transparent;
    }
  `
  const fadeInAnimation = `${fadeIn} 2s forwards`;


  return (
    appState.loggedIn ?
      <Stack
        id="siteStack"
        // direction="row"
        justifyContent="stretch"
        width={1}
        minHeight="100vh"
        // sx={{
        //   backgroundColor: !appState.loggedIn && COLORS.PRIMARY.LIGHT.MAIN
        // }}
      >
        <SiteLoggedIn>{children}</SiteLoggedIn>
      </Stack>
    :
      <Stack
        id="siteStack"
        direction="row"
        justifyContent="stretch"
        width={1}
        minHeight="100vh"
        sx={{
          // animation: animation
          // animation: `${fadeInAnimation}, ${gradientAnimation}`,
          animation: `${gradientAnimation}`,
          // backgroundColor: "black", // Set initial background color to black
        }}
      >
        <SiteLoggedOut />
      </Stack>
  )
}

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PageContainer } from "../../components/layout/PageContainer";
import { TableStatementDetails } from "./components/TableStatementDetails";
import { DescriptionTwoTone } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { LinkWrapped } from "../../components/utils/LinkWrapped";
import { PageTitle } from "../../components/layout/PageTitle";
import { TypographyFirstAndSecond } from "../../components/utils/TypographyFirstAndSecond";
import { ifNegNumConvertToParenthesesFormat } from "../../utils/ifParenthesesStrConvertToNegNum";
import { useFetchDocById } from "../../utils/hooks/useFetchDocById";

export default function Statement({}) {
  const {
    id: clientELid,
    invoiceId: invoiceId_, // _id
    statementId: statementId, // statementId (NOT _id)
  } = useParams();

  //////////////////////////
  // STATE
  //////////////////////////

  // GENERAL
  // const appState = useContext(StateContext)

  // DOCSORIGINAL
  const { doc: doc } = useFetchDocById(invoiceId_, "_id", "invoices", {
    consoleLog: true,
  });
  const { doc: statement } = useFetchDocById(
    statementId,
    "statementId",
    "statements",
    { consoleLog: true }
  );
  const keys = statement?.statementDetails
    ? Object.keys(statement.statementDetails)
    : [];

  const [docsFinal, setDocsFinal] = useState([]); // if doing modifications

  // DOCSORIGINAL MODIFICATIONS

  // ADDITIONAL
  const [isLoadingTable, setIsLoadingTable] = useState(true);

  const { doc: client } = useFetchDocById(clientELid, "ELid", "clients", {
    consoleLog: true,
  });

  //////////////////////////
  // EFFECTS
  //////////////////////////

  // CREATE DOC FOR EACH ORIGINAL LINEITEM (Revenue, Tax, Deduct) (vs keeping within taxDeducts only)
  useEffect(() => {
    if (doc._id && keys.length > 0) {
      let docs = getStatementDetailsLineItems(statement, keys);
      setDocsFinal(docs);
      setIsLoadingTable(false);
    }
  }, [statement, keys]);

  //////////////////////////
  // FUNCTIONS
  //////////////////////////

  function getStatementDetailsLineItems(statement, keys) {
    let arr = [];

    keys.forEach((k) =>
      statement.statementDetails[k].forEach((mi) => {
        // console.log("MI", mi)

        arr.push({ ...mi, category: k });
        if (mi.taxDeducts && mi.taxDeducts?.length > 0) {
          mi?.taxDeducts?.forEach((el) => arr.push({ ...el, category: k }));
        }
      })
    );

    return arr;
  }

  // let pageTitleName = "Invoice Details"
  let pageTitle =
    doc.docType == "REVENUE" ? (
      <PageTitle
        icon={<DescriptionTwoTone sx={{ fontSize: "4rem" }} />}
        pageTitleName={doc.num}
        // pageTitleName={pageTitleName}
        contentRightOfTitle={
          <Stack sx={{ ml: 4 }}>
            <TypographyFirstAndSecond
              detailsPage
              first={`Invoice # (${doc.docType})`}
              second={doc.docNum}
            />
            <TypographyFirstAndSecond
              detailsPage
              first={`Op Owner #`}
              second={doc.opOwnerNum}
            />
            <TypographyFirstAndSecond
              detailsPage
              first={`Operator`}
              second={doc._id && `${doc.opOwnerNum} - ${doc.operator}`}
            />
            <TypographyFirstAndSecond
              detailsPage
              first={`Owners`}
              second={
                doc.orgNum ? `${doc.orgNum} - ${client?.name}` : client?.name
              }
            />
            <TypographyFirstAndSecond
              detailsPage
              first={`Invoice (EL)`}
              second={
                <>
                  <LinkWrapped to={doc.invoiceUrl} external newTab>
                    Go to Invoice
                  </LinkWrapped>{" "}
                  <i>(must be logged in to that client)</i>
                </>
              }
            />
            <TypographyFirstAndSecond
              first={`Invoice Details (EL)`}
              second={
                <>
                  <LinkWrapped to={statement.statementUrl} external newTab>
                    Go to Invoice Details
                  </LinkWrapped>{" "}
                  <i>(must be logged in to that client)</i>
                </>
              }
            />
          </Stack>
        }
        contentRightOfPage={
          <Stack sx={{ mr: 5 }}>
            <TypographyFirstAndSecond
              right
              first={`Invoice # (${doc.docType})`}
              second={doc.docNum}
            />
            <TypographyFirstAndSecond
              right
              first={`Revenue`}
              second={
                doc._id && ifNegNumConvertToParenthesesFormat(statement.revenue)
              }
            />
            <TypographyFirstAndSecond
              right
              first={`Tax`}
              second={
                doc._id && ifNegNumConvertToParenthesesFormat(statement.tax)
              }
            />
            <TypographyFirstAndSecond
              right
              first={`Deductions`}
              second={
                doc._id &&
                ifNegNumConvertToParenthesesFormat(statement.deductions)
              }
            />
            <TypographyFirstAndSecond
              right
              first={`Total`}
              second={
                doc._id && ifNegNumConvertToParenthesesFormat(statement.total)
              }
            />
          </Stack>
        }
      />
    ) : (
      <PageTitle
        icon={<DescriptionTwoTone sx={{ fontSize: "4rem" }} />}
        pageTitleName={doc.num}
        // pageTitleName={pageTitleName}
        contentRightOfTitle={
          <Stack sx={{ ml: 2 }}>
            <TypographyFirstAndSecond
              detailsPage
              first={`Invoice # (${doc.docType})`}
              second={doc.docNum}
            />
            <TypographyFirstAndSecond
              detailsPage
              first={`Op Owner #`}
              second={doc.opOwnerNum}
            />
            <TypographyFirstAndSecond
              detailsPage
              first={`Operator`}
              second={`${doc.opOwnerNum} - ${doc.operator}`}
            />
            <TypographyFirstAndSecond
              detailsPage
              first={`Owners`}
              second={
                doc.orgNum ? `${doc.orgNum} - ${client?.name}` : client?.name
              }
            />
            <TypographyFirstAndSecond
              detailsPage
              first={`Invoice (EL)`}
              second={
                <>
                  <LinkWrapped to={doc.invoiceUrl} external newTab>
                    Go to Invoice
                  </LinkWrapped>{" "}
                  <i>(must be logged in to that client)</i>
                </>
              }
            />
            <TypographyFirstAndSecond
              first={`Invoice Details (EL)`}
              second={
                <>
                  <LinkWrapped to={statement.statementUrl} external newTab>
                    Go to Invoice Details
                  </LinkWrapped>{" "}
                  <i>(must be logged in to that client)</i>
                </>
              }
            />
          </Stack>
        }
        contentRightOfPage={
          <Stack sx={{ mr: 5, pt: 1 }}>
            <TypographyFirstAndSecond
              right
              first={`Invoice # (${doc.docType})`}
              second={doc.docNum}
            />
            {/* <TypographyFirstAndSecond right first={`AFE`} second={ifNegNumConvertToParenthesesFormat((statement.afe || statement.afeId || statement.afeOp) ? doc.amtOriginal : 0)} /> */}
            {/* <TypographyFirstAndSecond right first={`Operations`} second={ifNegNumConvertToParenthesesFormat(!(statement.afe || statement.afeId || statement.afeOp) ? doc.amtOriginal : 0)} /> */}
            <TypographyFirstAndSecond
              right
              first={`Total`}
              second={ifNegNumConvertToParenthesesFormat(statement.amtOriginal)}
            />
          </Stack>
        }
      />
    );

  return (
    <>
      <PageContainer title={pageTitle}>
        {docsFinal.length > 0 && (
          <TableStatementDetails
            docsOriginal={docsFinal}
            setDocsOriginal={setDocsFinal} // setDataOriginal (MRT)
            isLoading={isLoadingTable}
            setIsLoading={setIsLoadingTable}
            initialSettings={{
              tableView: "table",
            }}
            // additional
            docType={doc.docType}
            keys={keys}
          />
        )}
      </PageContainer>
    </>
  );
}

import React, { useState, useEffect } from "react"
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material"
import { Info } from "@mui/icons-material"


ButtonMoreInfo.propTypes = {
  
}
export function ButtonMoreInfo({
  open = false,
  setOpen = () => {},
  label,
  iconOnly = false,
  sx = {},
  sxIcon = {},
  columnHeader = false,
  iconButtonColor = "primaryBootstrap",
}) {
  
  if (columnHeader) {
    sxIcon["fontSize"] = "1.1rem"
  }

  if (iconOnly) return (
    <Tooltip title={label ? label : "Learn more"}>
      <Info
        color={iconButtonColor}
        onClick={() => setOpen(true)}
        sx={{
          opacity: 1.0,
          mb: "-3px",
          cursor: "pointer",
          ...sxIcon
        }}
      />
    </Tooltip>
  )
  

  return (
    <>
      <Tooltip title={label ? label : "Learn more"}>
        <IconButton color={iconButtonColor} size="medium"
          onClick={() => setOpen(true)}
          // onClick={() => setOpenGenericModal(true)}
          sx={{opacity: 1.0, mb: "-3px", ...sx}}
        >
          <Info sx={{...sxIcon}} />
        </IconButton>
      </Tooltip>
    </>
  )
}

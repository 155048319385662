import React, { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { FileDownloadRounded } from "@mui/icons-material";

// import { StateContext } from "../../../state-management/app/useContext/StateContext"

import { LinkWrapped } from "../../../components/utils/LinkWrapped";
import { MRT } from "../../../components/utils/MRT";
import { Pod } from "../../../components/utils/Pod";
import { useFetchDocById } from "../../../utils/hooks/useFetchDocById";
import { IconButtonStyled } from "../../../components/utils/IconButtonStyled";
import {
  handlePrintCsvJib,
  handlePrintCsvRevenue,
} from "../../../utils/handlePrintCsv";
import { ifParenthesesStrConvertToNegNum } from "../../../utils/ifParenthesesStrConvertToNegNum";
import { sortingFnRowStrToNum } from "../../../utils/sortingFnRowStrToNum";
import { TypographyError } from "../../../components/utils/TypographyError";
import {
  checkStatementAndStatementDetailsMatchJib,
  checkStatementAndStatementDetailsMatchRevenue,
} from "../../../utils/errorChecking";
import { nanoid } from "nanoid";
import { getInvoiceWithStatements } from "../../../utils/hooks/getInvoiceWithStatements";

export function TableStatements({
  docsOriginal,
  setDocsOriginal,

  isLoading, // isLoadingTable
  setIsLoading,

  tablePodViewVisible = true,
  initialSettings = {
    tableView: "pod",
  },

  enableTableHeader = true,

  sx = {},

  // additional
  docType,
  invoice = {},
}) {
  const DOC_SINGULAR = "statement";
  const DOCS_PLURAL = "statements";

  const { id: clientELid, invoiceId: invoiceId_ } = useParams();

  //////////////////////////
  // STATE
  //////////////////////////

  // GENERAL
  // const appState = useContext(StateContext)

  // TABLE
  const defaultColumnVisibility = {
    statementId: false,
    cc: true,

    // REVENUE
    well: true,
    state: true,
    county: true,
    revenue: true,
    tax: true,
    deductions: true,
    netted: false,
    total: true,

    // JIB
    // afe: true,
    // wellName: true,
    // amtOriginal: true,
  };
  const [columnVisibility, setColumnVisibility] = useState(
    defaultColumnVisibility
  );

  // ADDITIONAL
  const { doc: client, isFetching: isFetchingClient } = useFetchDocById(
    clientELid,
    "ELid",
    "clients"
  );

  //////////////////////////
  // EFFECTS
  //////////////////////////

  useEffect(() => {
    setColumnVisibility(defaultColumnVisibility);
  }, []);

  //////////////////////////
  // FUNCTIONS
  //////////////////////////

  async function handleExportInvoice(invoice, type = "REVENUE") {
    // const invoice = await handleGetInvoicesFromDb([row]);
    invoice.statements = docsOriginal;
    invoice.client = client;

    if (type === "REVENUE") {
      handlePrintCsvRevenue(invoice, client);
    } else if (type === "JIB") {
      handlePrintCsvJib(invoice, client);
    }
  }

  // async function handleGetInvoicesFromDb(invoice) {
  //   const promises = [invoice].map(async (inv, i) => {
  //     const client = { ...inv.original.client };
  //     const controller = new AbortController();
  //     // CHANGE
  //     // row.original = await getDocById("invoices", "_id", row.original._id, controller)
  //     inv = await getInvoiceWithStatements(inv._id, controller);
  //     inv.client = client;
  //     return inv;
  //   });
  //   const invoices = await Promise.all(promises);
  //   return invoices.at(0);
  // }

  //////////////////////////
  // TABLE
  //////////////////////////

  const columnsRevenue = useMemo(
    () => [
      {
        accessorKey: "statementId",
        header: "statementId",
      },
      {
        accessorKey: "cc",
        header: "cc",
        sortingFn: "alphanumeric",
        Cell: ({ row }) => {
          return (
            <Stack direction="row" gap={1}>
              <Typography variant="body1" sx={{ fontSize: "0.875rem" }}>
                {row.original.cc}
              </Typography>
              {row.original.ccOp && (
                <Typography variant="caption" sx={{ opacity: 0.6 }}>
                  {row.original.ccOp}
                </Typography>
              )}
            </Stack>
          );
        },
      },
      {
        accessorKey: "wellName",
        header: "well",
      },
      {
        accessorKey: "state",
        header: "state",
      },
      {
        accessorKey: "county",
        header: "county",
      },
      {
        accessorKey: "revenue",
        header: "revenue",
        sortingFn: (a, b) => sortingFnRowStrToNum(a, b, "revenue"),

        filterFn: (row, id, filterValue) => {
          if (filterValue == "*") {
            const {
              errors,
              statementAndStatementDetailsMatch:
                statementAndStatementDetailsMatchFrontEnd,
            } = checkStatementAndStatementDetailsMatchRevenue(row.original);
            const statementAndStatementDetailsMatchBackEnd =
              row.original.statementAndStatementDetailsMatch;
            const statementAndStatementDetailsMatch =
              statementAndStatementDetailsMatchFrontEnd &&
              statementAndStatementDetailsMatchBackEnd;
            return !statementAndStatementDetailsMatch;
          }
          return row.original[id]?.includes(filterValue);
        },

        Cell: ({ row }) => {
          if (row.original.statementId) {
            const linkComponent = (
              <LinkWrapped
                to={`/clients/${clientELid}/invoices/${invoiceId_}/${row.original.statementId}`}
              >
                {row.original.revenue}
              </LinkWrapped>
            );

            const {
              errors: errorsFrontEndUnfiltered,
              statementAndStatementDetailsMatch,
            } = checkStatementAndStatementDetailsMatchRevenue(row.original);
            const errorsFrontEnd = errorsFrontEndUnfiltered.filter(
              (error) => error.includes("revenue") || error.includes("netted")
            );
            const errorsBackEnd =
              row.original.errors?.filter(
                (error) => error.includes("revenue") || error.includes("netted")
              ) || [];
            const errors = [];
            errorsFrontEnd.forEach((e) =>
              errorsBackEnd.includes(e)
                ? errors.push(`${e}-FE/BE`)
                : errors.push(`${e}-FE`)
            );
            errorsBackEnd.forEach((e) =>
              errorsFrontEnd.includes(e) ? null : errors.push(`${e}-BE`)
            );

            return (
              <Stack direction="column" gap={1}>
                {linkComponent}
                {errors?.map((error) => (
                  <TypographyError key={nanoid()}>{error}</TypographyError>
                ))}
              </Stack>
            );
          } else {
            return (
              <Typography
                onClick={() =>
                  alert(
                    "There is no Statement ID for this line item.  Please inform developer."
                  )
                }
              >
                {row.original.revenue}
              </Typography>
            );
          }
        },
      },
      {
        accessorKey: "tax",
        header: "tax",
        sortingFn: (a, b) => sortingFnRowStrToNum(a, b, "tax"),
        // Cell: ({ row }) => <LinkWrapped to={`/invoices/${row.original._id}`}>{row.original.amtOriginal}</LinkWrapped>
        filterFn: (row, id, filterValue) => {
          if (filterValue == "*") {
            const {
              errors,
              statementAndStatementDetailsMatch:
                statementAndStatementDetailsMatchFrontEnd,
            } = checkStatementAndStatementDetailsMatchRevenue(row.original);
            const statementAndStatementDetailsMatchBackEnd =
              row.original.statementAndStatementDetailsMatch;
            const statementAndStatementDetailsMatch =
              statementAndStatementDetailsMatchFrontEnd &&
              statementAndStatementDetailsMatchBackEnd;
            return !statementAndStatementDetailsMatch;
          }
          return row.original[id]?.includes(filterValue);
        },

        Cell: ({ row, renderedCellValue }) => {
          if (row.original.statementId) {
            const {
              errors: errorsFrontEndUnfiltered,
              statementAndStatementDetailsMatch,
            } = checkStatementAndStatementDetailsMatchRevenue(row.original);
            const errorsFrontEnd = errorsFrontEndUnfiltered.filter((error) =>
              error.includes("tax")
            );
            const errorsBackEnd =
              row.original.errors?.filter((error) => error.includes("tax")) ||
              [];
            const errors = [];
            errorsFrontEnd.forEach((e) =>
              errorsBackEnd.includes(e)
                ? errors.push(`${e}-FE/BE`)
                : errors.push(`${e}-FE`)
            );
            errorsBackEnd.forEach((e) =>
              errorsFrontEnd.includes(e) ? null : errors.push(`${e}-BE`)
            );

            return (
              <Stack direction="column" gap={1}>
                {renderedCellValue}
                {errors?.map((error) => (
                  <TypographyError key={nanoid()}>{error}</TypographyError>
                ))}
              </Stack>
            );
          } else {
            return (
              <Typography
                onClick={() =>
                  alert(
                    "There is no Statement ID for this line item.  Please inform developer."
                  )
                }
              >
                {row.original.revenue}
              </Typography>
            );
          }
        },
      },
      {
        accessorKey: "deductions",
        header: "deductions",
        sortingFn: (a, b) => sortingFnRowStrToNum(a, b, "deductions"),
        // Cell: ({ row }) => <LinkWrapped to={`/invoices/${row.original._id}`}>{row.original.amtOriginal}</LinkWrapped>

        filterFn: (row, id, filterValue) => {
          if (filterValue == "*") {
            const {
              errors,
              statementAndStatementDetailsMatch:
                statementAndStatementDetailsMatchFrontEnd,
            } = checkStatementAndStatementDetailsMatchRevenue(row.original);
            const statementAndStatementDetailsMatchBackEnd =
              row.original.statementAndStatementDetailsMatch;
            const statementAndStatementDetailsMatch =
              statementAndStatementDetailsMatchFrontEnd &&
              statementAndStatementDetailsMatchBackEnd;
            return !statementAndStatementDetailsMatch;
          }
          return row.original[id]?.includes(filterValue);
        },

        Cell: ({ row, renderedCellValue }) => {
          if (row.original.statementId) {
            const {
              errors: errorsFrontEndUnfiltered,
              statementAndStatementDetailsMatch,
            } = checkStatementAndStatementDetailsMatchRevenue(row.original);
            const errorsFrontEnd = errorsFrontEndUnfiltered.filter((error) =>
              error.includes("deduct")
            );
            const errorsBackEnd =
              row.original.errors?.filter((error) =>
                error.includes("deduct")
              ) || [];
            const errors = [];
            errorsFrontEnd.forEach((e) =>
              errorsBackEnd.includes(e)
                ? errors.push(`${e}-FE/BE`)
                : errors.push(`${e}-FE`)
            );
            errorsBackEnd.forEach((e) =>
              errorsFrontEnd.includes(e) ? null : errors.push(`${e}-BE`)
            );

            return (
              <Stack direction="column" gap={1}>
                {renderedCellValue}
                {errors?.map((error) => (
                  <TypographyError key={nanoid()}>{error}</TypographyError>
                ))}
              </Stack>
            );
          } else {
            return (
              <Typography
                onClick={() =>
                  alert(
                    "There is no Statement ID for this line item.  Please inform developer."
                  )
                }
              >
                {row.original.revenue}
              </Typography>
            );
          }
        },
      },
      {
        accessorKey: "netted",
        header: "netted",
        sortingFn: (a, b) => sortingFnRowStrToNum(a, b, "netted"),
        // Cell: ({ row }) => <LinkWrapped to={`/invoices/${row.original._id}`}>{row.original.amtOriginal}</LinkWrapped>

        filterFn: (row, id, filterValue) => {
          if (filterValue == "*") {
            const {
              errors,
              statementAndStatementDetailsMatch:
                statementAndStatementDetailsMatchFrontEnd,
            } = checkStatementAndStatementDetailsMatchRevenue(row.original);
            const statementAndStatementDetailsMatchBackEnd =
              row.original.statementAndStatementDetailsMatch;
            const statementAndStatementDetailsMatch =
              statementAndStatementDetailsMatchFrontEnd &&
              statementAndStatementDetailsMatchBackEnd;
            return !statementAndStatementDetailsMatch;
          }
          return row.original[id]?.includes(filterValue);
        },

        Cell: ({ row, renderedCellValue }) => {
          if (row.original.statementId) {
            const {
              errors: errorsFrontEndUnfiltered,
              statementAndStatementDetailsMatch,
            } = checkStatementAndStatementDetailsMatchRevenue(row.original);
            const errorsFrontEnd = errorsFrontEndUnfiltered.filter((error) =>
              error.includes("netted")
            );
            const errorsBackEnd =
              row.original.errors?.filter((error) =>
                error.includes("netted")
              ) || [];
            const errors = [];
            errorsFrontEnd.forEach((e) =>
              errorsBackEnd.includes(e)
                ? errors.push(`${e}-FE/BE`)
                : errors.push(`${e}-FE`)
            );
            errorsBackEnd.forEach((e) =>
              errorsFrontEnd.includes(e) ? null : errors.push(`${e}-BE`)
            );

            return (
              <Stack direction="column" gap={1}>
                {renderedCellValue}
                {errors?.map((error) => (
                  <TypographyError key={nanoid()}>{error}</TypographyError>
                ))}
              </Stack>
            );
          } else {
            return (
              <Typography
                onClick={() =>
                  alert(
                    "There is no Statement ID for this line item.  Please inform developer."
                  )
                }
              >
                {row.original.revenue}
              </Typography>
            );
          }
        },
      },
      {
        accessorKey: "total",
        header: "total",
        sortingFn: (a, b) => sortingFnRowStrToNum(a, b, "total"),
        // Cell: ({ row }) => <LinkWrapped to={`/invoices/${row.original._id}`}>{row.original.amtOriginal}</LinkWrapped>

        filterFn: (row, id, filterValue) => {
          if (filterValue == "*") {
            const {
              errors,
              statementAndStatementDetailsMatch:
                statementAndStatementDetailsMatchFrontEnd,
            } = checkStatementAndStatementDetailsMatchRevenue(row.original);
            const statementAndStatementDetailsMatchBackEnd =
              row.original.statementAndStatementDetailsMatch;
            const statementAndStatementDetailsMatch =
              statementAndStatementDetailsMatchFrontEnd &&
              statementAndStatementDetailsMatchBackEnd;
            return !statementAndStatementDetailsMatch;
          }
          return row.original[id]?.includes(filterValue);
        },

        Cell: ({ row, renderedCellValue }) => {
          if (row.original.statementId) {
            const {
              errors: errorsFrontEndUnfiltered,
              statementAndStatementDetailsMatch,
            } = checkStatementAndStatementDetailsMatchRevenue(row.original);
            const errorsFrontEnd = errorsFrontEndUnfiltered.filter((error) =>
              error.includes("total")
            );
            const errorsBackEnd =
              row.original.errors?.filter((error) => error.includes("total")) ||
              [];
            const errors = [];
            errorsFrontEnd.forEach((e) =>
              errorsBackEnd.includes(e)
                ? errors.push(`${e}-FE/BE`)
                : errors.push(`${e}-FE`)
            );
            errorsBackEnd.forEach((e) =>
              errorsFrontEnd.includes(e) ? null : errors.push(`${e}-BE`)
            );

            return (
              <Stack direction="column" gap={1}>
                {renderedCellValue}
                {errors?.map((error) => (
                  <TypographyError key={nanoid()}>{error}</TypographyError>
                ))}
              </Stack>
            );
          } else {
            return (
              <Typography
                onClick={() =>
                  alert(
                    "There is no Statement ID for this line item.  Please inform developer."
                  )
                }
              >
                {row.original.revenue}
              </Typography>
            );
          }
        },
      },
    ],
    [columnVisibility]
  );

  const columnsJib = useMemo(
    () => [
      {
        accessorKey: "statementId",
        header: "statementId",
      },
      {
        accessorKey: "cc",
        header: "cc",
        sortingFn: "alphanumeric",
        Cell: ({ row }) => {
          let text = row.original.cc || "";
          let subtext = row.original.ccId || "";
          if (subtext) {
            return (
              <Stack>
                <Typography variant="body">{text}</Typography>
                <Typography variant="caption" sx={{ opacity: 0.6 }}>
                  {subtext}
                </Typography>
              </Stack>
            );
          }
          return text;
        },
      },
      {
        accessorKey: "afe",
        header: "afe",
        sortingFn: "alphanumeric",
        Cell: ({ row }) => {
          let text = row.original.afe || "";
          let subtext = row.original.afeOp || "";
          if (subtext) {
            return (
              <Stack>
                <Typography variant="body">{text}</Typography>
                <Typography variant="caption" sx={{ opacity: 0.6 }}>
                  {subtext}
                </Typography>
              </Stack>
            );
          }
          return text;
        },
      },
      {
        accessorKey: "wellName",
        header: "wellName",
      },
      {
        accessorKey: "amtOriginal",
        header: "amtOriginal",
        sortingFn: (a, b) => sortingFnRowStrToNum(a, b, "amtOriginal"),

        filterFn: (row, id, filterValue) => {
          if (filterValue == "*") {
            const { errors, statementAndStatementDetailsMatch } =
              checkStatementAndStatementDetailsMatchJib(row.original);
            return !statementAndStatementDetailsMatch;
          }
          return row.original[id]?.includes(filterValue);
        },

        Cell: ({ row, renderedCellValue }) => {
          if (row.original.statementId) {
            const linkComponent = (
              <LinkWrapped
                to={`/clients/${clientELid}/invoices/${invoiceId_}/${row.original.statementId}`}
              >
                {row.original.amtOriginal}
              </LinkWrapped>
            );

            const { errors, statementAndStatementDetailsMatch } =
              checkStatementAndStatementDetailsMatchJib(row.original);
            if (!statementAndStatementDetailsMatch) {
              return (
                <Stack direction="column" gap={0}>
                  {linkComponent}
                  {errors
                    .filter((error) => !error.includes("amtOriginal"))
                    .map((error) => (
                      <TypographyError
                        key={`${row.original.statementId}-${error}-${nanoid()}`}
                      >
                        {error}
                      </TypographyError>
                    ))}
                </Stack>
              );
            }
            return linkComponent;
          } else {
            return (
              <Typography
                onClick={() =>
                  alert(
                    "There is no Statement ID for this line item.  Please inform developer."
                  )
                }
              >
                {row.original.amtOriginal}
              </Typography>
            );
          }
        },
      },
    ],
    [columnVisibility]
  );

  const columns = docType == "REVENUE" ? columnsRevenue : columnsJib;

  let globalButtons = (
    <>
      {docType == "REVENUE" && (
        <IconButtonStyled
          // color={isChecked ? "secondary" : "primary"}
          // CHANGED
          // onClick={() => handlePrintCsvRevenue(invoice, client)}
          onClick={() => handleExportInvoice(invoice, "REVENUE")}
          toolTip="Export as CSV"
        >
          <FileDownloadRounded />
        </IconButtonStyled>
      )}
      {docType == "JIB" && (
        <IconButtonStyled
          // color={isChecked ? "secondary" : "primary"}
          // CHANGED
          // onClick={() => handlePrintCsvJib(invoice, client)}
          onClick={() => handleExportInvoice(invoice, "JIB")}
          toolTip="Export as CSV"
        >
          <FileDownloadRounded />
        </IconButtonStyled>
      )}

      {/* NEED TO UPDATE/CREATE handleInvoicePull in order to have this here */}
      {/* <IconButtonStyled
        onClick={() => handleInvoicePull(invoice)}
        disabled={isFetchingLocally}
        toolTip={invoice.statementsRepulled?.bool ?`Repull (last ${formatDate(new Date(invoice.statementsRepulled.date))})` : `Repull`}
        sx = {{
          opacity: invoice.statementsRepulled?.bool ? 0.6 : 0.6,
        }}
      >
        {invoice.statementsRepulled?.bool ? <PublishedWithChangesRounded /> : <AutorenewRounded />}
      </IconButtonStyled> */}
    </>
  );

  return (
    <>
      <Pod
        elevation={0}
        sx={{
          minWidth: 320,
          minHeight: 100,
          maxHeight: 600,
          width: 1,
          borderRadius: 0,
          ...sx,
        }}
      >
        <MRT
          // tableType="invoicess"
          data={docsOriginal}
          columns={columns}
          dataOriginal={docsOriginal}
          setDataOriginal={setDocsOriginal}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          estimatedNumOfRows={1}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
          globalButtons={globalButtons}
          enableTopToolbar={enableTableHeader ? true : false}
          enableColumnResizing={enableTableHeader ? true : false}
          enableTableHead={enableTableHeader ? true : false}
          enableGlobalFilter={enableTableHeader ? true : false}
          enableFilters={enableTableHeader ? true : false}
          enableHiding={enableTableHeader ? true : false}
          enableDensityToggle={enableTableHeader ? true : false}
          enableFullScreenToggle={enableTableHeader ? true : false}
          enableToolbarInternalActions={enableTableHeader ? true : false}
          enableRowNumbers={enableTableHeader ? true : false}
        />
      </Pod>
    </>
  );
}

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Box, Button, Typography, Stack } from '@mui/material';
import { Logo } from '../../components/utils/Logo';
import { LogoText } from '../../components/utils/LogoText';
import { SignInNew } from '../signin/components/SignInNew';
import { RegisterContentNew } from '../register/components/RegisterContentNew';

const logoVariants = {
  floating: {
    y: [-3, 3],
    transition: {
      repeat: Infinity,
      repeatType: 'reverse',
      duration: 2,
      ease: 'easeInOut',
    },
  },
  stopped: {
    y: 0,
  },

  up: {
    y: -20,
    transition: {
      duration: 0.3,
    },
  },
  down: {
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
};


const loginVariants = {
  hidden: {
    opacity: 0,
    y: -50,
  },
  visible: {
    opacity: 1,
    y: -60,
  },
};

const logoTextVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

export default function HomeLoggedOut({

}) {

  const [logoState, setLogoState] = useState('down')
  const [loginState, setLoginState] = useState('hidden')

  const [floating, setFloating] = useState(true)

  const [signInRegister, setSignInRegister] = useState('signIn')

  const handleLogoClick = () => {
    if (floating) {
      setFloating(false)
    }
  
    if (logoState === 'down') {
      setLogoState('up');
      setLoginState('visible');
    } else {
      setLogoState('down');
      setLoginState('hidden');
    }

    if (!floating) {
      setFloating(true)
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        width: '100%',
        height: '100%',
      }}
    >

      <Stack justifyContent="center" alignItems="center"
        sx={{
          width: 1,
          minHeight: "100vh",
          maxHeight: "100vh",
          pt: !floating && logoState === "down" ? "10vh" : "10vh",
        }}
      >

        <motion.div
          initial="floating"
          variants={logoVariants}
          animate={floating ? "floating" : logoState}
          onClick={handleLogoClick}
          style={{ cursor: 'pointer' }}
        >
          <Stack justifyContent="center" gap={3}>
            <Logo whiteVersion width={200} />
            <motion.div
              variants={logoTextVariants}
              animate={logoState === 'down' ? 'visible' : 'hidden'}
            >
              <LogoText whiteVersion width={300} />
            </motion.div>
          </Stack>
        </motion.div>

        <motion.div
          variants={loginVariants}
          initial="hidden"
          animate={loginState}
          style={{ mt: 10 }}
        >
          {signInRegister === 'signIn' ?
            <SignInNew setSignInRegister={setSignInRegister} />
            :
            <></>
            // <RegisterContentNew setSignInRegister={setSignInRegister} />
          }
        </motion.div>

      </Stack>

    </motion.div>
  );
};

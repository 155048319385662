import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { PageContainer } from "../../components/layout/PageContainer";
import { TableStatements } from "./components/TableStatements";
import { DescriptionTwoTone } from "@mui/icons-material";
import { LinkWrapped } from "../../components/utils/LinkWrapped";
import { Stack, Tooltip } from "@mui/material";
import { PageTitle } from "../../components/layout/PageTitle";
import { ifNegNumConvertToParenthesesFormat } from "../../utils/ifParenthesesStrConvertToNegNum";
import { TypographyFirstAndSecond } from "../../components/utils/TypographyFirstAndSecond";
import { useFetchDocById } from "../../utils/hooks/useFetchDocById";
import { TypographyError } from "../../components/utils/TypographyError";
import { nanoid } from "nanoid";
import { checkErrorsInvoice } from "../../utils/errorChecking";
import { LoadingModal } from "../../components/utils/LoadingModal";
import { useScrapeInvoices } from "../../utils/hooks/webscraping/useScrapeInvoices";
import { InvoicePullAndExportButtons } from "./components/InvoicePullAndExportButtons";
import { StateContext } from "../../state-management/app/useContext/StateContext";
import { SCRAPETYPE } from "../../utils/constants";
import { ButtonMoreInfo } from "../../components/utils/ButtonMoreInfo";
import { TypographyWarning } from "../../components/utils/TypographyWarning";
import { useFetchStatementsByInvoiceId } from "../../utils/hooks/useFetchStatementsByInvoiceId";

export default function Invoice({}) {
  const { id: clientELid, invoiceId: invoiceId_ } = useParams();

  //////////////////////////
  // STATE
  //////////////////////////

  // GENERAL
  const appState = useContext(StateContext);

  const { doc: client, isFetching: isFetchingClient } = useFetchDocById(
    clientELid,
    "ELid",
    "clients",
    { consoleLog: true }
  );

  const {
    doc: invoice,
    setDoc: setInvoice,
    // docs: statements,
    isFetching: isFetchingInvoice,
  } = useFetchDocById(invoiceId_, "_id", "invoices", {
    // TODO: project
    // project: true,
    consoleLog: true,
    // getDocsProperty: "statements",
    // sortFn: sortFnStatements,
  });

  const { docs: statements, isFetching: isFetchingStatements } =
    useFetchStatementsByInvoiceId(invoiceId_);

  // ADDITIONAL
  const [invoiceWithStatements, setInvoiceWithStatements] = useState();
  const [docsFinal, setDocsFinal] = useState([]); // if doing modifications

  const [isLoadingTable, setIsLoadingTable] = useState(true);

  // TODO: ERRORS
  // if (invoiceWithStatements) {
  //   const { errors: errorsFrontEndUnfiltered, invoiceAndStatementDetailsMatch } =
  //     checkErrorsInvoice(invoiceWithStatements);
  // }
  // const [errorsFrontEnd, setErrorsFrontEnd] = useState([]);
  // const [errorsBackEnd, setErrorsBackEnd] = useState([]);
  // const [errors, setErrors] = useState([]);

  const { scrapeInvoices, scrapedInvoices, isScrapingInvoices } =
    useScrapeInvoices(
      SCRAPETYPE.getInvoicesAndStatementsOfClients,
      client,
      appState
    );
  // used in MRT child component (fetching >1 invoice), so can't get rid of and use isScrapingInvoices
  // ...consider handling fetching >1 invoice within MRT (then, can remove from here & only use isScrapingInvioces ?)
  const [isFetchingLocally, setIsFetchingLocally] = useState(false);

  //////////////////////////
  // EFFECTS
  //////////////////////////

  // UPDATE DOCSFINAL WITH DOCSORIGINAL (UNMODIFIED)
  useEffect(() => {
    if (isFetchingInvoice) return;

    if (statements.length > 0 && modificationsNotPresent(statements)) {
      // if initial page load (!modificationsPresent) && docs exist, start showing table to user (no modifications present, but they aren't needed until user clicks something)
      setDocsFinal(statements);
      setIsLoadingTable(false); // start showing table to user
    } else if (statements.length == 0) {
      // if initial page load && no docs, no modifications to be done (show table -- "no data" version)
      setIsLoadingTable(false);
    }
  }, [statements]);

  // TODO: ERRORS
  // SET BACKENDERRORS
  // useEffect(() => {
  //   if (invoice.errors?.length > 0 && errorsBackEnd.length == 0) {
  //     setErrorsBackEnd(invoice.errors);
  //   }
  //   if (errorsFrontEndUnfiltered.length > 0 && errorsFrontEnd.length == 0) {
  //     setErrorsFrontEnd(errorsFrontEndUnfiltered);
  //   }
  // }, [invoiceWithStatements, errorsFrontEndUnfiltered]);

  // TODO: ERRORS
  // useEffect(() => {
  //   if (errorsFrontEnd.length > 0 || errorsBackEnd.length > 0) {
  //     const errorsTemp = [];
  //     errorsFrontEnd.forEach((e) =>
  //       errorsBackEnd.includes(e)
  //         ? errorsTemp.push(`${e}-FE/BE`)
  //         : errorsTemp.push(`${e}-FE`)
  //     );
  //     errorsBackEnd.forEach((e) =>
  //       errorsFrontEnd.includes(e) ? null : errorsTemp.push(`${e}-BE`)
  //     );
  //     setErrors(errorsTemp);
  //   }
  // }, [errorsFrontEnd, errorsBackEnd]);

  useEffect(() => {
    if (isScrapingInvoices) return;
    if (scrapedInvoices.length === 0) return;

    setIsFetchingLocally(false);
    setInvoice(scrapedInvoices.at(0)); // not updating doc ?
    window.location.reload(); // since ^ not updating doc
  }, [isScrapingInvoices]);

  //////////////////////////
  // FUNCTIONS
  //////////////////////////

  function modificationsNotPresent(docs, modification) {
    // modifications should be considered "not present" even if only one doc is missing the modification (could mess up future functions/events for that doc)
    // modifications useEffect (e.g. in Clients.js)
    const modificationsNotPresent = true;
    return modificationsNotPresent;
  }

  function handleInvoicePull(invoice) {
    setIsFetchingLocally(true);

    let scrapeInvoicesArg = {
      client: { ELid: invoice.clientELid },
      invoiceId: invoice.invoiceId,
    }; // OLD

    console.log(scrapeInvoicesArg);
    scrapeInvoices(scrapeInvoicesArg);
  }

  // let pageTitleName = "Invoice"
  let pageTitle =
    // doc &&
    invoice.docType === "REVENUE" ? (
      <PageTitle
        icon={<DescriptionTwoTone sx={{ fontSize: "4rem" }} />}
        contentRightOfIconTitle={
          <Stack sx={{ ml: 2 }}>
            <TypographyFirstAndSecond
              first={`Invoice # (${invoice.docType})`}
              second={invoice.docNum}
            />
            <TypographyFirstAndSecond
              first={`Op Owner #`}
              second={invoice.opOwnerNum}
            />
            <TypographyFirstAndSecond
              first={`Operator`}
              second={
                invoice._id && `${invoice.opOwnerNum} - ${invoice.operator}`
              }
            />
            <TypographyFirstAndSecond
              first={`Owners`}
              second={
                invoice.orgNum
                  ? `${invoice.orgNum} - ${client?.name}`
                  : client?.name
              }
            />
            <TypographyFirstAndSecond
              first={`Invoice (EL)`}
              second={
                <>
                  <LinkWrapped to={invoice.invoiceUrl} external newTab>
                    Go to Invoice
                  </LinkWrapped>{" "}
                  <i>(must be logged in to that client)</i>
                </>
              }
            />

            <InvoicePullAndExportButtons
              invoice={invoice}
              handleInvoicePull={handleInvoicePull}
              isFetchingLocally={isFetchingLocally}
            />

            {/* TODO: ERRORS */}
            {/* ERROR CHECKING */}
            {/* {errors.length > 0 && (
              <Stack>
                {errors.map((error) => (
                  <TypographyError key={`${invoice._id}-${error}-${nanoid()}`}>
                    {error}
                  </TypographyError>
                ))}
              </Stack>
            )} */}
          </Stack>
        }
        contentRightOfPage={
          <Stack sx={{ mr: 5 }}>
            <TypographyFirstAndSecond
              right
              first={`Invoice # (${invoice.docType})`}
              second={invoice.docNum}
            />
            <TypographyFirstAndSecond
              right
              first={`Revenue`}
              second={
                invoice._id &&
                ifNegNumConvertToParenthesesFormat(invoice.totals?.revenue)
              }
            />
            <TypographyFirstAndSecond
              right
              first={`Tax`}
              second={
                invoice._id &&
                ifNegNumConvertToParenthesesFormat(invoice.totals?.tax)
              }
            />
            <TypographyFirstAndSecond
              right
              first={`Deductions`}
              second={
                invoice._id &&
                ifNegNumConvertToParenthesesFormat(invoice.totals?.deductions)
              }
            />
            {invoice.totals?.netted ? (
              <TypographyFirstAndSecond
                right
                first={`Netted`}
                second={
                  invoice._id &&
                  ifNegNumConvertToParenthesesFormat(invoice.totals?.netted)
                }
              />
            ) : null}
            <TypographyFirstAndSecond
              right
              first={`Total`}
              second={
                invoice._id &&
                ifNegNumConvertToParenthesesFormat(invoice.totals?.total)
              }
            />
          </Stack>
        }
      />
    ) : (
      <PageTitle
        icon={<DescriptionTwoTone sx={{ fontSize: "4rem" }} />}
        contentRightOfIconTitle={
          <Stack sx={{ ml: 2 }}>
            <TypographyFirstAndSecond
              first={`Invoice # (${invoice.docType})`}
              second={invoice.docNum}
            />
            <TypographyFirstAndSecond
              first={`Op Owner #`}
              second={invoice.opOwnerNum}
            />
            <TypographyFirstAndSecond
              first={`Operator`}
              second={
                invoice._id && `${invoice.opOwnerNum} - ${invoice.operator}`
              }
            />
            <TypographyFirstAndSecond
              first={`Owners`}
              second={
                invoice.orgNum
                  ? `${invoice.orgNum} - ${client?.name}`
                  : client?.name
              }
            />
            <TypographyFirstAndSecond
              first={`Invoice (EL)`}
              second={
                <>
                  <LinkWrapped to={invoice.invoiceUrl} external newTab>
                    Go to Invoice
                  </LinkWrapped>{" "}
                  <i>(must be logged in to that client)</i>
                </>
              }
            />

            <Stack direction="row" alignItems="center" gap={1}>
              <InvoicePullAndExportButtons
                invoice={invoice}
                handleInvoicePull={handleInvoicePull}
                isFetchingLocally={isFetchingLocally}
              />
              {invoice?.docType == "JIB" && invoice?.errors === undefined && (
                <Stack direction="row" gap={0.5}>
                  <ButtonMoreInfo
                    iconOnly
                    iconButtonColor="warning"
                    sxIcon={{ fontSize: "1rem", mt: 0.2 }}
                    label='Repull for "Deep" Error Checking'
                  />
                  <TypographyWarning>
                    Repull for "Deep" Error Checking
                  </TypographyWarning>
                </Stack>
              )}
            </Stack>

            {/* TODO: ERRORS */}
            {/* ERROR CHECKING */}
            {/* {errors.length > 0 && (
              <Stack>
                {errors.map((error) => (
                  <TypographyError key={`${invoice._id}-${error}-${nanoid()}`}>
                    {error}
                  </TypographyError>
                ))}
              </Stack>
            )} */}
          </Stack>
        }
        contentRightOfPage={
          <Stack sx={{ mr: 5 }}>
            <TypographyFirstAndSecond
              right
              first={`Invoice # (${invoice.docType})`}
              second={invoice.docNum}
            />
            <TypographyFirstAndSecond
              right
              first={`AFE*`}
              firstTooltip="This display number may be a total of AFE + any other non-Operations columns (e.g. Cash Call)"
              second={
                invoice._id &&
                ifNegNumConvertToParenthesesFormat(invoice.totals?.afe)
              }
            />
            <TypographyFirstAndSecond
              right
              first={`Operations`}
              second={
                invoice._id &&
                ifNegNumConvertToParenthesesFormat(invoice.totals?.operations)
              }
            />
            <TypographyFirstAndSecond
              right
              first={`Total`}
              second={
                invoice._id &&
                ifNegNumConvertToParenthesesFormat(invoice.totals?.total)
              }
            />
          </Stack>
        }
      />
    );

  return (
    <>
      <PageContainer title={pageTitle}>
        <TableStatements
          docsOriginal={docsFinal} // TODO: docsFinal
          setDocsOriginal={setDocsFinal} // setDataOriginal (MRT)
          isLoading={isLoadingTable}
          setIsLoading={setIsLoadingTable}
          initialSettings={{
            tableView: "table",
          }}
          mainPage
          // additional
          invoice={invoice}
          docType={invoice.docType}
        />
      </PageContainer>

      <LoadingModal open={isFetchingLocally} />
    </>
  );
}

export function formatToCamelCase(str="") {
  const camelCase = str
    .split(' ')
    .map((word, i) => {
      if (i === 0) return word.toLowerCase();
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('')
    .replaceAll(/[_\W]/g, '')

  return camelCase
}

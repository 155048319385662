import React from 'react'
// import { SignIn as SignInComponent } from './components/SignIn'
import { SignInSide as SignInComponent } from './components/SignInSide'
import { PageContainer } from '../../components/layout/PageContainer'

export default function SignIn() {


  return (
    <>
      <PageContainer justifyContent="center" toolbarSpacing={false}>
        <SignInComponent />
      </PageContainer>
    </>
  )
}

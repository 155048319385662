import React from "react"
import { Chip } from "@mui/material"


export function ChipNavItem({
  navStateActive,
  navState,
  docs,
  color
}) {


  return (
    <>
      <Chip size="small" label={docs.length} color={color ? color : navState == navStateActive ? "primary" : "default"} sx={{fontWeight: 400, opacity: 0.8}}/>
    </>
  )
}

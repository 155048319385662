export function ifParenthesesStrConvertToNegNum(str="") {
  // console.log("VALUE", str)
  
  if (str === null) str = ""
  str = str.trim()
  
  if (str.at(0) === "(" && str.at(-1) === ")") {
    str = str.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(",", "").replaceAll("$", "") // handle (, ), -  (e.g. (-1.245), (1.245) )
    return Number(str) * -1
  }
  else {
    str = str.replaceAll(",", "")
    return Number(str)
  }
}

export function ifNegNumConvertToParenthesesFormat(num=0) {
  if (typeof num == "number") {
    num = num.toFixed(2)
  }
  let str = `${num}`
  if (str.at(0) === "-") {
    str = str.replace("-", "(") + ")"
    return str
  }
  return str
}
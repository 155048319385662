import { Box, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import React from 'react'

export function LinkWrapped({
  to,
  // color = "inherit",
  color = "primary",
  underline = "none",
  className,
  variant,
  sx,
  
  newTab,
  external,

  children
}) {

  return (
    // rel="noopener noreferrer"  target="_blank"
    newTab ?
      external ?
        <>
          {/* <a href={to} rel="noopener noreferrer" target="_blank" className={className}>
            {children}
          </a> */}
          <Box component={"a"} href={to} rel="noopener noreferrer" target="_blank" className={className} sx={{
            color: "primary.main"
          }}>
            {children}
          </Box>
        </>
      :
        <Link component={RouterLink} to={to} rel="noopener noreferrer" target="_blank" color={color} underline={underline} sx={sx} className={className}>
          {children}
        </Link>
    :
      <Link component={RouterLink} to={to} color={color} underline={underline} sx={sx} variant={variant} className={className}>
        {children}
      </Link>
  )
}

// NOTE:  previously passing <null> to new Date() (new Date(null)) PROVIDES A VALID DATE (...don't want it to, usually!)

// usage (d/t above note)
// if (value !== null) {
//   if (isValidDate(new Date(value))) {
//     value = formatDate(value)
//   }
// }



export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function isValidDateStr(d="", reqYearLength) {
  function strIsTwoOrFourCharacters(str="") {
    if (reqYearLength) {
      return str.length === reqYearLength
    }
    return str.length === 2 || str.length === 4
  }

  if (!d.includes("/") && !d.includes("-")) return false
  if (d.includes("/") && (d.split('/').length !== 3 || !strIsTwoOrFourCharacters(d.split('/').at(2)))) return false
  if (d.includes("-") && (d.split('-').length !== 3 || !strIsTwoOrFourCharacters(d.split('-').at(2)))) return false

  return isValidDate(new Date(d))
}
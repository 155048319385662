import { getDocById } from "../../services/axios";
import { useState, useEffect } from "react";
import { handleErrorCatching } from "../handleErrorCatching";

export function useFetchDocById(id, idType, collection, options = {}) {
  const {
    consoleLog = false,
    getDocsProperty = false,
    sortFn = null,
    statementId = null,
  } = options;

  const [doc, setDoc] = useState({}); // on Invoice.js, setDoc done after scraping on page (then need to update "docs" here... messy... (new if Object.keys statements here, etc.))
  const [docs, setDocs] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [statement, setStatement] = useState({});
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if (id && isFetching) {
      const controller = new AbortController();
      async function getDoc() {
        try {
          let fetchedDoc = {};
          if (Object.keys(doc).length == 0) {
            fetchedDoc = await getDocById(
              collection,
              idType,
              id,
              controller,
              consoleLog
            );
            setDoc(fetchedDoc);
          }

          if (getDocsProperty) {
            if (Object.keys(doc).length > 0) {
              setDocs(doc[getDocsProperty].sort(sortFn));
            } else {
              setDocs(fetchedDoc[getDocsProperty].sort(sortFn));
            }
          }
          if (statementId) {
            if (Object.keys(doc).length > 0) {
              const selectedStatement = doc.statements.find(
                (doc) => doc.statementId == statementId
              );
              setStatement(selectedStatement);
              setKeys(Object.keys(selectedStatement.statementDetails));
            } else {
              const selectedStatement = fetchedDoc.statements.find(
                (doc) => doc.statementId == statementId
              );
              setStatement(selectedStatement);
              setKeys(Object.keys(selectedStatement.statementDetails));
            }
          }
        } catch (e) {
          handleErrorCatching(e, collection);
        } finally {
          setIsFetching(false);
        }
      }
      getDoc();
      return () => {
        controller.abort();
      };
    }
  }, []);

  return { doc, setDoc, isFetching, docs, statement, keys };
}

import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios'

import { useImmerReducer } from 'use-immer'
import { CSSTransition } from 'react-transition-group'

import { StateContext } from '../../../state-management/app/useContext/StateContext';
import { DispatchContext } from '../../../state-management/app/useContext/DispatchContext';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { IconButton, InputAdornment, Stack } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import LockOutlined from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import { LinkWrapped } from '../../../components/utils/LinkWrapped';

// import { Logo } from '../../../components/utility/Logo';
// import landingPageMain from "../../../../staticmaybe/images/illustrations/landingpage-main.svg"

export function RegisterContent({
  buttonSx,
}) {

  const logoSvg = "https://res.cloudinary.com/dxnehm70c/image/upload/v1661308010/MB_logo_bluem_ikg6lp.png"
  const Logo = <></>

  // const searchParams = new URLSearchParams(window.location.search)
  const history = useNavigate()
  const goBack = () => {
    history.goBack()
  }

  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)
  const [isLoading, setIsLoading] = useState(false) 
  const [isFetching, setIsFetching] = useState(false)

  //////////////////
  //////////////////
  // OLD LOGIN PAGE

  const [first, setFirst] = useState("")
  const [last, setLast] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  const [isRegistering, setIsRegistering] = useState(false)

  const initialState = {
    first: {
      value: "",
      hasErrors: false,
      message: "",
      isUnique: false,
      checkCount: 0
    },
    last: {
      value: "",
      hasErrors: false,
      message: "",
      isUnique: false,
      checkCount: 0
    },
    username: {
      value: "",
      hasErrors: false,
      message: "",
      isUnique: false,
      checkCount: 0
    },
    email: {
      value: "",
      hasErrors: false,
      message: "",
      isUnique: false,
      checkCount: 0
    },
    password: {
      value: "",
      hasErrors: false,
      message: "",
    },
    submitCount: 0
  }
  function ourReducer(draft, action) {
    switch (action.type) {
      case "firstImmediately":
        draft.first.hasErrors = false // since running after every field value change, want a "clean slate" each time (assume no errors)
        draft.first.value = action.value

        if (draft.first.value && !/^([a-zA-Z'-\s]+)$/.test(draft.first.value)) {
          draft.first.hasErrors = true
          draft.first.message = "First name can only contain letters, hyphens, and apostrophes."
        }
        return       
      case "lastImmediately":
        draft.last.hasErrors = false // since running after every field value change, want a "clean slate" each time (assume no errors)
        draft.last.value = action.value

        if (draft.last.value && !/^([a-zA-Z'-\s]+)$/.test(draft.last.value)) {
          draft.last.hasErrors = true
          draft.last.message = "Last name can only contain letters, hyphens, and apostrophes."
        }
        return       
      case "usernameImmediately":
        draft.username.hasErrors = false // since running after every field value change, want a "clean slate" each time (assume no errors)
        draft.username.value = action.value
        if (draft.username.value.length > 30) {
          draft.username.hasErrors = true
          draft.username.message = "Username cannot exceed 30 characters."
        }
        if (draft.username.value && !/^([a-zA-Z0-9]+)$/.test(draft.username.value)) {
          draft.username.hasErrors = true
          draft.username.message = "Username can only contain letters and numbers."
        }
        return
      case "usernameAfterDelay":
        if (draft.username.value.length < 3) {
          draft.username.hasErrors = true
          draft.username.message = "Username must be at least 3 characters."
        }
        if (!draft.username.hasErrors && !action.noRequest) {
          draft.username.checkCount++
        }
        return
      case "usernameUniqueResults":
        if (action.value) {
          draft.username.hasErrors = true
          draft.username.isUnique = false
          draft.username.message = "That username is already taken."
        } else {
          draft.username.hasErrors = false
          draft.username.isUnique = true
          // draft.username.message = (draft.username.message == "That username is already taken.") && ""
        }
        return

      case "emailImmediately":
        draft.email.hasErrors = false
        draft.email.value = action.value
        return
      case "emailAfterDelay":
        if (!/^\S+@\S+$/.test(draft.email.value)) {
          draft.email.hasErrors = true
          draft.email.message = "You must provide a valid email address."
        }
        if (!draft.email.hasErrors && !action.noRequest) {
         draft.email.checkCount++ 
        }
        return
      case "emailUniqueResults":
        if (action.value) {
          draft.email.hasErrors = true
          draft.email.isUnique = false
          draft.email.message = "That email is already being used."
        } else {
          draft.email.hasErrors = false
          draft.email.isUnique = true
          // draft.email.message = (draft.email.message == "That email is already being used.") && ""
        }
        return

      case "passwordImmediately":
        draft.password.hasErrors = false
        draft.password.value = action.value
        if (draft.password.value.length > 50) {
          draft.password.hasErrors = true
          draft.password.message = "Password cannot exceed 50 characters."
        }
        return
      case "passwordAfterDelay":
        if (draft.password.value.length < 6) {
          draft.password.hasErrors = true
          draft.password.message = "Password must be at least 6 characters."
        }
        return

      case "submitForm":
        if (!draft.first.hasErrors && !draft.last.hasErrors && !draft.username.hasErrors && draft.username.isUnique && !draft.email.hasErrors && draft.email.isUnique && !draft.password.hasErrors) {
          draft.submitCount++
        }
        return
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState)


  //////////////////
  //// USEEFFECTS
  //////////////////

  useEffect(() => {
    if (state.username.value) {
      const delay = setTimeout(() => dispatch({type: "usernameAfterDelay"}), 800) // web browser
      return () => clearTimeout(delay) // clean-up
    }
  }, [state.username.value])

  useEffect(() => {
    if (state.username.checkCount) {
      const ourRequest = axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await axios.post('/doesUsernameExist', {username: state.username.value}, {cancelToken: ourRequest.token})
          console.log("USERNAME: ", response)
          dispatch({type: "usernameUniqueResults", value: response.data})
        } catch(e) {
          console.log("There was a problem or the request was cancelled.")
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    }
  }, [state.username.checkCount])

  useEffect(() => {
    if (state.email.value) {
      const delay = setTimeout(() => dispatch({type: "emailAfterDelay"}), 800) // web browser
      return () => clearTimeout(delay) // clean-up
    }
  }, [state.email.value])

  useEffect(() => {
    if (state.email.checkCount) {
      const ourRequest = axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await axios.post('/doesEmailExist', {email: state.email.value}, {cancelToken: ourRequest.token})
          dispatch({type: "emailUniqueResults", value: response.data})
        } catch(e) {
          console.log("There was a problem or the request was cancelled.")
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    }
  }, [state.email.checkCount])

  useEffect(() => {
    if (state.password.value) {
      const delay = setTimeout(() => dispatch({type: "passwordAfterDelay"}), 800) // web browser
      return () => clearTimeout(delay) // clean-up
    }
  }, [state.password.value])

  useEffect(() => {
    if (state.submitCount) {
      console.log(state.first.value, state.last.value, state.username.value, state.email.value)
      const ourRequest = axios.CancelToken.source()
      async function fetchResults() {
        setIsRegistering(true)
        try {
          // const response = await axios.post('/register', {first: state.first.value, last: state.last.value, username: state.username.value, email: state.email.value, password: state.password.value}, {cancelToken: ourRequest.token})
          const response = await axios.post('/register', {first, last, email, username, password}, {cancelToken: ourRequest.token})
          appDispatch({type: "login", data: response.data})
          appDispatch({type: "flashMessage", value: "Congrats! Welcome to your new account."})
          history('/')
        } catch(e) {
          console.error(e.response.data)
          console.log("There was a problem or the request was cancelled.")
          appDispatch({type: "flashMessage", value: "Sorry, something went wrong. Please try again."})
        } finally {
          setIsRegistering(false)
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    }
  }, [state.submitCount])



  //////////////////
  //// FUNCTIONS
  //////////////////
  function isHomePage() {
    // change body classlist (for logged in theme/sidebar/etc.)
    let body = document.body
    if (!body.classList.contains('homepage')) {
      body.classList.add('homepage')
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    // check all validation upon clicking register button (before making request to server)
    dispatch({type: "usernameImmediately", value: state.username.value})
    dispatch({type: "usernameAfterDelay", value: state.username.value, noRequest: true})
    dispatch({type: "emailImmediately", value: state.email.value})
    dispatch({type: "emailAfterDelay", value: state.email.value, noRequest: true})
    dispatch({type: "passwordImmediately", value: state.password.value})
    dispatch({type: "passwordAfterDelay", value: state.password.value})
    // submit form
    dispatch({type: "submitForm"})
  }

  async function handleSubmit2(e) {
    e.preventDefault()
    try {
      const response = await axios.post('/login', { username, password })
      if (response.data) {
        // change body classlist (for logged in theme/sidebar/etc.)
        let body = document.body
        body.classList.remove('homepage')


        // change navbar (based on now being logged in)
        appDispatch({type: "login", data: response.data}) // via context
        appDispatch({type: "flashMessage", value: "You have successfully logged in."})

      } else {
        console.log("Incorrect username / password.")
        appDispatch({type: "flashMessage", value: "Invalid username/password."})
      }
    } catch(e) {
      console.log(e.response.data)
    }
  }

  async function handleLogin(e) {
    e.preventDefault()
    try {
      console.log(username, password)
      const response = await axios.post('/login', { username, password })
      console.log({response})
      if (response.data) {

        // window.location.reload(false);
        // change navbar (based on now being logged in)
        appDispatch({type: "login", data: response.data}) // via context
        appDispatch({type: "flashMessage", value: "You have successfully logged in."})
        history('/')
      } else {
        console.log("Incorrect username / password.")
        appDispatch({type: "flashMessage", value: "Invalid username/password."})
      }
    } catch(e) {
      console.log(e.response.data)
    }
  }


  return (
      <Container component="main" maxWidth="xs">

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          {/* <Avatar sx={{ m: 1, bgcolor: 'grey.400' }}>
            <Box component="img" src={logoSvg} sx={{width: "20px"}}/>
          </Avatar> */}
          {/* <Logo label={false} width="56px" sx={{height: 1, mb: 1.5}} /> */}

          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoComplete="given-name"
                  autoFocus
                  error={state.first.hasErrors}
                  helperText={state.first.hasErrors && state.first.message}
                  onChange={e => {setFirst(e.target.value); dispatch({type: "firstImmediately", value: e.target.value})}}
                  value={first}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  error={state.last.hasErrors}
                  helperText={state.last.hasErrors && state.last.message}
                  onChange={e => {setLast(e.target.value); dispatch({type: "lastImmediately", value: e.target.value})}}
                  value={last}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  error={state.email.hasErrors}
                  helperText={state.email.hasErrors && state.email.message}
                  onChange={e => {setEmail(e.target.value); dispatch({type: "emailImmediately", value: e.target.value})}} value={email}
                />
                {/* <CSSTransition in={state.email.hasErrors} timeout={330} classNames="liveValidateMessage" unmountOnExit><div className="alert alert-danger small liveValidateMessage">{state.email.message}</div></CSSTransition> */}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  error={state.username.hasErrors}
                  helperText={state.username.hasErrors && state.username.message}
                  onChange={e => {setUsername(e.target.value); dispatch({type: "usernameImmediately", value: e.target.value})}} value={username}
                />
                {/* <CSSTransition in={state.username.hasErrors} timeout={330} classNames="liveValidateMessage" unmountOnExit><div className="alert alert-danger small liveValidateMessage">{state.username.message}</div></CSSTransition> */}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={!showPassword ? "password" : "text"}
                  id="password"
                  autoComplete="new-password"
                  error={state.password.hasErrors}
                  helperText={state.password.hasErrors && state.password.message}
                  onChange={e => {setPassword(e.target.value); dispatch({type: "passwordImmediately", value: e.target.value})}} value={password}

                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{mr: 0.5}}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                    ),
                  }}
                />
                {/* <CSSTransition in={state.password.hasErrors} timeout={330} classNames="liveValidateMessage" unmountOnExit><div className="alert alert-danger small liveValidateMessage">{state.password.message}</div></CSSTransition> */}
              </Grid>

              {/* MARKETING EMAILS SIGNUP SECTION */}
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive important app updates and occasional marketing promotions via email."
                />
              </Grid> */}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isRegistering}
              sx={{ ...buttonSx, mt: 3, mb: 2 }}
            >
              {isRegistering ? "Signing Up..." : "Sign Up"}
            </Button>

            {/* BOTTOM LINKS */}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <LinkWrapped to="/signin">
                  Already have an account? Sign in
                </LinkWrapped>
              </Grid>
            </Grid>

          </Box>

        </Box>

      </Container>
  )
}

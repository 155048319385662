import React, { useMemo } from "react"
import MaterialReactTable from "material-react-table";

import DraggableBox from './components/DraggableBox';
import { useFetchClinicDocs } from "../../utils/hooks/useFetchClinicDocs";



export function Testing({
  
}) {
  



  // BETA API (NEW)

  // decoded request URL
  /////////////////////////
  // url = `https://beta.clinicaltrials.gov/api/int/studies?query.cond=heart attack&filter.geo=distance(46.8017352,-96.8426281,50mi)&agg.synonyms=true&aggFilters=&from=0&limit=10&fields=OverallStatus,HasResults,BriefTitle,Condition,InterventionType,InterventionName,LocationFacility,LocationCity,LocationState,LocationCountry,LocationStatus,LocationZip,LocationGeoPoint,LocationContactName,LocationContactRole,LocationContactPhone,LocationContactPhoneExt,LocationContactEMail,CentralContactName,CentralContactRole,CentralContactPhone,CentralContactPhoneExt,CentralContactEMail,Gender,MinimumAge,MaximumAge,StdAge,NCTId,StudyType,LeadSponsorName,Acronym,EnrollmentCount,StartDate,PrimaryCompletionDate,CompletionDate,StudyFirstPostDate,ResultsFirstPostDate,LastUpdatePostDate,OrgStudyId,SecondaryId,Phase,LargeDocLabel,LargeDocFilename,PrimaryOutcomeMeasure,SecondaryOutcomeMeasure,DesignAllocation,DesignInterventionModel,DesignMasking,DesignWhoMasked,DesignPrimaryPurpose,DesignObservationalModel,DesignTimePerspective,LeadSponsorClass,CollaboratorClass&columns=conditions,interventions,collaborators&highlight=true`
  // url = `https://beta.clinicaltrials.gov/api/
  // // int/
  // // studies
  // // ?
  // // query.cond=heart attack
  // &filter.geo=distance(46.8017352,-96.8426281,50mi)
  // &agg.synonyms=true
  // &aggFilters=
  // &from=0
  // &limit=10
  // &fields=OverallStatus,HasResults,BriefTitle,Condition,InterventionType,InterventionName,LocationFacility,LocationCity,LocationState,LocationCountry,LocationStatus,LocationZip,LocationGeoPoint,LocationContactName,LocationContactRole,LocationContactPhone,LocationContactPhoneExt,LocationContactEMail,CentralContactName,CentralContactRole,CentralContactPhone,CentralContactPhoneExt,CentralContactEMail,Gender,MinimumAge,MaximumAge,StdAge,NCTId,StudyType,LeadSponsorName,Acronym,EnrollmentCount,StartDate,PrimaryCompletionDate,CompletionDate,StudyFirstPostDate,ResultsFirstPostDate,LastUpdatePostDate,OrgStudyId,SecondaryId,Phase,LargeDocLabel,LargeDocFilename,PrimaryOutcomeMeasure,SecondaryOutcomeMeasure,DesignAllocation,DesignInterventionModel,DesignMasking,DesignWhoMasked,DesignPrimaryPurpose,DesignObservationalModel,DesignTimePerspective,LeadSponsorClass,CollaboratorClass
  // &columns=conditions,interventions,collaborators
  // &highlight=true`

  // actual website URL after request URL
  /////////////////////////
  // https://beta.clinicaltrials.gov/search?lat=46.8017352&lng=-96.8426281&locStr=Current%20Location&distance=50&cond=heart%20attack

  // results obj
  /////////////////////////
  // (see resultsObj.json)


  


  // OLD API


  // https://clinicaltrials.gov/api/query/list?expr=glaucoma &min_rnk=1&max_rnk=100&fmt=json
  // https://clinicaltrials.gov/api/query/list?expr=glaucoma +AND+AREA[Location](RADIUS(50.0,42.3601,-71.0589)) &min_rnk=1&max_rnk=100&fmt=json
  // https://clinicaltrials.gov/api/query/list?expr=glaucoma +AND+AGE%20BETWEEN%2018%20AND%2065 &min_rnk=1&max_rnk=100&fmt=json
  // https://clinicaltrials.gov/api/query/list?expr=glaucoma +AND+AREA[Location](RADIUS(50.0,42.3601,-71.0589) )+AND+AGE%20BETWEEN%2018%20AND%2065 &min_rnk=1&max_rnk=100&fmt=json


  // const urlBase = `https://clinicaltrials.gov/api/query/study_fields`
  const urlBase = `https://clinicaltrials.gov/api/query/full_studies`

  const api = ``
  // const api = `/list`

  const subject = `glaucoma`

  const fields = [
    'NCTId', // Unique identifier for the study // studyURL = `https://clinicaltrials.gov/ct2/show/${nctId}`;
    "OverallStatus", // overall status of the study ("Recruiting," "Completed," "Withdrawn," "Terminated," etc.)
    "EnrollmentType", // enrollment type ("Actual" or "Anticipated." )
      // Actual: The "Actual" enrollment type means that the reported number of participants in the EnrollmentCount field reflects the actual number of participants who have been enrolled in the study at the time the record was last updated. This value is generally provided after the study has started enrolling participants, and it may be updated as enrollment progresses or when the study is completed.
      // Anticipated: The "Anticipated" enrollment type means that the reported number of participants in the EnrollmentCount field is an estimate or a target number of participants that the study investigators plan to enroll. This value is generally provided before the study starts enrolling participants, and it may be based on feasibility assessments, statistical considerations, or other planning factors.
    "EnrollmentCount", // enrollment count
    'BriefTitle', // Title (brief version)
    'StartDate', // Study start date
    'LocationCity', // City of the study location
    'LocationState', // State of the study location
    'LocationCountry', // Country of the study location
    // 'BriefSummary', // Brief description of the study
    // 'URL', // URL to the study details page on ClinicalTrials.gov 
  ]
  
  const fieldsString = fields.join('%2C')

  // URL: https://clinicaltrials.gov/api/query/study_fields?expr=glaucoma&fields=BriefTitle&min_rnk=1&max_rnk=100&fmt=json
  // URL: https://clinicaltrials.gov/api/query/study_fields?expr=glaucoma&fields=BriefTitle%2CReferenceType&min_rnk=1&max_rnk=10&fmt=json
  // URL: https://clinicaltrials.gov/api/query/study_fields/list?expr=glaucoma&fields=BriefTitle&min_rnk=1&max_rnk=100&fmt=json
  const minRank = 1
  const maxRank = 100
  const minMax = `&min_rnk=${minRank}&max_rnk=${maxRank}`

  const resultsFormat = `&fmt=json`

  let urlWithoutParameters = `${urlBase}${api}?expr=${subject}&fields=${fieldsString}${minMax}${resultsFormat}`
  // let url = `${urlBase}${api}?expr=${subject}&fields=${fieldsString}${minMax}${resultsFormat}` // study_fields
  let url = `${urlBase}${api}?expr=${subject}${minMax}${resultsFormat}` // full_studies
  const useANDparameters = true
  const useORparameters = false


  // ADDITIOANL PARAMETERS
  // WORKING
  const useLocation = true
  // const location = `SEARCH[Location](AREA[LocationState](North+Dakota)+OR+AREA[LocationState](South+Dakota)+OR+AREA[LocationState](Minnesota)+OR+AREA[LocationState](Missouri))`; // ND, SD, MN, MO
  const locationTypes = {
    state: "State",
    city: "City",
    country: "Country",
    zip: "Zip",
    latitude: "Latitude",
    longitude: "Longitude",
    radius: "Radius",
    // ...
  }
  const locations = [
    {type: locationTypes.state, value: 'North Dakota'},
    {type: locationTypes.state, value: 'South Dakota'},
    {type: locationTypes.state, value: 'Minnesota'},
    {type: locationTypes.state, value: 'Missouri'},
  ]
  let locationString = ``
  if (useLocation) {
    if (locations.length > 0) {
      const locationParamStart = `SEARCH[Location](`
      const locationParamEnd = `)`
      let locationParamMiddle = ``
      locations.forEach((location, i) => {
        if (i === 0) {
          locationParamMiddle += `AREA[LocationState](${location.value})` // FUTURE: consider generalizing to LocationX (LocationState, LocationCity, etc.) (currently, states are close enough... want enough of results, can use city long/lat later for sorting by nearest)
        } else {
          locationParamMiddle += `+OR+AREA[LocationState](${location.value})`
        }
      })
      locationString = `${locationParamStart}${locationParamMiddle}${locationParamEnd}`   // needs +AND+ before it
    }
  }


  // url = `${urlBase}${api}?expr=${subject}+AND+${locationString}&fields=${fieldsString}${minMax}${resultsFormat}`; // study_fields
  url = `${urlBase}${api}?expr=${subject}+AND+${locationString}${minMax}${resultsFormat}`; // full_studies



  // locationString = `+AND+${location}`
  
  // const location = `SEARCH[Location](AREA[LocationState](North+Dakota)+OR+AREA[LocationState](South+Dakota)+OR+AREA[LocationState](Minnesota)+OR+AREA[LocationState](Missouri))`; // ND, SD, MN, MO
  // NOT WORKING
  // const location = `AREA[Location](STATE(North+Dakota)+OR+STATE(South+Dakota)+OR+STATE(Minnesota)+OR+STATE(Missouri))`; // ND, SD, MN, MO
  // const location = `LocationTerm=(North+Dakota+OR+South+Dakota+OR+Minnesota+OR+Missouri)`; // ND, SD, MN, MO
  // const location = `LocationTerm=Boston%2C%20MA` // within Boston, MA (works)
  // const location = `AREA[Location](RADIUS(50.0,42.3601,-71.0589))` // within 50mi of Boston
  // const location = `AREA%5BLocation%5D%28RADIUS%2850.0%2C42.3601%2C-71.0589%29%29`; // within 50mi of Boston
  const age = `AGE+BETWEEN+18+AND+65` // between 18 and 65 (works)
  // NOT WORKING
  // const age = `AGE%20BETWEEN%2018%20AND%2065` // between 18 and 65




  // AND PARAMETERS
  // const ANDparameters = [location, age]
  const ANDparameters = []
  // const ANDparameters = [age]
  if (useANDparameters && ANDparameters.length > 0) {
    const ANDparametersString = ANDparameters.join('+AND+');
    url = `${urlBase}${api}?expr=${subject}+AND+${ANDparametersString}&fields=${fieldsString}${minMax}${resultsFormat}`;
  }

  // OR PARAMETERS
  const ORparameters = []
  if (useORparameters && ORparameters.length > 0) {
    // NOT CONFIRMED (COPILOT... check with ChatGPT)
    // url = `${urlBase}${api}?expr=${subject}+AND+${ORparameters.join('+OR+')}${minMax}${resultsFormat}`
  }
  




  // const url = `${urlBase}${api}?expr=${subject}+AND+${location}+AND+${age}${minMax}${resultsFormat}`
  // const url = `${urlBase}${api}?expr=glaucoma+AND+AREA[Location](RADIUS(50.0,42.3601,-71.0589))+AND+AGE%20BETWEEN%2018%20AND%2065${minMax}${format}`
  // const url = `https://clinicaltrials.gov/api/query/list?expr=glaucoma+AND+AREA[Location](RADIUS(50.0,42.3601,-71.0589))+AND+AGE%20BETWEEN%2018%20AND%2065&min_rnk=1&max_rnk=100&fmt=json  `
  
  // const { docs, isFetching } = useFetchClinicDocs(urlWithoutParameters, {})
  const { fetchDocs, docs, isFetching } = useFetchClinicDocs(url, {})
  


  const columns = useMemo(
    () => fields.map((field) => (
        {
          accessorKey: field,
          header: field,
        }
      )
    ) , []
  )



  return (
    <div>
      <MaterialReactTable
        data={docs}
        columns={columns}
        options={{
          pagination: true,
          search: true,
          density: "compact",
        }}
      />
    </div>
  );
};





// FIELDS
const fieldOptions = [
  "Acronym",
  "AgreementOtherDetails",
  "AgreementPISponsorEmployee",
  "AgreementRestrictionType",
  "AgreementRestrictiveAgreement",
  "ArmGroupDescription",
  "ArmGroupInterventionName",
  "ArmGroupLabel",
  "ArmGroupType",
  "AvailIPDComment",
  "AvailIPDId",
  "AvailIPDType",
  "AvailIPDURL",
  "BaselineCategoryTitle",
  "BaselineClassDenomCountGroupId",
  "BaselineClassDenomCountValue",
  "BaselineClassDenomUnits",
  "BaselineClassTitle",
  "BaselineDenomCountGroupId",
  "BaselineDenomCountValue",
  "BaselineDenomUnits",
  "BaselineGroupDescription",
  "BaselineGroupId",
  "BaselineGroupTitle",
  "BaselineMeasureCalculatePct",
  "BaselineMeasureDenomCountGroupId",
  "BaselineMeasureDenomCountValue",
  "BaselineMeasureDenomUnits",
  "BaselineMeasureDenomUnitsSelected",
  "BaselineMeasureDescription",
  "BaselineMeasureDispersionType",
  "BaselineMeasureParamType",
  "BaselineMeasurePopulationDescription",
  "BaselineMeasureTitle",
  "BaselineMeasureUnitOfMeasure",
  "BaselineMeasurementComment",
  "BaselineMeasurementGroupId",
  "BaselineMeasurementLowerLimit",
  "BaselineMeasurementSpread",
  "BaselineMeasurementUpperLimit",
  "BaselineMeasurementValue",
  "BaselinePopulationDescription",
  "BaselineTypeUnitsAnalyzed",
  "BioSpecDescription",
  "BioSpecRetention",
  "BriefSummary",
  "BriefTitle",
  "CentralContactEMail",
  "CentralContactName",
  "CentralContactPhone",
  "CentralContactPhoneExt",
  "CentralContactRole",
  "CollaboratorClass",
  "CollaboratorName",
  "CompletionDate",
  "CompletionDateType",
  "Condition",
  "ConditionAncestorId",
  "ConditionAncestorTerm",
  "ConditionBrowseBranchAbbrev",
  "ConditionBrowseBranchName",
  "ConditionBrowseLeafAsFound",
  "ConditionBrowseLeafId",
  "ConditionBrowseLeafName",
  "ConditionBrowseLeafRelevance",
  "ConditionMeshId",
  "ConditionMeshTerm",
  "DelayedPosting",
  "DesignAllocation",
  "DesignInterventionModel",
  "DesignInterventionModelDescription",
  "DesignMasking",
  "DesignMaskingDescription",
  "DesignObservationalModel",
  "DesignPrimaryPurpose",
  "DesignTimePerspective",
  "DesignWhoMasked",
  "DetailedDescription",
  "DispFirstPostDate",
  "DispFirstPostDateType",
  "DispFirstSubmitDate",
  "DispFirstSubmitQCDate",
  "EligibilityCriteria",
  "EnrollmentCount",
  "EnrollmentType",
  "EventGroupDeathsNumAffected",
  "EventGroupDeathsNumAtRisk",
  "EventGroupDescription",
  "EventGroupId",
  "EventGroupOtherNumAffected",
  "EventGroupOtherNumAtRisk",
  "EventGroupSeriousNumAffected",
  "EventGroupSeriousNumAtRisk",
  "EventGroupTitle",
  "EventsDescription",
  "EventsFrequencyThreshold",
  "EventsTimeFrame",
  "ExpAccTypeIndividual",
  "ExpAccTypeIntermediate",
  "ExpAccTypeTreatment",
  "ExpandedAccessNCTId",
  "ExpandedAccessStatusForNCTId",
  "FDAAA801Violation",
  "FlowAchievementComment",
  "FlowAchievementGroupId",
  "FlowAchievementNumSubjects",
  "FlowAchievementNumUnits",
  "FlowDropWithdrawComment",
  "FlowDropWithdrawType",
  "FlowGroupDescription",
  "FlowGroupId",
  "FlowGroupTitle",
  "FlowMilestoneComment",
  "FlowMilestoneType",
  "FlowPeriodTitle",
  "FlowPreAssignmentDetails",
  "FlowReasonComment",
  "FlowReasonGroupId",
  "FlowReasonNumSubjects",
  "FlowReasonNumUnits",
  "FlowRecruitmentDetails",
  "FlowTypeUnitsAnalyzed",
  "Gender",
  "GenderBased",
  "GenderDescription",
  "HasExpandedAccess",
  "HealthyVolunteers",
  "IPDSharing",
  "IPDSharingAccessCriteria",
  "IPDSharingDescription",
  "IPDSharingInfoType",
  "IPDSharingTimeFrame",
  "IPDSharingURL",
  "InterventionAncestorId",
  "InterventionAncestorTerm",
  "InterventionArmGroupLabel",
  "InterventionBrowseBranchAbbrev",
  "InterventionBrowseBranchName",
  "InterventionBrowseLeafAsFound",
  "InterventionBrowseLeafId",
  "InterventionBrowseLeafName",
  "InterventionBrowseLeafRelevance",
  "InterventionDescription",
  "InterventionMeshId",
  "InterventionMeshTerm",
  "InterventionName",
  "InterventionOtherName",
  "InterventionType",
  "IsFDARegulatedDevice",
  "IsFDARegulatedDrug",
  "IsPPSD",
  "IsUSExport",
  "IsUnapprovedDevice",
  "Keyword",
  "LargeDocDate",
  "LargeDocFilename",
  "LargeDocHasICF",
  "LargeDocHasProtocol",
  "LargeDocHasSAP",
  "LargeDocLabel",
  "LargeDocTypeAbbrev",
  "LargeDocUploadDate",
  "LastKnownStatus",
  "LastUpdatePostDate",
  "LastUpdatePostDateType",
  "LastUpdateSubmitDate",
  "LeadSponsorClass",
  "LeadSponsorName",
  "LimitationsAndCaveatsDescription",
  "LocationCity",
  "LocationContactEMail",
  "LocationContactName",
  "LocationContactPhone",
  "LocationContactPhoneExt",
  "LocationContactRole",
  "LocationCountry",
  "LocationFacility",
  "LocationState",
  "LocationStatus",
  "LocationZip",
  "MaximumAge",
  "MinimumAge",
  "NCTId",
  "NCTIdAlias",
  "OfficialTitle",
  "OrgClass",
  "OrgFullName",
  "OrgStudyId",
  "OrgStudyIdDomain",
  "OrgStudyIdLink",
  "OrgStudyIdType",
  "OtherEventAssessmentType",
  "OtherEventNotes",
  "OtherEventOrganSystem",
  "OtherEventSourceVocabulary",
  "OtherEventStatsGroupId",
  "OtherEventStatsNumAffected",
  "OtherEventStatsNumAtRisk",
  "OtherEventStatsNumEvents",
  "OtherEventTerm",
  "OtherOutcomeDescription",
  "OtherOutcomeMeasure",
  "OtherOutcomeTimeFrame",
  "OutcomeAnalysisCILowerLimit",
  "OutcomeAnalysisCILowerLimitComment",
  "OutcomeAnalysisCINumSides",
  "OutcomeAnalysisCIPctValue",
  "OutcomeAnalysisCIUpperLimit",
  "OutcomeAnalysisCIUpperLimitComment",
  "OutcomeAnalysisDispersionType",
  "OutcomeAnalysisDispersionValue",
  "OutcomeAnalysisEstimateComment",
  "OutcomeAnalysisGroupDescription",
  "OutcomeAnalysisGroupId",
  "OutcomeAnalysisNonInferiorityComment",
  "OutcomeAnalysisNonInferiorityType",
  "OutcomeAnalysisOtherAnalysisDescription",
  "OutcomeAnalysisPValue",
  "OutcomeAnalysisPValueComment",
  "OutcomeAnalysisParamType",
  "OutcomeAnalysisParamValue",
  "OutcomeAnalysisStatisticalComment",
  "OutcomeAnalysisStatisticalMethod",
  "OutcomeAnalysisTestedNonInferiority",
  "OutcomeCategoryTitle",
  "OutcomeClassDenomCountGroupId",
  "OutcomeClassDenomCountValue",
  "OutcomeClassDenomUnits",
  "OutcomeClassTitle",
  "OutcomeDenomCountGroupId",
  "OutcomeDenomCountValue",
  "OutcomeDenomUnits",
  "OutcomeGroupDescription",
  "OutcomeGroupId",
  "OutcomeGroupTitle",
  "OutcomeMeasureAnticipatedPostingDate",
  "OutcomeMeasureCalculatePct",
  "OutcomeMeasureDenomUnitsSelected",
  "OutcomeMeasureDescription",
  "OutcomeMeasureDispersionType",
  "OutcomeMeasureParamType",
  "OutcomeMeasurePopulationDescription",
  "OutcomeMeasureReportingStatus",
  "OutcomeMeasureTimeFrame",
  "OutcomeMeasureTitle",
  "OutcomeMeasureType",
  "OutcomeMeasureTypeUnitsAnalyzed",
  "OutcomeMeasureUnitOfMeasure",
  "OutcomeMeasurementComment",
  "OutcomeMeasurementGroupId",
  "OutcomeMeasurementLowerLimit",
  "OutcomeMeasurementSpread",
  "OutcomeMeasurementUpperLimit",
  "OutcomeMeasurementValue",
  "OverallOfficialAffiliation",
  "OverallOfficialName",
  "OverallOfficialRole",
  "OverallStatus",
  "OversightHasDMC",
  "PatientRegistry",
  "Phase",
  "PointOfContactEMail",
  "PointOfContactOrganization",
  "PointOfContactPhone",
  "PointOfContactPhoneExt",
  "PointOfContactTitle",
  "PrimaryCompletionDate",
  "PrimaryCompletionDateType",
  "PrimaryOutcomeDescription",
  "PrimaryOutcomeMeasure",
  "PrimaryOutcomeTimeFrame",
  "ReferenceCitation",
  "ReferencePMID",
  "ReferenceType",
  "RemovedCountry",
  "ResponsiblePartyInvestigatorAffiliation",
  "ResponsiblePartyInvestigatorFullName",
  "ResponsiblePartyInvestigatorTitle",
  "ResponsiblePartyOldNameTitle",
  "ResponsiblePartyOldOrganization",
  "ResponsiblePartyType",
  "ResultsFirstPostDate",
  "ResultsFirstPostDateType",
  "ResultsFirstPostedQCCommentsDate",
  "ResultsFirstPostedQCCommentsDateType",
  "ResultsFirstSubmitDate",
  "ResultsFirstSubmitQCDate",
  "RetractionPMID",
  "RetractionSource",
  "SamplingMethod",
  "SecondaryId",
  "SecondaryIdDomain",
  "SecondaryIdLink",
  "SecondaryIdType",
  "SecondaryOutcomeDescription",
  "SecondaryOutcomeMeasure",
  "SecondaryOutcomeTimeFrame",
  "SeeAlsoLinkLabel",
  "SeeAlsoLinkURL",
  "SeriousEventAssessmentType",
  "SeriousEventNotes",
  "SeriousEventOrganSystem",
  "SeriousEventSourceVocabulary",
  "SeriousEventStatsGroupId",
  "SeriousEventStatsNumAffected",
  "SeriousEventStatsNumAtRisk",
  "SeriousEventStatsNumEvents",
  "SeriousEventTerm",
  "StartDate",
  "StartDateType",
  "StatusVerifiedDate",
  "StdAge",
  "StudyFirstPostDate",
  "StudyFirstPostDateType",
  "StudyFirstSubmitDate",
  "StudyFirstSubmitQCDate",
  "StudyPopulation",
  "StudyType",
  "SubmissionMCPReleaseN",
  "SubmissionReleaseDate",
  "SubmissionResetDate",
  "SubmissionUnreleaseDate",
  "TargetDuration",
  "UnpostedEventDate",
  "UnpostedEventType",
  "UnpostedResponsibleParty",
  "VersionHolder",
  "WhyStopped",
]




const fullStudiesObj = {
  "Rank": 1,
  "Study": {
      "ProtocolSection": {
          "IdentificationModule": {
              "NCTId": "NCT04670575",
              "OrgStudyIdInfo": {
                  "OrgStudyId": "VIVA"
              },
              "Organization": {
                  "OrgFullName": "Vance Thompson Vision",
                  "OrgClass": "OTHER"
              },
              "BriefTitle": "Vivity Outcomes in Patients With Early Stage Glaucoma",
              "OfficialTitle": "Vivity Outcomes in Patients With Early Stage Glaucoma",
              "Acronym": "VIVA"
          },
          "StatusModule": {
              "StatusVerifiedDate": "December 2020",
              "OverallStatus": "Unknown status",
              "LastKnownStatus": "Not yet recruiting",
              "ExpandedAccessInfo": {
                  "HasExpandedAccess": "No"
              },
              "StartDateStruct": {
                  "StartDate": "December 2020",
                  "StartDateType": "Anticipated"
              },
              "PrimaryCompletionDateStruct": {
                  "PrimaryCompletionDate": "June 2021",
                  "PrimaryCompletionDateType": "Anticipated"
              },
              "CompletionDateStruct": {
                  "CompletionDate": "July 2021",
                  "CompletionDateType": "Anticipated"
              },
              "StudyFirstSubmitDate": "December 13, 2020",
              "StudyFirstSubmitQCDate": "December 13, 2020",
              "StudyFirstPostDateStruct": {
                  "StudyFirstPostDate": "December 17, 2020",
                  "StudyFirstPostDateType": "Actual"
              },
              "LastUpdateSubmitDate": "December 13, 2020",
              "LastUpdatePostDateStruct": {
                  "LastUpdatePostDate": "December 17, 2020",
                  "LastUpdatePostDateType": "Actual"
              }
          },
          "SponsorCollaboratorsModule": {
              "ResponsibleParty": {
                  "ResponsiblePartyType": "Sponsor"
              },
              "LeadSponsor": {
                  "LeadSponsorName": "Vance Thompson Vision",
                  "LeadSponsorClass": "OTHER"
              }
          },
          "OversightModule": {
              "OversightHasDMC": "No",
              "IsFDARegulatedDrug": "No",
              "IsFDARegulatedDevice": "No",
              "IsUSExport": "No"
          },
          "DescriptionModule": {
              "BriefSummary": "The goal of the study is to determine the visual outcomes in patients with mild, pre-perimetric glaucoma who undergo cataract surgery with implantation of the Alcon Vivity Extended Range Intraocular Lens (IOL)."
          },
          "ConditionsModule": {
              "ConditionList": {
                  "Condition": [
                      "Glaucoma",
                      "Cataract",
                      "Presbyopia"
                  ]
              },
              "KeywordList": {
                  "Keyword": [
                      "Extended Range of Vision, Intraocular Lens Technology, Glaucoma"
                  ]
              }
          },
          "DesignModule": {
              "StudyType": "Observational",
              "PatientRegistry": "No",
              "DesignInfo": {
                  "DesignObservationalModelList": {
                      "DesignObservationalModel": [
                          "Cohort"
                      ]
                  },
                  "DesignTimePerspectiveList": {
                      "DesignTimePerspective": [
                          "Prospective"
                      ]
                  }
              },
              "EnrollmentInfo": {
                  "EnrollmentCount": "25",
                  "EnrollmentType": "Anticipated"
              }
          },
          "ArmsInterventionsModule": {
              "ArmGroupList": {
                  "ArmGroup": [
                      {
                          "ArmGroupLabel": "Vivity",
                          "ArmGroupDescription": "Patients implanted with Vivity or Vivity Toric intraocular lens at the time of cataract surgery.",
                          "ArmGroupInterventionList": {
                              "ArmGroupInterventionName": [
                                  "Procedure: Cataract Surgery with Implantation of Vivity Extended Range of Vision IOL"
                              ]
                          }
                      }
                  ]
              },
              "InterventionList": {
                  "Intervention": [
                      {
                          "InterventionType": "Procedure",
                          "InterventionName": "Cataract Surgery with Implantation of Vivity Extended Range of Vision IOL",
                          "InterventionDescription": "Patients with early stage glaucoma implanted with Vivity or Vivity Toric IOL",
                          "InterventionArmGroupLabelList": {
                              "InterventionArmGroupLabel": [
                                  "Vivity"
                              ]
                          }
                      }
                  ]
              }
          },
          "OutcomesModule": {
              "PrimaryOutcomeList": {
                  "PrimaryOutcome": [
                      {
                          "PrimaryOutcomeMeasure": "Uncorrected Distance Visual Acuity",
                          "PrimaryOutcomeTimeFrame": "4-6 months"
                      },
                      {
                          "PrimaryOutcomeMeasure": "Uncorrected Intermediate Visual Acuity",
                          "PrimaryOutcomeTimeFrame": "4-6 months"
                      },
                      {
                          "PrimaryOutcomeMeasure": "Uncorrected Near Visual Acuity",
                          "PrimaryOutcomeTimeFrame": "4-6 months"
                      }
                  ]
              },
              "SecondaryOutcomeList": {
                  "SecondaryOutcome": [
                      {
                          "SecondaryOutcomeMeasure": "Contrast Sensitivity",
                          "SecondaryOutcomeDescription": "As measured by Pelli Robson Chart",
                          "SecondaryOutcomeTimeFrame": "4-6 months"
                      },
                      {
                          "SecondaryOutcomeMeasure": "Patient Satisfaction and Spectacle Independence Survey Results",
                          "SecondaryOutcomeTimeFrame": "4-6 months"
                      },
                      {
                          "SecondaryOutcomeMeasure": "Mean Refractive Spherical Equivalent",
                          "SecondaryOutcomeTimeFrame": "4-6 months"
                      }
                  ]
              }
          },
          "EligibilityModule": {
              "EligibilityCriteria": "Inclusion Criteria:\n\nPatients at least age 45 years of age undergoing uncomplicated cataract surgery with optional concomitant minimally invasive glaucoma surgery (MIGS)\nSubjects with documented diagnosis of pre-perimetric glaucoma\nCalculated lens power within Vivity/Vivity toric range\nWilling and able to comprehend informed consent and complete 4-6 month post-op visit\nPotential postoperative BCDVA of 20/20 or better in each eye based on Investigator's medical opinion\n\nExclusion Criteria:\n\nOcular comorbidity, other than pre-perimetric glaucoma, that could reduce the potential postoperative BCDVA\nPrevious ocular surgery including refractive surgery\nSubjects who are pregnant or plan to become pregnant during the course of the study.",
              "Gender": "All",
              "MinimumAge": "45 Years",
              "StdAgeList": {
                  "StdAge": [
                      "Adult",
                      "Older Adult"
                  ]
              },
              "StudyPopulation": "Patients with early stage glaucoma with cataracts in both eyes who are planned to have bilateral cataract surgery with implantation of Vivity or Vivity Toric intraocular lens.",
              "SamplingMethod": "Non-Probability Sample"
          },
          "ContactsLocationsModule": {
              "CentralContactList": {
                  "CentralContact": [
                      {
                          "CentralContactName": "Keeley Puls",
                          "CentralContactRole": "Contact",
                          "CentralContactPhone": "402 740 9420",
                          "CentralContactEMail": "Keeley.puls@vancethompsonvision.com"
                      }
                  ]
              },
              "OverallOfficialList": {
                  "OverallOfficial": [
                      {
                          "OverallOfficialName": "Brian M Shafer, MD",
                          "OverallOfficialAffiliation": "Vance Thompson Vision",
                          "OverallOfficialRole": "Principal Investigator"
                      }
                  ]
              },
              "LocationList": {
                  "Location": [
                      {
                          "LocationFacility": "Vance Thompson Vision",
                          "LocationCity": "Sioux Falls",
                          "LocationState": "South Dakota",
                          "LocationZip": "57108",
                          "LocationCountry": "United States"
                      }
                  ]
              }
          },
          "IPDSharingStatementModule": {
              "IPDSharing": "No"
          }
      },
      "DerivedSection": {
          "MiscInfoModule": {
              "VersionHolder": "April 10, 2023"
          },
          "ConditionBrowseModule": {
              "ConditionMeshList": {
                  "ConditionMesh": [
                      {
                          "ConditionMeshId": "D000005901",
                          "ConditionMeshTerm": "Glaucoma"
                      },
                      {
                          "ConditionMeshId": "D000002386",
                          "ConditionMeshTerm": "Cataract"
                      },
                      {
                          "ConditionMeshId": "D000011305",
                          "ConditionMeshTerm": "Presbyopia"
                      }
                  ]
              },
              "ConditionAncestorList": {
                  "ConditionAncestor": [
                      {
                          "ConditionAncestorId": "D000009798",
                          "ConditionAncestorTerm": "Ocular Hypertension"
                      },
                      {
                          "ConditionAncestorId": "D000005128",
                          "ConditionAncestorTerm": "Eye Diseases"
                      },
                      {
                          "ConditionAncestorId": "D000007905",
                          "ConditionAncestorTerm": "Lens Diseases"
                      },
                      {
                          "ConditionAncestorId": "D000012030",
                          "ConditionAncestorTerm": "Refractive Errors"
                      }
                  ]
              },
              "ConditionBrowseLeafList": {
                  "ConditionBrowseLeaf": [
                      {
                          "ConditionBrowseLeafId": "M4790",
                          "ConditionBrowseLeafName": "Cataract",
                          "ConditionBrowseLeafAsFound": "Cataract",
                          "ConditionBrowseLeafRelevance": "high"
                      },
                      {
                          "ConditionBrowseLeafId": "M8165",
                          "ConditionBrowseLeafName": "Glaucoma",
                          "ConditionBrowseLeafAsFound": "Glaucoma",
                          "ConditionBrowseLeafRelevance": "high"
                      },
                      {
                          "ConditionBrowseLeafId": "M13331",
                          "ConditionBrowseLeafName": "Presbyopia",
                          "ConditionBrowseLeafAsFound": "Presbyopia",
                          "ConditionBrowseLeafRelevance": "high"
                      },
                      {
                          "ConditionBrowseLeafId": "M9176",
                          "ConditionBrowseLeafName": "Hypertension",
                          "ConditionBrowseLeafRelevance": "low"
                      },
                      {
                          "ConditionBrowseLeafId": "M11883",
                          "ConditionBrowseLeafName": "Ocular Hypertension",
                          "ConditionBrowseLeafRelevance": "low"
                      },
                      {
                          "ConditionBrowseLeafId": "M7423",
                          "ConditionBrowseLeafName": "Eye Diseases",
                          "ConditionBrowseLeafRelevance": "low"
                      },
                      {
                          "ConditionBrowseLeafId": "M10069",
                          "ConditionBrowseLeafName": "Lens Diseases",
                          "ConditionBrowseLeafRelevance": "low"
                      },
                      {
                          "ConditionBrowseLeafId": "M14024",
                          "ConditionBrowseLeafName": "Refractive Errors",
                          "ConditionBrowseLeafRelevance": "low"
                      }
                  ]
              },
              "ConditionBrowseBranchList": {
                  "ConditionBrowseBranch": [
                      {
                          "ConditionBrowseBranchAbbrev": "BC11",
                          "ConditionBrowseBranchName": "Eye Diseases"
                      },
                      {
                          "ConditionBrowseBranchAbbrev": "All",
                          "ConditionBrowseBranchName": "All Conditions"
                      },
                      {
                          "ConditionBrowseBranchAbbrev": "BC14",
                          "ConditionBrowseBranchName": "Heart and Blood Diseases"
                      }
                  ]
              }
          }
      }
  }
}


const studyStructre = {
  "StudyStructure": {
      "APIVrs": "1.01.05",
      "ElmtDefs": {
        "Study": {
            "type": "Object",
            "ProtocolSection": {
              "type": "Object",
              "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html",
              "IdentificationModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html#identification",
                  "NCTId": {
                    "type": "String"
                  },
                  "NCTIdAliasList": {
                    "type": "Array",
                    "NCTIdAlias": {
                        "type": "String"
                    }
                  },
                  "OrgStudyIdInfo": {
                    "type": "Object",
                    "OrgStudyId": {
                        "type": "String"
                    },
                    "OrgStudyIdType": {
                        "type": "String"
                    },
                    "OrgStudyIdDomain": {
                        "type": "String"
                    },
                    "OrgStudyIdLink": {
                        "type": "String"
                    }
                  },
                  "SecondaryIdInfoList": {
                    "type": "Array",
                    "SecondaryIdInfo": {
                        "type": "Object",
                        "SecondaryId": {
                          "type": "String"
                        },
                        "SecondaryIdType": {
                          "type": "String"
                        },
                        "SecondaryIdDomain": {
                          "type": "String"
                        },
                        "SecondaryIdLink": {
                          "type": "String"
                        }
                    }
                  },
                  "Organization": {
                    "type": "Object",
                    "OrgFullName": {
                        "type": "String"
                    },
                    "OrgClass": {
                        "type": "String"
                    }
                  },
                  "BriefTitle": {
                    "type": "String"
                  },
                  "OfficialTitle": {
                    "type": "String"
                  },
                  "Acronym": {
                    "type": "String"
                  }
              },
              "StatusModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html#status",
                  "StatusVerifiedDate": {
                    "type": "String"
                  },
                  "OverallStatus": {
                    "type": "String"
                  },
                  "LastKnownStatus": {
                    "type": "String"
                  },
                  "DelayedPosting": {
                    "type": "String"
                  },
                  "WhyStopped": {
                    "type": "String"
                  },
                  "ExpandedAccessInfo": {
                    "type": "Object",
                    "HasExpandedAccess": {
                        "type": "String"
                    },
                    "ExpandedAccessNCTId": {
                        "type": "String"
                    },
                    "ExpandedAccessStatusForNCTId": {
                        "type": "String"
                    }
                  },
                  "StartDateStruct": {
                    "type": "Object",
                    "StartDate": {
                        "type": "String"
                    },
                    "StartDateType": {
                        "type": "String"
                    }
                  },
                  "PrimaryCompletionDateStruct": {
                    "type": "Object",
                    "PrimaryCompletionDate": {
                        "type": "String"
                    },
                    "PrimaryCompletionDateType": {
                        "type": "String"
                    }
                  },
                  "CompletionDateStruct": {
                    "type": "Object",
                    "CompletionDate": {
                        "type": "String"
                    },
                    "CompletionDateType": {
                        "type": "String"
                    }
                  },
                  "StudyFirstSubmitDate": {
                    "type": "String"
                  },
                  "StudyFirstSubmitQCDate": {
                    "type": "String"
                  },
                  "StudyFirstPostDateStruct": {
                    "type": "Object",
                    "StudyFirstPostDate": {
                        "type": "String"
                    },
                    "StudyFirstPostDateType": {
                        "type": "String"
                    }
                  },
                  "ResultsFirstSubmitDate": {
                    "type": "String"
                  },
                  "ResultsFirstSubmitQCDate": {
                    "type": "String"
                  },
                  "ResultsFirstPostDateStruct": {
                    "type": "Object",
                    "ResultsFirstPostDate": {
                        "type": "String"
                    },
                    "ResultsFirstPostDateType": {
                        "type": "String"
                    }
                  },
                  "ResultsFirstPostedQCCommentsDateStruct": {
                    "type": "Object",
                    "ResultsFirstPostedQCCommentsDate": {
                        "type": "String"
                    },
                    "ResultsFirstPostedQCCommentsDateType": {
                        "type": "String"
                    }
                  },
                  "DispFirstSubmitDate": {
                    "type": "String"
                  },
                  "DispFirstSubmitQCDate": {
                    "type": "String"
                  },
                  "DispFirstPostDateStruct": {
                    "type": "Object",
                    "DispFirstPostDate": {
                        "type": "String"
                    },
                    "DispFirstPostDateType": {
                        "type": "String"
                    }
                  },
                  "LastUpdateSubmitDate": {
                    "type": "String"
                  },
                  "LastUpdatePostDateStruct": {
                    "type": "Object",
                    "LastUpdatePostDate": {
                        "type": "String"
                    },
                    "LastUpdatePostDateType": {
                        "type": "String"
                    }
                  }
              },
              "SponsorCollaboratorsModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html#sponsors",
                  "ResponsibleParty": {
                    "type": "Object",
                    "ResponsiblePartyType": {
                        "type": "String"
                    },
                    "ResponsiblePartyInvestigatorFullName": {
                        "type": "String"
                    },
                    "ResponsiblePartyInvestigatorTitle": {
                        "type": "String"
                    },
                    "ResponsiblePartyInvestigatorAffiliation": {
                        "type": "String"
                    },
                    "ResponsiblePartyOldNameTitle": {
                        "type": "String"
                    },
                    "ResponsiblePartyOldOrganization": {
                        "type": "String"
                    }
                  },
                  "LeadSponsor": {
                    "type": "Object",
                    "LeadSponsorName": {
                        "type": "String"
                    },
                    "LeadSponsorClass": {
                        "type": "String"
                    }
                  },
                  "CollaboratorList": {
                    "type": "Array",
                    "Collaborator": {
                        "type": "Object",
                        "CollaboratorName": {
                          "type": "String"
                        },
                        "CollaboratorClass": {
                          "type": "String"
                        }
                    }
                  }
              },
              "OversightModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html#oversight",
                  "OversightHasDMC": {
                    "type": "String"
                  },
                  "IsFDARegulatedDrug": {
                    "type": "String"
                  },
                  "IsFDARegulatedDevice": {
                    "type": "String"
                  },
                  "IsUnapprovedDevice": {
                    "type": "String"
                  },
                  "IsPPSD": {
                    "type": "String"
                  },
                  "IsUSExport": {
                    "type": "String"
                  },
                  "FDAAA801Violation": {
                    "type": "String"
                  }
              },
              "DescriptionModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html#description",
                  "BriefSummary": {
                    "type": "String"
                  },
                  "DetailedDescription": {
                    "type": "String"
                  }
              },
              "ConditionsModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html#Conditions",
                  "ConditionList": {
                    "type": "Array",
                    "Condition": {
                        "type": "String"
                    }
                  },
                  "KeywordList": {
                    "type": "Array",
                    "Keyword": {
                        "type": "String"
                    }
                  }
              },
              "DesignModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html#StudyDesign",
                  "StudyType": {
                    "type": "String",
                    "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html#StudyType"
                  },
                  "ExpandedAccessTypes": {
                    "type": "Object",
                    "ExpAccTypeIndividual": {
                        "type": "String"
                    },
                    "ExpAccTypeIntermediate": {
                        "type": "String"
                    },
                    "ExpAccTypeTreatment": {
                        "type": "String"
                    }
                  },
                  "PatientRegistry": {
                    "type": "String"
                  },
                  "TargetDuration": {
                    "type": "String"
                  },
                  "PhaseList": {
                    "type": "Array",
                    "Phase": {
                        "type": "String"
                    }
                  },
                  "DesignInfo": {
                    "type": "Object",
                    "DesignAllocation": {
                        "type": "String"
                    },
                    "DesignInterventionModel": {
                        "type": "String"
                    },
                    "DesignInterventionModelDescription": {
                        "type": "String"
                    },
                    "DesignPrimaryPurpose": {
                        "type": "String"
                    },
                    "DesignObservationalModelList": {
                        "type": "Array",
                        "DesignObservationalModel": {
                          "type": "String"
                        }
                    },
                    "DesignTimePerspectiveList": {
                        "type": "Array",
                        "DesignTimePerspective": {
                          "type": "String"
                        }
                    },
                    "DesignMaskingInfo": {
                        "type": "Object",
                        "DesignMasking": {
                          "type": "String"
                        },
                        "DesignMaskingDescription": {
                          "type": "String"
                        },
                        "DesignWhoMaskedList": {
                          "type": "Array",
                          "DesignWhoMasked": {
                              "type": "String"
                          }
                        }
                    }
                  },
                  "BioSpec": {
                    "type": "Object",
                    "BioSpecRetention": {
                        "type": "String"
                    },
                    "BioSpecDescription": {
                        "type": "String"
                    }
                  },
                  "EnrollmentInfo": {
                    "type": "Object",
                    "EnrollmentCount": {
                        "type": "String"
                    },
                    "EnrollmentType": {
                        "type": "String"
                    }
                  }
              },
              "ArmsInterventionsModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html#ArmsGroupsInterventions",
                  "ArmGroupList": {
                    "type": "Array",
                    "ArmGroup": {
                        "type": "Object",
                        "ArmGroupLabel": {
                          "type": "String"
                        },
                        "ArmGroupType": {
                          "type": "String"
                        },
                        "ArmGroupDescription": {
                          "type": "String"
                        },
                        "ArmGroupInterventionList": {
                          "type": "Array",
                          "ArmGroupInterventionName": {
                              "type": "String"
                          }
                        }
                    }
                  },
                  "InterventionList": {
                    "type": "Array",
                    "Intervention": {
                        "type": "Object",
                        "InterventionType": {
                          "type": "String"
                        },
                        "InterventionName": {
                          "type": "String"
                        },
                        "InterventionDescription": {
                          "type": "String"
                        },
                        "InterventionArmGroupLabelList": {
                          "type": "Array",
                          "InterventionArmGroupLabel": {
                              "type": "String"
                          }
                        },
                        "InterventionOtherNameList": {
                          "type": "Array",
                          "InterventionOtherName": {
                              "type": "String"
                          }
                        }
                    }
                  }
              },
              "OutcomesModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html#Outcomes",
                  "PrimaryOutcomeList": {
                    "type": "Array",
                    "PrimaryOutcome": {
                        "type": "Object",
                        "PrimaryOutcomeMeasure": {
                          "type": "String"
                        },
                        "PrimaryOutcomeDescription": {
                          "type": "String"
                        },
                        "PrimaryOutcomeTimeFrame": {
                          "type": "String"
                        }
                    }
                  },
                  "SecondaryOutcomeList": {
                    "type": "Array",
                    "SecondaryOutcome": {
                        "type": "Object",
                        "SecondaryOutcomeMeasure": {
                          "type": "String"
                        },
                        "SecondaryOutcomeDescription": {
                          "type": "String"
                        },
                        "SecondaryOutcomeTimeFrame": {
                          "type": "String"
                        }
                    }
                  },
                  "OtherOutcomeList": {
                    "type": "Array",
                    "OtherOutcome": {
                        "type": "Object",
                        "OtherOutcomeMeasure": {
                          "type": "String"
                        },
                        "OtherOutcomeDescription": {
                          "type": "String"
                        },
                        "OtherOutcomeTimeFrame": {
                          "type": "String"
                        }
                    }
                  }
              },
              "EligibilityModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html#Eligibility",
                  "EligibilityCriteria": {
                    "type": "String"
                  },
                  "HealthyVolunteers": {
                    "type": "String"
                  },
                  "Gender": {
                    "type": "String"
                  },
                  "GenderBased": {
                    "type": "String"
                  },
                  "GenderDescription": {
                    "type": "String"
                  },
                  "MinimumAge": {
                    "type": "String"
                  },
                  "MaximumAge": {
                    "type": "String"
                  },
                  "StdAgeList": {
                    "type": "Array",
                    "StdAge": {
                        "type": "String"
                    }
                  },
                  "StudyPopulation": {
                    "type": "String"
                  },
                  "SamplingMethod": {
                    "type": "String"
                  }
              },
              "ContactsLocationsModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html#Locations",
                  "CentralContactList": {
                    "type": "Array",
                    "CentralContact": {
                        "type": "Object",
                        "CentralContactName": {
                          "type": "String"
                        },
                        "CentralContactRole": {
                          "type": "String"
                        },
                        "CentralContactPhone": {
                          "type": "String"
                        },
                        "CentralContactPhoneExt": {
                          "type": "String"
                        },
                        "CentralContactEMail": {
                          "type": "String"
                        }
                    }
                  },
                  "OverallOfficialList": {
                    "type": "Array",
                    "OverallOfficial": {
                        "type": "Object",
                        "OverallOfficialName": {
                          "type": "String"
                        },
                        "OverallOfficialAffiliation": {
                          "type": "String"
                        },
                        "OverallOfficialRole": {
                          "type": "String"
                        }
                    }
                  },
                  "LocationList": {
                    "type": "Array",
                    "Location": {
                        "type": "Object",
                        "LocationFacility": {
                          "type": "String"
                        },
                        "LocationStatus": {
                          "type": "String"
                        },
                        "LocationCity": {
                          "type": "String"
                        },
                        "LocationState": {
                          "type": "String"
                        },
                        "LocationZip": {
                          "type": "String"
                        },
                        "LocationCountry": {
                          "type": "String"
                        },
                        "LocationContactList": {
                          "type": "Array",
                          "LocationContact": {
                              "type": "Object",
                              "LocationContactName": {
                                "type": "String"
                              },
                              "LocationContactRole": {
                                "type": "String"
                              },
                              "LocationContactPhone": {
                                "type": "String"
                              },
                              "LocationContactPhoneExt": {
                                "type": "String"
                              },
                              "LocationContactEMail": {
                                "type": "String"
                              }
                          }
                        }
                    }
                  }
              },
              "ReferencesModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html#References",
                  "ReferenceList": {
                    "type": "Array",
                    "Reference": {
                        "type": "Object",
                        "ReferencePMID": {
                          "type": "String"
                        },
                        "ReferenceType": {
                          "type": "String"
                        },
                        "ReferenceCitation": {
                          "type": "String"
                        },
                        "RetractionList": {
                          "type": "Array",
                          "Retraction": {
                              "type": "Object",
                              "RetractionPMID": {
                                "type": "String"
                              },
                              "RetractionSource": {
                                "type": "String"
                              }
                          }
                        }
                    }
                  },
                  "SeeAlsoLinkList": {
                    "type": "Array",
                    "SeeAlsoLink": {
                        "type": "Object",
                        "SeeAlsoLinkLabel": {
                          "type": "String"
                        },
                        "SeeAlsoLinkURL": {
                          "type": "String"
                        }
                    }
                  },
                  "AvailIPDList": {
                    "type": "Array",
                    "AvailIPD": {
                        "type": "Object",
                        "AvailIPDId": {
                          "type": "String"
                        },
                        "AvailIPDType": {
                          "type": "String"
                        },
                        "AvailIPDURL": {
                          "type": "String"
                        },
                        "AvailIPDComment": {
                          "type": "String"
                        }
                    }
                  }
              },
              "IPDSharingStatementModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/definitions.html#IPDSharing",
                  "IPDSharing": {
                    "type": "String"
                  },
                  "IPDSharingDescription": {
                    "type": "String"
                  },
                  "IPDSharingInfoTypeList": {
                    "type": "Array",
                    "IPDSharingInfoType": {
                        "type": "String"
                    }
                  },
                  "IPDSharingTimeFrame": {
                    "type": "String"
                  },
                  "IPDSharingAccessCriteria": {
                    "type": "String"
                  },
                  "IPDSharingURL": {
                    "type": "String"
                  }
              }
            },
            "ResultsSection": {
              "type": "Object",
              "dedLink": "https://prsinfo.clinicaltrials.gov/results_definitions.html",
              "ParticipantFlowModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/results_definitions.html#Result_ParticipantFlow",
                  "FlowPreAssignmentDetails": {
                    "type": "String"
                  },
                  "FlowRecruitmentDetails": {
                    "type": "String"
                  },
                  "FlowTypeUnitsAnalyzed": {
                    "type": "String"
                  },
                  "FlowGroupList": {
                    "type": "Array",
                    "FlowGroup": {
                        "type": "Object",
                        "FlowGroupId": {
                          "type": "String"
                        },
                        "FlowGroupTitle": {
                          "type": "String"
                        },
                        "FlowGroupDescription": {
                          "type": "String"
                        }
                    }
                  },
                  "FlowPeriodList": {
                    "type": "Array",
                    "FlowPeriod": {
                        "type": "Object",
                        "FlowPeriodTitle": {
                          "type": "String"
                        },
                        "FlowMilestoneList": {
                          "type": "Array",
                          "FlowMilestone": {
                              "type": "Object",
                              "FlowMilestoneType": {
                                "type": "String"
                              },
                              "FlowMilestoneComment": {
                                "type": "String"
                              },
                              "FlowAchievementList": {
                                "type": "Array",
                                "FlowAchievement": {
                                    "type": "Object",
                                    "FlowAchievementGroupId": {
                                      "type": "String"
                                    },
                                    "FlowAchievementComment": {
                                      "type": "String"
                                    },
                                    "FlowAchievementNumSubjects": {
                                      "type": "String"
                                    },
                                    "FlowAchievementNumUnits": {
                                      "type": "String"
                                    }
                                }
                              }
                          }
                        },
                        "FlowDropWithdrawList": {
                          "type": "Array",
                          "FlowDropWithdraw": {
                              "type": "Object",
                              "FlowDropWithdrawType": {
                                "type": "String"
                              },
                              "FlowDropWithdrawComment": {
                                "type": "String"
                              },
                              "FlowReasonList": {
                                "type": "Array",
                                "FlowReason": {
                                    "type": "Object",
                                    "FlowReasonGroupId": {
                                      "type": "String"
                                    },
                                    "FlowReasonComment": {
                                      "type": "String"
                                    },
                                    "FlowReasonNumSubjects": {
                                      "type": "String"
                                    },
                                    "FlowReasonNumUnits": {
                                      "type": "String"
                                    }
                                }
                              }
                          }
                        }
                    }
                  }
              },
              "BaselineCharacteristicsModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/results_definitions.html#Result_Baseline",
                  "BaselinePopulationDescription": {
                    "type": "String"
                  },
                  "BaselineTypeUnitsAnalyzed": {
                    "type": "String"
                  },
                  "BaselineGroupList": {
                    "type": "Array",
                    "BaselineGroup": {
                        "type": "Object",
                        "BaselineGroupId": {
                          "type": "String"
                        },
                        "BaselineGroupTitle": {
                          "type": "String"
                        },
                        "BaselineGroupDescription": {
                          "type": "String"
                        }
                    }
                  },
                  "BaselineDenomList": {
                    "type": "Array",
                    "BaselineDenom": {
                        "type": "Object",
                        "BaselineDenomUnits": {
                          "type": "String"
                        },
                        "BaselineDenomCountList": {
                          "type": "Array",
                          "BaselineDenomCount": {
                              "type": "Object",
                              "BaselineDenomCountGroupId": {
                                "type": "String"
                              },
                              "BaselineDenomCountValue": {
                                "type": "String"
                              }
                          }
                        }
                    }
                  },
                  "BaselineMeasureList": {
                    "type": "Array",
                    "BaselineMeasure": {
                        "type": "Object",
                        "BaselineMeasureTitle": {
                          "type": "String"
                        },
                        "BaselineMeasureDescription": {
                          "type": "String"
                        },
                        "BaselineMeasurePopulationDescription": {
                          "type": "String"
                        },
                        "BaselineMeasureParamType": {
                          "type": "String"
                        },
                        "BaselineMeasureDispersionType": {
                          "type": "String"
                        },
                        "BaselineMeasureUnitOfMeasure": {
                          "type": "String"
                        },
                        "BaselineMeasureCalculatePct": {
                          "type": "String"
                        },
                        "BaselineMeasureDenomUnitsSelected": {
                          "type": "String"
                        },
                        "BaselineMeasureDenomList": {
                          "type": "Array",
                          "BaselineMeasureDenom": {
                              "type": "Object",
                              "BaselineMeasureDenomUnits": {
                                "type": "String"
                              },
                              "BaselineMeasureDenomCountList": {
                                "type": "Array",
                                "BaselineMeasureDenomCount": {
                                    "type": "Object",
                                    "BaselineMeasureDenomCountGroupId": {
                                      "type": "String"
                                    },
                                    "BaselineMeasureDenomCountValue": {
                                      "type": "String"
                                    }
                                }
                              }
                          }
                        },
                        "BaselineClassList": {
                          "type": "Array",
                          "BaselineClass": {
                              "type": "Object",
                              "BaselineClassTitle": {
                                "type": "String"
                              },
                              "BaselineClassDenomList": {
                                "type": "Array",
                                "BaselineClassDenom": {
                                    "type": "Object",
                                    "BaselineClassDenomUnits": {
                                      "type": "String"
                                    },
                                    "BaselineClassDenomCountList": {
                                      "type": "Array",
                                      "BaselineClassDenomCount": {
                                          "type": "Object",
                                          "BaselineClassDenomCountGroupId": {
                                            "type": "String"
                                          },
                                          "BaselineClassDenomCountValue": {
                                            "type": "String"
                                          }
                                      }
                                    }
                                }
                              },
                              "BaselineCategoryList": {
                                "type": "Array",
                                "BaselineCategory": {
                                    "type": "Object",
                                    "BaselineCategoryTitle": {
                                      "type": "String"
                                    },
                                    "BaselineMeasurementList": {
                                      "type": "Array",
                                      "BaselineMeasurement": {
                                          "type": "Object",
                                          "BaselineMeasurementGroupId": {
                                            "type": "String"
                                          },
                                          "BaselineMeasurementValue": {
                                            "type": "String"
                                          },
                                          "BaselineMeasurementSpread": {
                                            "type": "String"
                                          },
                                          "BaselineMeasurementLowerLimit": {
                                            "type": "String"
                                          },
                                          "BaselineMeasurementUpperLimit": {
                                            "type": "String"
                                          },
                                          "BaselineMeasurementComment": {
                                            "type": "String"
                                          }
                                      }
                                    }
                                }
                              }
                          }
                        }
                    }
                  }
              },
              "OutcomeMeasuresModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/results_definitions.html#Result_Outcome_Measure",
                  "OutcomeMeasureList": {
                    "type": "Array",
                    "OutcomeMeasure": {
                        "type": "Object",
                        "OutcomeMeasureType": {
                          "type": "String"
                        },
                        "OutcomeMeasureTitle": {
                          "type": "String"
                        },
                        "OutcomeMeasureDescription": {
                          "type": "String"
                        },
                        "OutcomeMeasurePopulationDescription": {
                          "type": "String"
                        },
                        "OutcomeMeasureReportingStatus": {
                          "type": "String"
                        },
                        "OutcomeMeasureAnticipatedPostingDate": {
                          "type": "String"
                        },
                        "OutcomeMeasureParamType": {
                          "type": "String"
                        },
                        "OutcomeMeasureDispersionType": {
                          "type": "String"
                        },
                        "OutcomeMeasureUnitOfMeasure": {
                          "type": "String"
                        },
                        "OutcomeMeasureCalculatePct": {
                          "type": "String"
                        },
                        "OutcomeMeasureTimeFrame": {
                          "type": "String"
                        },
                        "OutcomeMeasureTypeUnitsAnalyzed": {
                          "type": "String"
                        },
                        "OutcomeMeasureDenomUnitsSelected": {
                          "type": "String"
                        },
                        "OutcomeGroupList": {
                          "type": "Array",
                          "OutcomeGroup": {
                              "type": "Object",
                              "OutcomeGroupId": {
                                "type": "String"
                              },
                              "OutcomeGroupTitle": {
                                "type": "String"
                              },
                              "OutcomeGroupDescription": {
                                "type": "String"
                              }
                          }
                        },
                        "OutcomeDenomList": {
                          "type": "Array",
                          "OutcomeDenom": {
                              "type": "Object",
                              "OutcomeDenomUnits": {
                                "type": "String"
                              },
                              "OutcomeDenomCountList": {
                                "type": "Array",
                                "OutcomeDenomCount": {
                                    "type": "Object",
                                    "OutcomeDenomCountGroupId": {
                                      "type": "String"
                                    },
                                    "OutcomeDenomCountValue": {
                                      "type": "String"
                                    }
                                }
                              }
                          }
                        },
                        "OutcomeClassList": {
                          "type": "Array",
                          "OutcomeClass": {
                              "type": "Object",
                              "OutcomeClassTitle": {
                                "type": "String"
                              },
                              "OutcomeClassDenomList": {
                                "type": "Array",
                                "OutcomeClassDenom": {
                                    "type": "Object",
                                    "OutcomeClassDenomUnits": {
                                      "type": "String"
                                    },
                                    "OutcomeClassDenomCountList": {
                                      "type": "Array",
                                      "OutcomeClassDenomCount": {
                                          "type": "Object",
                                          "OutcomeClassDenomCountGroupId": {
                                            "type": "String"
                                          },
                                          "OutcomeClassDenomCountValue": {
                                            "type": "String"
                                          }
                                      }
                                    }
                                }
                              },
                              "OutcomeCategoryList": {
                                "type": "Array",
                                "OutcomeCategory": {
                                    "type": "Object",
                                    "OutcomeCategoryTitle": {
                                      "type": "String"
                                    },
                                    "OutcomeMeasurementList": {
                                      "type": "Array",
                                      "OutcomeMeasurement": {
                                          "type": "Object",
                                          "OutcomeMeasurementGroupId": {
                                            "type": "String"
                                          },
                                          "OutcomeMeasurementValue": {
                                            "type": "String"
                                          },
                                          "OutcomeMeasurementSpread": {
                                            "type": "String"
                                          },
                                          "OutcomeMeasurementLowerLimit": {
                                            "type": "String"
                                          },
                                          "OutcomeMeasurementUpperLimit": {
                                            "type": "String"
                                          },
                                          "OutcomeMeasurementComment": {
                                            "type": "String"
                                          }
                                      }
                                    }
                                }
                              }
                          }
                        },
                        "OutcomeAnalysisList": {
                          "type": "Array",
                          "dedLink": "https://prsinfo.clinicaltrials.gov/results_definitions.html#Result_Outcome_Analysis",
                          "OutcomeAnalysis": {
                              "type": "Object",
                              "OutcomeAnalysisGroupIdList": {
                                "type": "Array",
                                "OutcomeAnalysisGroupId": {
                                    "type": "String"
                                }
                              },
                              "OutcomeAnalysisGroupDescription": {
                                "type": "String"
                              },
                              "OutcomeAnalysisTestedNonInferiority": {
                                "type": "String"
                              },
                              "OutcomeAnalysisNonInferiorityType": {
                                "type": "String"
                              },
                              "OutcomeAnalysisNonInferiorityComment": {
                                "type": "String"
                              },
                              "OutcomeAnalysisPValue": {
                                "type": "String"
                              },
                              "OutcomeAnalysisPValueComment": {
                                "type": "String"
                              },
                              "OutcomeAnalysisStatisticalMethod": {
                                "type": "String"
                              },
                              "OutcomeAnalysisStatisticalComment": {
                                "type": "String"
                              },
                              "OutcomeAnalysisParamType": {
                                "type": "String"
                              },
                              "OutcomeAnalysisParamValue": {
                                "type": "String"
                              },
                              "OutcomeAnalysisCIPctValue": {
                                "type": "String"
                              },
                              "OutcomeAnalysisCINumSides": {
                                "type": "String"
                              },
                              "OutcomeAnalysisCILowerLimit": {
                                "type": "String"
                              },
                              "OutcomeAnalysisCIUpperLimit": {
                                "type": "String"
                              },
                              "OutcomeAnalysisCILowerLimitComment": {
                                "type": "String"
                              },
                              "OutcomeAnalysisCIUpperLimitComment": {
                                "type": "String"
                              },
                              "OutcomeAnalysisDispersionType": {
                                "type": "String"
                              },
                              "OutcomeAnalysisDispersionValue": {
                                "type": "String"
                              },
                              "OutcomeAnalysisEstimateComment": {
                                "type": "String"
                              },
                              "OutcomeAnalysisOtherAnalysisDescription": {
                                "type": "String"
                              }
                          }
                        }
                    }
                  }
              },
              "AdverseEventsModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/results_definitions.html#Result_AdverseEvents",
                  "EventsFrequencyThreshold": {
                    "type": "String"
                  },
                  "EventsTimeFrame": {
                    "type": "String"
                  },
                  "EventsDescription": {
                    "type": "String"
                  },
                  "EventGroupList": {
                    "type": "Array",
                    "EventGroup": {
                        "type": "Object",
                        "EventGroupId": {
                          "type": "String"
                        },
                        "EventGroupTitle": {
                          "type": "String"
                        },
                        "EventGroupDescription": {
                          "type": "String"
                        },
                        "EventGroupDeathsNumAffected": {
                          "type": "String"
                        },
                        "EventGroupDeathsNumAtRisk": {
                          "type": "String"
                        },
                        "EventGroupSeriousNumAffected": {
                          "type": "String"
                        },
                        "EventGroupSeriousNumAtRisk": {
                          "type": "String"
                        },
                        "EventGroupOtherNumAffected": {
                          "type": "String"
                        },
                        "EventGroupOtherNumAtRisk": {
                          "type": "String"
                        }
                    }
                  },
                  "SeriousEventList": {
                    "type": "Array",
                    "SeriousEvent": {
                        "type": "Object",
                        "SeriousEventTerm": {
                          "type": "String"
                        },
                        "SeriousEventOrganSystem": {
                          "type": "String"
                        },
                        "SeriousEventSourceVocabulary": {
                          "type": "String"
                        },
                        "SeriousEventAssessmentType": {
                          "type": "String"
                        },
                        "SeriousEventNotes": {
                          "type": "String"
                        },
                        "SeriousEventStatsList": {
                          "type": "Array",
                          "SeriousEventStats": {
                              "type": "Object",
                              "SeriousEventStatsGroupId": {
                                "type": "String"
                              },
                              "SeriousEventStatsNumEvents": {
                                "type": "String"
                              },
                              "SeriousEventStatsNumAffected": {
                                "type": "String"
                              },
                              "SeriousEventStatsNumAtRisk": {
                                "type": "String"
                              }
                          }
                        }
                    }
                  },
                  "OtherEventList": {
                    "type": "Array",
                    "OtherEvent": {
                        "type": "Object",
                        "OtherEventTerm": {
                          "type": "String"
                        },
                        "OtherEventOrganSystem": {
                          "type": "String"
                        },
                        "OtherEventSourceVocabulary": {
                          "type": "String"
                        },
                        "OtherEventAssessmentType": {
                          "type": "String"
                        },
                        "OtherEventNotes": {
                          "type": "String"
                        },
                        "OtherEventStatsList": {
                          "type": "Array",
                          "OtherEventStats": {
                              "type": "Object",
                              "OtherEventStatsGroupId": {
                                "type": "String"
                              },
                              "OtherEventStatsNumEvents": {
                                "type": "String"
                              },
                              "OtherEventStatsNumAffected": {
                                "type": "String"
                              },
                              "OtherEventStatsNumAtRisk": {
                                "type": "String"
                              }
                          }
                        }
                    }
                  }
              },
              "MoreInfoModule": {
                  "type": "Object",
                  "LimitationsAndCaveatsDescription": {
                    "type": "String",
                    "dedLink": "https://prsinfo.clinicaltrials.gov/results_definitions.html#Result_LimitationsAndCaveats_description"
                  },
                  "CertainAgreement": {
                    "type": "Object",
                    "dedLink": "https://prsinfo.clinicaltrials.gov/results_definitions.html#Result_CertainAgreement",
                    "AgreementPISponsorEmployee": {
                        "type": "String"
                    },
                    "AgreementRestrictionType": {
                        "type": "String"
                    },
                    "AgreementRestrictiveAgreement": {
                        "type": "String"
                    },
                    "AgreementOtherDetails": {
                        "type": "String"
                    }
                  },
                  "PointOfContact": {
                    "type": "Object",
                    "dedLink": "https://prsinfo.clinicaltrials.gov/results_definitions.html#Result_PointOfContact",
                    "PointOfContactTitle": {
                        "type": "String"
                    },
                    "PointOfContactOrganization": {
                        "type": "String"
                    },
                    "PointOfContactEMail": {
                        "type": "String"
                    },
                    "PointOfContactPhone": {
                        "type": "String"
                    },
                    "PointOfContactPhoneExt": {
                        "type": "String"
                    }
                  }
              }
            },
            "AnnotationSection": {
              "type": "Object",
              "AnnotationModule": {
                  "type": "Object",
                  "UnpostedAnnotation": {
                    "type": "Object",
                    "UnpostedResponsibleParty": {
                        "type": "String"
                    },
                    "UnpostedEventList": {
                        "type": "Array",
                        "UnpostedEvent": {
                          "type": "Object",
                          "UnpostedEventType": {
                              "type": "String"
                          },
                          "UnpostedEventDate": {
                              "type": "String"
                          }
                        }
                    }
                  }
              }
            },
            "DocumentSection": {
              "type": "Object",
              "LargeDocumentModule": {
                  "type": "Object",
                  "dedLink": "https://prsinfo.clinicaltrials.gov/results_definitions.html#DocumentUploadLabel",
                  "LargeDocList": {
                    "type": "Array",
                    "LargeDoc": {
                        "type": "Object",
                        "LargeDocTypeAbbrev": {
                          "type": "String"
                        },
                        "LargeDocHasProtocol": {
                          "type": "String"
                        },
                        "LargeDocHasSAP": {
                          "type": "String"
                        },
                        "LargeDocHasICF": {
                          "type": "String"
                        },
                        "LargeDocLabel": {
                          "type": "String"
                        },
                        "LargeDocDate": {
                          "type": "String"
                        },
                        "LargeDocUploadDate": {
                          "type": "String"
                        },
                        "LargeDocFilename": {
                          "type": "String"
                        }
                    }
                  }
              }
            },
            "DerivedSection": {
              "type": "Object",
              "MiscInfoModule": {
                  "type": "Object",
                  "VersionHolder": {
                    "type": "String"
                  },
                  "RemovedCountryList": {
                    "type": "Array",
                    "RemovedCountry": {
                        "type": "String"
                    }
                  },
                  "SubmissionTracking": {
                    "type": "Object",
                    "SubmissionInfoList": {
                        "type": "Array",
                        "SubmissionInfo": {
                          "type": "Object",
                          "SubmissionReleaseDate": {
                              "type": "String"
                          },
                          "SubmissionUnreleaseDate": {
                              "type": "String"
                          },
                          "SubmissionResetDate": {
                              "type": "String"
                          },
                          "SubmissionMCPReleaseN": {
                              "type": "String"
                          }
                        }
                    }
                  }
              },
              "ConditionBrowseModule": {
                  "type": "Object",
                  "ConditionMeshList": {
                    "type": "Array",
                    "ConditionMesh": {
                        "type": "Object",
                        "ConditionMeshId": {
                          "type": "String"
                        },
                        "ConditionMeshTerm": {
                          "type": "String"
                        }
                    }
                  },
                  "ConditionAncestorList": {
                    "type": "Array",
                    "ConditionAncestor": {
                        "type": "Object",
                        "ConditionAncestorId": {
                          "type": "String"
                        },
                        "ConditionAncestorTerm": {
                          "type": "String"
                        }
                    }
                  },
                  "ConditionBrowseLeafList": {
                    "type": "Array",
                    "ConditionBrowseLeaf": {
                        "type": "Object",
                        "ConditionBrowseLeafId": {
                          "type": "String"
                        },
                        "ConditionBrowseLeafName": {
                          "type": "String"
                        },
                        "ConditionBrowseLeafAsFound": {
                          "type": "String"
                        },
                        "ConditionBrowseLeafRelevance": {
                          "type": "String"
                        }
                    }
                  },
                  "ConditionBrowseBranchList": {
                    "type": "Array",
                    "ConditionBrowseBranch": {
                        "type": "Object",
                        "ConditionBrowseBranchAbbrev": {
                          "type": "String"
                        },
                        "ConditionBrowseBranchName": {
                          "type": "String"
                        }
                    }
                  }
              },
              "InterventionBrowseModule": {
                  "type": "Object",
                  "InterventionMeshList": {
                    "type": "Array",
                    "InterventionMesh": {
                        "type": "Object",
                        "InterventionMeshId": {
                          "type": "String"
                        },
                        "InterventionMeshTerm": {
                          "type": "String"
                        }
                    }
                  },
                  "InterventionAncestorList": {
                    "type": "Array",
                    "InterventionAncestor": {
                        "type": "Object",
                        "InterventionAncestorId": {
                          "type": "String"
                        },
                        "InterventionAncestorTerm": {
                          "type": "String"
                        }
                    }
                  },
                  "InterventionBrowseLeafList": {
                    "type": "Array",
                    "InterventionBrowseLeaf": {
                        "type": "Object",
                        "InterventionBrowseLeafId": {
                          "type": "String"
                        },
                        "InterventionBrowseLeafName": {
                          "type": "String"
                        },
                        "InterventionBrowseLeafAsFound": {
                          "type": "String"
                        },
                        "InterventionBrowseLeafRelevance": {
                          "type": "String"
                        }
                    }
                  },
                  "InterventionBrowseBranchList": {
                    "type": "Array",
                    "InterventionBrowseBranch": {
                        "type": "Object",
                        "InterventionBrowseBranchAbbrev": {
                          "type": "String"
                        },
                        "InterventionBrowseBranchName": {
                          "type": "String"
                        }
                    }
                  }
              }
            }
        }
      }
  }
}

import { format } from "date-fns"
import { getDocById } from "../services/axios"
import { convertPercentStringToDecimal } from "./convertPercentStringToDecimal"
import { formatDate, formatDateFromInvoice } from "./formatDate"
import { ifParenthesesStrConvertToNegNum } from "./ifParenthesesStrConvertToNegNum"
import { isValidDate } from "./isValidDate"
import { arrayToCsv, downloadBlob } from "./JsonToCsv"



// export async function handlePrintCsvMany(rows=[], client = {}) {
export function handlePrintCsvMany(rows=[], setIsFetching=()=>{}) {
  const client = rows.at(0)?.original.client
  console.log("client", client)
  console.log("rows", rows)

  if (client) {
    const finalArrayForCsv = []
    rows.forEach((row, i) => {
      const invoice = row.original // TODO (true ?)
      console.log("INVOICE", invoice)
      console.log("DOCTYPE", invoice.docType)
      if (invoice.docType == "REVENUE") {
        if (i === 0) {
          const dataRowsWithHeaderRow = handlePrintCsvRevenue(invoice, client, true, true)
          dataRowsWithHeaderRow.forEach(row => finalArrayForCsv.push(row))
        }
        else {
          const dataRows = handlePrintCsvRevenue(invoice, client, true)
          dataRows.forEach(row => finalArrayForCsv.push(row))
        }
      }
      else if (invoice.docType == "JIB") {
        if (i === 0) {
          const dataRowsWithHeaderRow = handlePrintCsvJib(invoice, client, true, true)
          dataRowsWithHeaderRow.forEach(row => finalArrayForCsv.push(row))
        }
        else {
          const dataRows = handlePrintCsvJib(invoice, client, true)
          dataRows.forEach(row => finalArrayForCsv.push(row))
        }
      }
    })
  
    // console.log("finalArrayForCsv", finalArrayForCsv)
    const csv = arrayToCsv(finalArrayForCsv)

    const currentDate = format(new Date(), "yyyy.MM.dd")
    const clientIndicator = client["ELclientBaName"] || ""
    const invoice = rows.at(0)?.original // for naming, down below
    const fileName = `ClarityExport-${currentDate}-${clientIndicator}-${invoice.docType}-aggregate.csv`
    downloadBlob(csv, fileName, 'text/csv;charset=utf-8;')
    // downloadBlob(csv, 'blob.csv', 'text/csv;charset=utf-8;')
  }

  setIsFetching(false)
}



export function handlePrintCsvRevenue(invoice = {}, client = {}, many = false, firstOfMany = false) {
  const customColumnsKeys = Object.keys(invoice.custom || {})?.sort() || []  // sort = keeps csv printout the same for all invoices (custom data fields may be 'set' in different sequences for different invoices, resulting in objectKeys being in a different order per invoice)
  const customColumnsValues = []
  if (customColumnsKeys.length > 0) {
    for (const key of customColumnsKeys) {
      let value = invoice.custom[key] || ""
      if (value !== null) {
        if (isValidDate(new Date(value))) {
          value = formatDate(value)
        }
      }
      customColumnsValues.push(value)
    }
  }



  const taxTypesArray = []
  for (let i = 1; i < 11; i++) {
    taxTypesArray.push(`Tax Type ${i}`)
    taxTypesArray.push(`Gross Tax ${i}`)
    taxTypesArray.push(`Net Tax ${i}`)
  }

  const deductTypesArray = []
  for (let i = 1; i < 11; i++) {
    deductTypesArray.push(`Deduct Type ${i}`)
    deductTypesArray.push(`Gross Deduct ${i}`)
    deductTypesArray.push(`Net Deduct ${i}`)
  }


  // const headerRow = [
  //   "operator", // i
  //   "ELclientBaName", // c
  //   "opOwnerNum", // i
  //   "docNum", // i
  //   "receivedDate", // i
  //   "amtOriginal", // i (TODO: is it right?  or need to subtract something? -- ..maybe this is for statement amtOriginal)
    
  //   "ccId", // s
  //   "wellName", // s

  //   "mi.category", // sd
  //   "mi.type", // sd
  //   "mi.owner.interestPercentage", // sd
  //   "mi.owner.distributionPercentage", // sd
  //   "mi.date", // sd
  //   "mi.dateFormatted", // sd
  //   "mi.price", // sd
  //   "mi.btu", // sd
  //   "mi.property.volume", // sd
  //   "mi.propert.value", // sd

  //   "Gross Taxes",
  //   "Gross Deducts",
  //   "Net Value",
  //   "Owner Volume",
  //   "Owner Value",
  //   "Owner Taxes",
  //   "Owner Deducts",
  //   "Owner Net Value",

  //   ...taxTypesArray,
  //   ...deductTypesArray

  //   // TODO:  custom columns
  // ]
  const headerRow = [
    "Operator Name", // "operator", // i
    "Owner Name", // "ELclientBaName", // c
    "Owner Number", // "opOwnerNum", // i
    "Check Number", // "docNum", // i
    "Check Date", // "receivedDate", // i
    "Check Amount", // "amtOriginal", // i (TODO: is it right?  or need to subtract something? -- ..maybe this is for statement amtOriginal)
    
    // "Cash Date", // CUSTOM COLUMN ("cashDate")
    ...customColumnsKeys, // CUSTOM COLUMNS

    "Operator CC", // "cc", // s  // UPDATED: used to be ccId, Ross wanted cc
    "Property Description", // "wellName", // s

    "Product Code Description", // "mi.category", // sd
    "Interest Type", // "mi.type", // sd

    // "Owner Percent", // "mi.owner.interestPercentage", // sd
    // "Distribution Percent", // "mi.owner.distributionPercentage", // sd
    "Owner Decimal", // "mi.owner.interestPercentage", // sd
    "Distribution Decimal", // "mi.owner.distributionPercentage", // sd

    // "Prod Date", // "mi.date", // sd
    "Prod Date", // "mi.dateFormatted", // sd


    "Price", // "mi.price", // sd
    "BTU Factor", // "mi.btu", // sd
    "Gross Volume", // "mi.property.volume", // sd
    "Gross Value", // "mi.propert.value", // sd

    "Gross Taxes",
    "Gross Deducts",
    "Net Value",
    "Owner Volume",
    "Owner Value",
    "Owner Taxes",
    "Owner Deducts",
    "Owner Net Value",

    ...taxTypesArray,
    ...deductTypesArray

    // TODO:  custom columns
  ]

  // const clientKeys = Object.keys(client)
  // const invoiceKeys = Object.keys(invoice)
  // console.log(clientKeys)
  // console.log(invoiceKeys)
  
  const dataRows = []


  const dataRowsConsistent = [
    invoice["operator"],
    client["ELclientBaName"],
    invoice["opOwnerNum"],
    invoice["docNum"],
    invoice["receivedDate"],
    invoice["amtOriginal"],
    ...customColumnsValues
  ]

  // each invoice = separated into # of rows BASED ON mi
  const invoiceRows = invoice.statements.forEach(statement => {
    // const statementKeys = Object.keys(statement)

    const statementDetailsKeys = Object.keys(statement.statementDetails || {})
    if (statementDetailsKeys.length > 0) {
      statementDetailsKeys.forEach(key => {
        statement.statementDetails[key].forEach(mi => {
          const taxes = mi.taxDeducts.filter(lineItem => lineItem.typeCategory == "Tax") || []
          const deducts = mi.taxDeducts.filter(lineItem => lineItem.typeCategory == "Deduct") || []

          const grossTaxes = taxes.reduce((prev, curr) => prev + ifParenthesesStrConvertToNegNum(curr.property?.value), 0) || 0
          const grossDeducts = deducts.reduce((prev, curr) => prev + ifParenthesesStrConvertToNegNum(curr.property?.value), 0) || 0
          const netValue = grossTaxes + grossDeducts + ifParenthesesStrConvertToNegNum(mi.property?.value)

          const ownerTaxes = taxes.reduce((prev, curr) => prev + ifParenthesesStrConvertToNegNum(curr.owner?.value), 0) || 0
          const ownerDeducts = deducts.reduce((prev, curr) => prev + ifParenthesesStrConvertToNegNum(curr.owner?.value), 0) || 0
          const ownerNetValue = ownerTaxes + ownerDeducts + ifParenthesesStrConvertToNegNum(mi.owner?.value)

          const taxItemArray = []
          for (let i = 0; i < 10; i++) {
            if (taxes.at(i)) {
              const taxItem = taxes.at(i)
              taxItemArray.push(taxItem.type) // "Tax Type X"
              taxItemArray.push(taxItem.property?.value) // "Gross Tax X"
              taxItemArray.push(taxItem.owner?.value) // "Net Tax X"
            }
            else {
              taxItemArray.push("")
              taxItemArray.push("")
              taxItemArray.push("")
            }
          }

          const deductItemArray = []
          for (let i = 0; i < 10; i++) {
            if (deducts.at(i)) {
              const deductItem = deducts.at(i)
              deductItemArray.push(deductItem.type) // "Tax Type X"
              deductItemArray.push(deductItem.property?.value) // "Gross Tax X"
              deductItemArray.push(deductItem.owner?.value) // "Net Tax X"
            }
            else {
              deductItemArray.push("")
              deductItemArray.push("")
              deductItemArray.push("")
            }
          }

          let dataRowsMineralInterest = [
            ...dataRowsConsistent,
            statement["cc"],
            statement["wellName"],
            key.toUpperCase(),
            mi["type"],
            convertPercentStringToDecimal(mi.owner?.interestPercent),
            convertPercentStringToDecimal(mi.owner?.distributionPercent),
            // mi.date,
            formatDateFromInvoice(mi.date),
            // formatDate(invoice.custom?.cashDate), // doing elsewhere (all custom columns...)
            mi.property?.price,
            mi.btu,
            mi.property?.volume,
            mi.property?.value,
            grossTaxes,
            grossDeducts,
            netValue,
            mi.owner?.volume,
            mi.owner?.value,
            ownerTaxes,
            ownerDeducts,
            ownerNetValue,
            ...taxItemArray,
            ...deductItemArray
          ]
          dataRows.push(dataRowsMineralInterest)


        })
      })
    }

  })


  if (!many) {
    const csv = arrayToCsv([headerRow, ...dataRows])

    const currentDate = format(new Date(), "yyyy.MM.dd")
    const clientIndicator = client["ELclientBaName"] || ""
    const fileName = `ClarityExport-${currentDate}-${clientIndicator}-${invoice.docType}-invoiceId-${invoice.invoiceId}.csv`
    downloadBlob(csv, fileName, 'text/csv;charset=utf-8;')
  }
  else if (many) {
    if (firstOfMany) {
      return [headerRow, ...dataRows]
    }
    else {
      return dataRows
    }
  }



}



export function handlePrintCsvJib(invoice = {}, client = {}, many = false, firstOfMany = false) {
  const customColumnsKeys = Object.keys(invoice.custom || {})?.sort() || []  // sort = keeps csv printout the same for all invoices (custom data fields may be 'set' in different sequences for different invoices, resulting in objectKeys being in a different order per invoice)
  const customColumnsValues = []
  if (customColumnsKeys.length > 0) {
    for (const key of customColumnsKeys) {
      let value = invoice.custom[key] || ""
      if (value !== null) {
        if (isValidDate(new Date(value))) {
          value = formatDate(value)
        }
      }
      customColumnsValues.push(value)
    }
  }



  const headerRow = [
    "Operator Name", // "operator", // i
    "Owner Name", // "ELclientBaName", // c
    "Owner Number", // "opOwnerNum", // i
    "Check Number", // "docNum", // i
    "Check Date", // "receivedDate", // i
    "Check Amount", // "amtOriginal", // i (TODO: is it right?  or need to subtract something? -- ..maybe this is for statement amtOriginal)
    
    // "Cash Date", // CUSTOM COLUMN ("cashDate")
    ...customColumnsKeys, // CUSTOM COLUMNS

    "Cost Center", // "cc", // s
    "OpCC", // "ccId", // s
    "AFE", // "afe", // s
    "OpAFE", // "afeOp", // s
    "Well Name", // "wellName", // s
    "Statement Amount", // "amtOriginal", // s

    "Category", // "category", // sd ()

    "Account", // "account", // sd
    "Description", // "desc", // sd
    "Partner Decimal", // "partnerPercent", // sd
    "Activity Month", // "date", // sd
    "Gross Amount", // "amtGross", // sd
    "Original", // "amtOriginal", // sd
  ]

  const dataRows = []

  const dataRowsConsistent = [
    invoice["operator"],
    client["ELclientBaName"],
    invoice["opOwnerNum"],
    invoice["docNum"],
    invoice["receivedDate"],
    invoice["amtOriginal"],
    ...customColumnsValues
  ]

  // each invoice = separated into # of rows BASED ON li
  const invoiceRows = invoice.statements.forEach(statement => {
    const statementDetailsKeys = Object.keys(statement.statementDetails || {})
    if (statementDetailsKeys.length > 0) {
      statementDetailsKeys.forEach(key => {
        statement.statementDetails[key].forEach(li => {
          let dataRowsLineItem = [
            ...dataRowsConsistent,
            statement["cc"],
            statement["ccId"],
            statement["afe"] || "",
            statement["afeOp"] || "",
            statement["wellName"],
            statement["amtOriginal"],
            key.toUpperCase(),
            li["account"],
            li["desc"],
            convertPercentStringToDecimal(li.partnerPercent),
            formatDateFromInvoice(li.date),
            li["amtGross"],
            li["amtOriginal"],
          ]
          dataRows.push(dataRowsLineItem)
        })
      })
    }

  })


  if (!many) {
    const csv = arrayToCsv([headerRow, ...dataRows])

    const currentDate = format(new Date(), "yyyy.MM.dd")
    const clientIndicator = client["ELclientBaName"] || ""
    const fileName = `ClarityExport-${currentDate}-${clientIndicator}-${invoice.docType}-invoiceId-${invoice.invoiceId}.csv`
    downloadBlob(csv, fileName, 'text/csv;charset=utf-8;')
  }
  else if (many) {
    if (firstOfMany) {
      return [headerRow, ...dataRows]
    }
    else {
      return dataRows
    }
  }



}



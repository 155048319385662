// third party
import { saveAs } from "file-saver";
import { utils, writeFile, read } from "xlsx";
import { format } from "date-fns";
// custom
import { ifParenthesesStrConvertToNegNum } from "../../utils/ifParenthesesStrConvertToNegNum";
import * as EF from "../utils/ExportFormatter";
import {
	getCustomStatementsFromS3,
	getWellIndexFromS3,
} from "../utils/s3client";

const currentYear = new Date().getFullYear();
const minYear = 2000;

// orchestrator for export formatted statements
export async function handleFormattedCsvExport(
	rows = [],
	setIsFetching = () => {},
	wellIndexReference = null,
	customStatementsYear = "",
	customStatementsMonth = 0 // this will be a number
) {
	if (!wellIndexReference || wellIndexReference === "") {
		alert("No well index file found");
		console.log("No well index file found");
		setIsFetching(false);
		return;
	}

	if (wellIndexReference === "s3") {
		console.log("Fetching well index file from s3");
		const s3WellIndexData = await getWellIndexFromS3();
		if (!s3WellIndexData) {
			console.log("No well index file found in s3");
			setIsFetching(false);
			return;
		}
		wellIndexReference = new Blob([s3WellIndexData.Body], {
			type: s3WellIndexData.ContentType,
		});
	}

	const client = rows.at(0)?.original.client;
	console.log("client", client);
	console.log("rows", rows);

	// get custom statement data from s3 if necessary
	let customStatements = null;
	// of one value is blank and the other is not, alert and return
	console.log(
		"Custom statements year and month",
		customStatementsYear,
		customStatementsMonth
	);
	if (
		(customStatementsYear === "" && customStatementsMonth !== 0) ||
		(customStatementsMonth === 0 && customStatementsYear !== "")
	) {
		alert("Both year and month are required for custom statements");
		console.log("Both year and month are required for custom statements");
		setIsFetching(false);
		return;
	}
	if (customStatementsYear !== "" && customStatementsMonth !== "") {
		customStatementsYear = parseInt(customStatementsYear);
		customStatementsMonth = parseInt(customStatementsMonth);
		if (customStatementsYear < minYear || customStatementsYear > currentYear) {
			alert("Year must be between 2000 and current year");
			console.log("Year must be between 2000 and current year");
			setIsFetching(false);
			return;
		}
		console.log(
			"Custom statements year and month",
			customStatementsYear,
			customStatementsMonth
		);
		// fetch custom statements from db
		customStatements = await getCustomStatementsFromS3(
			client.ELid,
			customStatementsYear,
			customStatementsMonth
		);
		// if no custom statements are found even though they were requested, it should not cause error on the export
		if (!customStatements) {
			alert("No custom statements found - continuing with export");
			console.log("No custom statements found - continuing with export");
		} else {
			customStatements = new Blob([customStatements.Body], {
				type: customStatements.ContentType,
			});
		}
	}

	// get s3 data if it exists
	let s3JsonData = [];
	if (customStatements) {
		const s3Reader = new FileReader();
		s3Reader.onload = (e) => {
			const s3data = new Uint8Array(e.target.result);
			const s3Workbook = read(s3data, { type: "array" });
			const s3Worksheet = s3Workbook.Sheets[s3Workbook.SheetNames[0]];
			s3JsonData = utils.sheet_to_json(s3Worksheet);
			console.log("s3JsonData", s3JsonData.slice(0, 3));
		};
		try {
			s3Reader.readAsArrayBuffer(customStatements);
		} catch (error) {
			alert("Error reading custom statements from s3");
			console.log("Error reading custom statements from s3", error);
			setIsFetching(false);
			return;
		}
	}
	// note: consider writing custom statements to another sheet in the xlsx file

	// will be an array of dicts in format [ { 'column1': 'value1', 'column2': 'value2' ...}, {<another record>}, ...]
	// nomenclature: record indicates a row for the final csv - row is from the react table
	const formattedRevenueRecords = [];
	const formattedJibRecords = [];

	if (client) {
		rows.forEach((row, i) => {
			const invoice = row.original;
			console.log("INVOICE", invoice);
			console.log("DOCTYPE", invoice.docType);
			if (invoice.docType === "REVENUE") {
				const invoiceRecords = handleRevenueRow(invoice, client);
				formattedRevenueRecords.push(...invoiceRecords);
			} else if (invoice.docType === "JIB") {
				const invoiceRecords = handleJibRow(invoice, client);
				formattedJibRecords.push(...invoiceRecords);
			}
		});
		const formattedRecords = [
			...formattedRevenueRecords,
			...formattedJibRecords,
		];

		// todo - handle well index file and wellname stuff
		const reader = new FileReader();
		reader.onload = (e) => {
			const data = new Uint8Array(e.target.result);
			const workbook = read(data, { type: "array" });
			const wellIndexSheet = workbook.Sheets[workbook.SheetNames[0]];
			const wellIndexData = utils.sheet_to_json(wellIndexSheet);

			console.log("referenceData", wellIndexData.slice(0, 3));

			// Add new columns and transform data based on referenceData
			const transformedData = formattedRecords.map((record) => {
				if (
					record["Operator CC"] !== undefined &&
					record["Operator CC"] !== ""
				) {
					return {
						...record,
						...EF.mapWellIndexNameAndFile(record["Operator CC"], wellIndexData),
					};
				} else {
					return {
						...record,
						"Well Index Name": "",
						"Well File & Well Name": "",
					};
				}
			});

			// Combine and order data
			const combinedData = [...transformedData, ...s3JsonData];
			const orderedData = combinedData.map((record) => {
				const orderedRecord = {};
				EF.allColumnsOrdered.forEach((column) => {
					orderedRecord[column] = record[column] || "";
				});
				return orderedRecord;
			});

			// write formatted data to xlsx
			const worksheet = utils.json_to_sheet(orderedData);
			const workbookOut = utils.book_new();
			utils.book_append_sheet(workbookOut, worksheet, "FormattedData");

			writeFile(
				workbookOut,
				`FormattedClarityExport-${format(new Date(), "yyyy.MM.dd")}-${
					client["ELclientBaName"]
				}.xlsx`
			);
		};

		try {
			reader.readAsArrayBuffer(wellIndexReference);
		} catch (error) {
			alert("Error reading well index file");
			console.log("Error reading well index file", error);
			setIsFetching(false);
			return;
		}

		// --- old export method
		// const currentDate = format(new Date(), "yyyy.MM.dd");
		// const clientIndicator = client["ELclientBaName"] || "";
		// const fileName = `FormattedClarityExport-${currentDate}-${clientIndicator}.csv`;
		// exportArrayToCSV(formattedRecords, EF.csvColumnNames, fileName);
		// ---
	} else {
		console.log("No client found in rows");
	}

	setIsFetching(false);
}

// DOWNLOAD AND EXPORT ------------------------------------------------
const exportArrayToCSV = (arrayOfDicts, columnNames, fileName) => {
	// utility function for this export instead of the other dump export made by last dev
	// Create the CSV header
	const header = columnNames;
	// Create the CSV rows
	const rows = arrayOfDicts.map((dict) => {
		return columnNames.map((columnName) => {
			return dict[columnName] ? `"${dict[columnName]}"` : '""'; // Quote values and fill with empty string if key is missing
		});
	});
	// Combine header and rows
	const csvContent = [header, ...rows].join("\n");
	// Create a Blob from the CSV content and trigger a download
	const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
	saveAs(blob, fileName);
};

const downloadXlsx = (xlsxData) => {
	const blob = new Blob([xlsxData], {
		type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	});
	const url = URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.href = url;
	a.download = "export.xlsx";
	a.click();
	URL.revokeObjectURL(url);
};

// Doc Handlers ------------------------------------------

const handleRevenueRow = (invoice) => {
	// add revenue records
	const formattedRecordsFromInvoice = [];

	const invoiceRecordData = EF.mapOriginalInvoice(invoice);
	invoice.statements.forEach((statement) => {
		const statementRecordData = EF.mapRevenueStatement(statement);
		const detailsKeys = Object.keys(statement.statementDetails || {});
		detailsKeys.forEach((key) => {
			const interests = statement.statementDetails[key];
			interests.forEach((interest) => {
				const interestRecordMeta = {
					...invoiceRecordData,
					...statementRecordData,
					...EF.mapRevInterestToMetadata(interest, key),
				};

				// prepare tax and deduct information
				const taxes =
					interest.taxDeducts.filter(
						(lineItem) => lineItem.typeCategory == "Tax"
					) || [];
				const deducts =
					interest.taxDeducts.filter(
						(lineItem) => lineItem.typeCategory == "Deduct"
					) || [];
				interest.grossTaxes =
					taxes.reduce(
						(prev, curr) =>
							prev + ifParenthesesStrConvertToNegNum(curr.property?.value),
						0
					) || 0;
				interest.grossDeducts =
					deducts.reduce(
						(prev, curr) =>
							prev + ifParenthesesStrConvertToNegNum(curr.property?.value),
						0
					) || 0;
				interest.netValue =
					interest.grossTaxes +
					interest.grossDeducts +
					ifParenthesesStrConvertToNegNum(interest.property?.value);
				interest.ownerTaxes =
					taxes.reduce(
						(prev, curr) =>
							prev + ifParenthesesStrConvertToNegNum(curr.owner?.value),
						0
					) || 0;
				interest.ownerDeducts =
					deducts.reduce(
						(prev, curr) =>
							prev + ifParenthesesStrConvertToNegNum(curr.owner?.value),
						0
					) || 0;
				interest.ownerNetValue =
					interest.ownerTaxes +
					interest.ownerDeducts +
					ifParenthesesStrConvertToNegNum(interest.owner?.value);

				// ---
				// depending on need of format output, may need to add all interest records first, then add all of the tax records and deduct records as a block appending later
				// const interestRecords = [];
				// const interestRecord = { ...interestRecordMeta, ...EF.mapRevenueMineralInterest(interest) };
				// ---

				// add interest record
				formattedRecordsFromInvoice.push({
					...interestRecordMeta,
					...EF.mapRevenueMineralInterest(interest),
				});
				// create records for each potential tax and deduct type for the interest
				for (let i = 0; i < 10; i++) {
					// handle tax
					if (taxes.at(i)) {
						const taxItem = taxes.at(i);
						formattedRecordsFromInvoice.push({
							...interestRecordMeta,
							...EF.mapTaxItem(taxItem),
						});
					}
					// handle deduct
					if (deducts.at(i)) {
						const deductItem = deducts.at(i);
						formattedRecordsFromInvoice.push({
							...interestRecordMeta,
							...EF.mapDeductions(deductItem),
						});
					}
				}
			});
		});
	});

	return formattedRecordsFromInvoice;
};

const handleJibRow = (invoice) => {
	// add jib records
	const formattedRecordsFromInvoice = [];

	const invoiceRecordData = EF.mapOriginalInvoice(invoice);
	invoice.statements.forEach((statement) => {
		const statementRecordData = EF.mapJibStatement(statement);
		const detailsKeys = Object.keys(statement.statementDetails || {});
		detailsKeys.forEach((key) => {
			const interests = statement.statementDetails[key];
			interests.forEach((interest) => {
				const interestRecordData = EF.mapJibInterest(interest, key);
				const formattedAccountAssignment = EF.mapAccountAssignment(
					interestRecordData.Account,
					interestRecordData.Original
				);
				formattedRecordsFromInvoice.push({
					...invoiceRecordData,
					...statementRecordData,
					...interestRecordData,
					...formattedAccountAssignment,
				});
			});
		});
	});
	return formattedRecordsFromInvoice;
};

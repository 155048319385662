import { ClearRounded, DeleteRounded, EditRounded } from "@mui/icons-material";
import axios from "axios";
import { get, set } from "lodash";
import { ButtonMoreInfo } from "../components/utils/ButtonMoreInfo";
import { IconButtonStyled } from "../components/utils/IconButtonStyled";
import { ModalPropertyValueUpdate } from "../pages/clients/components/ModalProperyValueUpdate";
import { isValidDate } from "./isValidDate";
import { updateDoc } from "../services/axios";
import { TypographyError } from "../components/utils/TypographyError";

import React, { useState } from "react";
import {
	Select,
	TextField,
	Box,
	Stack,
	Typography,
	MenuItem,
} from "@mui/material";
import { filterMonthSelect, filterCashDates } from "../cc_/utils/filtering_utils";
import {
	DateRangeFilter,
	MonthYearFilter,
} from "../cc_/components/CashDateFilters";

async function handleDelete(doc, property, setDocs) {
	const response = window.confirm(
		"Are you sure you want to delete this value?"
	);
	console.log("response", response);
	if (response) {
		// set update properties (+ _id to find doc)
		doc = {
			_id: doc._id,
		};
		set(doc, property, "");

		// update doc on db
		const controller = new AbortController();
		const updatedDoc = await updateDoc(doc, "invoices", controller, true);
		console.log(`updated doc: `, updatedDoc);

		// side effects
		setDocs((prev) =>
			prev.map((prevDoc) =>
				prevDoc._id == updatedDoc._id ? updatedDoc : prevDoc
			)
		);

		return () => controller.abort();
	}
}

const UpdateValueBtn = ({ row, property, setDocsOriginal }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<IconButtonStyled onClick={() => setOpen(true)}>
				<EditRounded sx={{ fontSize: "1.2rem" }} />
			</IconButtonStyled>

			<ModalPropertyValueUpdate
				doc={row.original}
				property={property}
				open={open}
				setOpen={setOpen}
				setDocs={setDocsOriginal}
			/>
		</>
	);
};

const DeleteValueBtn = ({ row, property, setDocsOriginal }) => {
	return (
		<IconButtonStyled
			color="error"
			onClick={() => handleDelete(row.original, property, setDocsOriginal)}
		>
			<ClearRounded />
		</IconButtonStyled>
	);
};

const ExistingValueComponent = ({ row, property, setDocsOriginal }) => {
	const [open, setOpen] = useState(false);

	const value = get(row.original, property);

	return (
		<>
			<Stack direction="row" gap={0.5} alignItems="center">
				<Typography>
					{/* {get(row.original, property)} */}
					{value}
				</Typography>

				{(property == "custom.cashDate" || property == "cashDate") &&
				value != "" ? (
					value?.split("/").at(-1).length < 4 ? (
						<TypographyError sx={{ opacity: 0.8 }}>*REFORMAT*</TypographyError>
					) : null
				) : null}

				<Stack direction="row">
					<UpdateValueBtn
						row={row}
						property={property}
						setDocsOriginal={setDocsOriginal}
					/>
					<DeleteValueBtn
						row={row}
						property={property}
						setDocsOriginal={setDocsOriginal}
					/>
				</Stack>
			</Stack>

			<ModalPropertyValueUpdate
				doc={row.original}
				property={property}
				open={open}
				setOpen={setOpen}
				setDocs={setDocsOriginal}
			/>
		</>
	);
};

async function handleDeleteColumn(property) {
	const response = window.confirm(
		"Are you sure you want to delete this column?  This will affect all invoices."
	);
	console.log("response", response);
	if (response) {
		const controller = new AbortController();

		console.log("property to delete", property);
		let response = await axios.post(
			"/invoices/custom/property?delete=true",
			{ customPropertyName: property },
			{ signal: controller.signal }
		);
		console.log("response (delete custom property)", response);

		if (Boolean(response) == true) {
			window.location.reload();
			// window.alert("Custom column deleted. Please refresh the page to allow for the change to properly take place.")
		}

		return () => controller.abort();
	}
}

const DeleteColumnBtn = ({ property }) => {
	return (
		<IconButtonStyled
			color="error"
			onClick={() => handleDeleteColumn(property)}
		>
			<ClearRounded sx={{ fontSize: "1.2rem" }} />
		</IconButtonStyled>
	);
};

// function displayValueDateOrAlphanumeric(value="") {
//   if (new Date(value)) {
//     return new Date(value)
//   }
//   else {
//     return value
//   }
// }

function sortingFn(rowA, rowB, property, desc) {
	let valueA = Boolean(get(rowA, property)) ? get(rowA, property) : null;
	let valueB = Boolean(get(rowB, property)) ? get(rowB, property) : null;

	/**
	 * These first 3 conditions keep our null values at the bottom. (NO IT DOESN'T)
	 */
	if (valueA === null && valueB !== null) {
		return desc ? -1 : 1;
	}

	if (valueA !== null && valueB === null) {
		return desc ? 1 : -1;
	}

	if (valueA === null && valueB === null) {
		return 0;
	}

	if (
		(valueA?.includes("-") || valueA?.includes("/")) &&
		(valueB?.includes("-") || valueB?.includes("/")) &&
		isValidDate(new Date(valueA)) &&
		isValidDate(new Date(valueB))
	) {
		valueA = new Date(valueA);
		valueB = new Date(valueB);
	} else if (Number(valueA) && Number(valueB)) {
		valueA = Number(valueA);
		valueB = Number(valueB);
	}

	try {
		if (valueA > valueB) return 1;
		if (valueB > valueA) return -1;
		return 0;
	} catch {
		return 0;
	}
}

function headerCell(property) {
	const propertyName = property.includes("custom.")
		? property.replace("custom.", "")
		: property;
	const isPermanentColumn =
		propertyName === "cashDate" || propertyName === "note";

	return (
		<Stack direction="row" alignItems="center" gap={1}>
			<Typography
				sx={{
					fontWeight: 600,
					color: "primary.main",
				}}
			>
				{propertyName}
			</Typography>

			<Stack direction="row" alignItems="center">
				<ButtonMoreInfo
					iconOnly
					columnHeader
					label="Type asterisk (*) to filter out rows with empty values"
				/>
				{!isPermanentColumn && <DeleteColumnBtn property={property} />}
			</Stack>
		</Stack>
	);
}

function muiTableHeaderCellProps() {
	return {
		sx: {
			"& .Mui-TableHeadCell-Content-Wrapper": {
				overflow: "visible",
			},
			// backgroundColor: selectedBackgroundColor,
			// borderLeft: "2px solid lightgray"
		},
	};
}

export function formatToTanStackTableColumn(
	property = "",
	setDocsOriginal = () => {}
) {
	// property = can be nested ("this.nested.property")

	const propertyWithoutCustom = property.includes("custom.")
		? property.replace("custom.", "")
		: property;
	const isCashDateColumn = propertyWithoutCustom === "cashDate";

	if (isCashDateColumn) {
		return {
			accessorKey: property,
			muiTableHeadCellProps: ({ table, column }) => ({
				sx: {
					"& .MuiCollapse-root .MuiBox-root": {
						display: "flex",
						flexDirection: "column",
						gap: "0.5rem",
            margin: "0.25rem 0 0 0",
					},
				},
			}),
			header: headerCell(property),
			Cell: ({ row }) => {
				if (get(row.original, property) && get(row.original, property) !== "") {
					return (
						<ExistingValueComponent
							row={row}
							property={property}
							setDocsOriginal={setDocsOriginal}
						/>
					);
				} else {
					return (
						<UpdateValueBtn
							row={row}
							property={property}
							setDocsOriginal={setDocsOriginal}
						/>
					);
				}
			},
			Filter: ({ column }) => {
				const [filterType, setFilterType] = useState("cashDateRangeFilter");

				return (
					<Box>
						{/* <Select
							value={filterType}
							onChange={(e) => setFilterType(e.target.value)}
						>
							<MenuItem value="cashDateRangeFilter">Date Range</MenuItem>
						  <MenuItem value="cashDateMonthFilter">Month/Year</MenuItem>
						</Select> */}
            {filterType === "cashDateRangeFilter" && (
              <DateRangeFilter
              column={column}
              onFilterChange={(filterValue) =>
                column.setFilterValue(filterValue)
              }
              />
            )}
            {filterType === "cashDateMonthFilter" && (
              <MonthYearFilter
              column={column}
              onFilterChange={(filterValue) =>
                column.setFilterValue(filterValue)
              }
              />
            )}
					</Box>
				);
			},
			filterFn: (row, id, filterValue, column) => {
        return filterCashDates(row, id, filterValue);
				// if (column.getFilterType() === "cashDateRangeFilter") {
				// 	return filterCashDates(row, id, filterValue);
				// }
				// if (column.getFilterType() === "cashDateMonthFilter") {
				// 	return filterMonthSelect(row, id, filterValue);
				// }
				// return true;
			},
		};
	} else {
		return {
			accessorKey: property,
			muiTableHeadCellProps: muiTableHeaderCellProps(),
			header: headerCell(property),
			enableColumnFilterModes: false,
			sortingFn: (rowA, rowB, columnId, desc) =>
				sortingFn(rowA.original, rowB.original, property, desc), // https://stackoverflow.com/questions/63927644/how-does-one-supply-a-custom-sort-function-for-react-table-7
			filterVariant: "fuzzy",
			filterFn: (row, id, filterValue) => {
				if (filterValue.length > 0) {
					if (filterValue.trim() == "*") {
						return row.getValue(id).length > 0;
					} else {
						return row.getValue(id).includes(filterValue);
					}
				} else {
					return true;
				}
			},
			muiTableHeadCellFilterTextFieldProps: ({
				table,
				column,
				rangeFilterIndex,
			}) => ({
				placeholder: `Filter by ${propertyWithoutCustom}`,
			}),

			Cell: ({ row }) => {
				if (get(row.original, property) && get(row.original, property) != "") {
					return (
						<ExistingValueComponent
							row={row}
							property={property}
							setDocsOriginal={setDocsOriginal}
						/>
					);
				} else {
					return (
						<UpdateValueBtn
							row={row}
							property={property}
							setDocsOriginal={setDocsOriginal}
						/>
					);
				}
			},
		};
	}
}

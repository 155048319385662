import { useEffect, useState } from "react";
import { handleErrorCatching } from "../handleErrorCatching";
import axios from "axios";

const sortFnStatements = (a, b) =>
  new Date(b.updatedDate) - new Date(a.updatedDate);

// invoiceId_ (doc._id, not doc.invoiceId)
export function useFetchStatementsByInvoiceId(invoiceId) {
  const [docs, setDocs] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    async function fetchStatements() {
      try {
        const fetchedStatements = await getStatementsByInvoiceId(
          invoiceId,
          controller
        );
        setDocs(fetchedStatements);
      } catch (e) {
        handleErrorCatching(e, "statements");
      } finally {
        setIsFetching(false);
      }
    }
    fetchStatements();
    return () => {
      controller.abort();
    };
  }, [invoiceId]);

  return { docs, isFetching };
}

export async function getStatementsByInvoiceId(
  invoiceId_,
  controller = undefined
) {
  try {
    const response = await axios.get(`/statements?invoiceId_=${invoiceId_}`, {
      signal: controller.signal,
    });
    const statements = response.data;
    const statementsSorted = statements.sort(sortFnStatements);
    return statementsSorted;
  } catch (e) {
    throw e;
  }
}

// // example
// export const getDocById = async (collection, idType = "_id", id, controller, consoleLog = false) => {
//   const response = await axios.get(`/${collection}/${id}/${idType}`, {signal: controller.signal})
//   if (consoleLog) {
//     console.log(`getDoc (${collection}): `, response.data)
//   }
//   return response.data // doc
// }

import React, { useState, useEffect } from "react"
import PropTypes  from "prop-types"
import { Alert, Box, Snackbar, Stack, Typography } from "@mui/material"
import { useSnackbar } from 'notistack'

// EXAMPLE USAGE
{/* <Button
  onClick={() => setToast(!toast)}
>
  Toast
</Button>
{toast && <Toast message="Congratulations! You won!" />} */}


Toast.propTypes = {
  message: PropTypes.string,
  display: PropTypes.bool,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
}
export function Toast({
  message,
  severity = "success",
  // display = true,
  vertical = "top",
  horizontal = "center",
}) {

  const [open, setOpen] = useState(true)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  function handleClose() {
    setOpen(false)
  }

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        ClickAwayListenerProps={{ onClickAway: () => false }}
        anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
        key={vertical + horizontal}  
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}
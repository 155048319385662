import React, { useContext, useState } from 'react'
import { PageContainer } from '../../components/layout/PageContainer'
import { Box, Button, TextField, Typography } from '@mui/material'
import { StateContext } from '../../state-management/app/useContext/StateContext'
import { DispatchContext } from '../../state-management/app/useContext/DispatchContext'
import { UserDashboard } from '../dashboard/UserDashboard'
import Clients from '../clients/Clients'
import { motion } from 'framer-motion';


export default function HomeLoggedIn() {
  
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <UserDashboard />
      </motion.div>
    </>
  )
}

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Box, Divider, colors } from "@mui/material"


MyDivider.propTypes = {
  orientation: PropTypes.oneOf(["horizontal", "vertical"]), 
  thickness: PropTypes.number,
  color: PropTypes.string,
  spacing: PropTypes.number,
  hidden: PropTypes.bool,
  headerDivider: PropTypes.bool,
  mt: PropTypes.number,
  mb: PropTypes.number,
}
export function MyDivider({
  orientation = "horizontal",
  thickness,
  color,
  spacing,
  hidden,
  headerDivider,
  mt = 0,
  mb = 0
}) {

  if (hidden) return

  return (
    <Box
      display={hidden && "none"}
      width={orientation == "horizontal" && "100%"}
      py={orientation == "horizontal" && (spacing || 1)}
      px={orientation == "vertical" && (spacing || 0)}

      mb={headerDivider ? 2 : mb}
      mt={mt}

      sx={{
        '& .MuiDivider-root': { // https://stackoverflow.com/questions/54073151/css-child-selector-in-mui
          borderColor: color
        },
      }}
    >
      <Divider 
        orientation={orientation}
        sx={{
          borderColor: color || colors.grey[400],
          borderBottomWidth: orientation == "horizontal" && (thickness || 2),
          borderRightWidth: orientation == "vertical" && (thickness || 2),
        }}
      />
    </Box> 
  )
}

import React from "react"


export function LoadingDotsIcon() {
  return (
      <div className="dots-loading">
        <div>Loading...</div>
      </div>
  )
}



/* Dots Loading Animation */
// .dots-loading {
//   margin: 0 auto;
//   text-align: center;
// }

// .dots-loading::before,
// .dots-loading::after {
//   content: " ";
// }

// .dots-loading div,
// .dots-loading::before,
// .dots-loading::after {
//   margin: 35px 5px;
//   display: inline-block;
//   width: 16px;
//   height: 16px;
//   border-radius: 50%;
//   background-color: #c4c4c4;
//   opacity: 0;
// }

// .dots-loading::before {
//   -moz-animation: opacitychange 1s ease-in-out infinite;
//   -webkit-animation: opacitychange 1s ease-in-out infinite;
//   -o-animation: opacitychange 1s ease-in-out infinite;
//   animation: opacitychange 1s ease-in-out infinite;
// }

// .dots-loading div {
//   -moz-animation: opacitychange 1s ease-in-out 0.33s infinite;
//   -webkit-animation: opacitychange 1s ease-in-out 0.33s infinite;
//   -o-animation: opacitychange 1s ease-in-out 0.33s infinite;
//   animation: opacitychange 1s ease-in-out 0.33s infinite;
//   -webkit-animation-fill-mode: infinite;
//   animation-fill-mode: infinite;
// }

// .dots-loading::after {
//   -moz-animation: opacitychange 1s ease-in-out 0.66s infinite;
//   -webkit-animation: opacitychange 1s ease-in-out 0.66s infinite;
//   -o-animation: opacitychange 1s ease-in-out 0.66s infinite;
//   animation: opacitychange 1s ease-in-out 0.66s infinite;
//   -webkit-animation-fill-mode: infinite;
//   animation-fill-mode: infinite;
// }

// @keyframes opacitychange {
//   0%,
//   100% {
//     opacity: 0;
//   }

//   60% {
//     opacity: 1;
//   }
// }

// @-webkit-keyframes opacitychange {
//   0%,
//   100% {
//     opacity: 0;
//   }

//   60% {
//     opacity: 1;
//   }
// }
import React from "react"
import { Typography } from "@mui/material"



export function TypographyWarning({
  i = null,
  sx = {},
  children
}) {
  
  

  return (
      <Typography key={children} sx={{color: "warning.main", fontStyle: "italic", fontSize: "0.9rem", opacity: 0.9, mt: i === 0 ? 0.5 : 0, ...sx}}>
        {children}
      </Typography>
  )
}

import React, { useState, useEffect, useContext } from "react"
import { StateContext } from "../../state-management/app/useContext/StateContext"
import { PageContainer } from "../../components/layout/PageContainer"

import { TableClients } from "./components/TableClients"
import { ButtonStyled } from "../../components/utils/ButtonStyled"
import { SCRAPETYPE } from "../../utils/constants"
import { PeopleAltTwoTone } from "@mui/icons-material"
import { LoadingModal } from "../../components/utils/LoadingModal"
import { PageTitle } from "../../components/layout/PageTitle"
import { useScrapeClients } from "../../utils/hooks/webscraping/useScrapeClients"
import { useFetchDocsMany } from "../../utils/hooks/useFetchDocsMany"



export default function Clients({

}) {
  const sortFnClients = (a,b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0)

  //////////////////////////
  // STATE
  //////////////////////////

  // GENERAL
  const appState = useContext(StateContext)

  // DOCSORIGINAL 
  const {docs: docsOriginal, isFetching: isFetchingDocsOriginal } = useFetchDocsMany("clients", { consoleLog: true, sortFn: sortFnClients})
  const [docsFinal, setDocsFinal] = useState([]) // if doing modifications
  
  // DOCSORIGINAL MODIFICATIONS


  // ADDITIONAL
  const [isLoadingTable, setIsLoadingTable] = useState(true)

  const { scrapeClients, scrapedClients, isScrapingClients } = useScrapeClients(SCRAPETYPE.getClientsOfUser, appState, { sortFn: sortFnClients })




  //////////////////////////
  // EFFECTS
  //////////////////////////

  // UPDATE DOCSFINAL WITH DOCSORIGINAL (UNMODIFIED)
  useEffect(() => {
    if (isFetchingDocsOriginal) return


    if (docsOriginal.length > 0 && modificationsNotPresent(docsOriginal)) { // if initial page load (!modificationsPresent) && docs exist, start showing table to user (no modifications present, but they aren't needed until user clicks something)
      setDocsFinal(docsOriginal)
      setIsLoadingTable(false) // start showing table to user
    }
    else if (docsOriginal.length == 0) { // if initial page load && no docs, no modifications to be done (show table -- "no data" version)
      setIsLoadingTable(false)
    }
  }, [docsOriginal])
  

  // UPDATE DOCSFINAL WITH NEWLY SCRAPED DOCS (CLIENTS)
  useEffect(() => {
    if (scrapedClients.length == 0) return

    const newDocs = []
    scrapedClients.forEach(scrapedClient => {
      if (docsFinal.some(doc => doc.ELid == scrapedClient.ELid)) return 
      newDocs.push(scrapedClient)
    })
    if (newDocs.length > 0) {
      const updatedDocs =  [...docsFinal, ...newDocs].sort(sortFnClients)
      setDocsFinal(updatedDocs)
    }
  }, [scrapedClients])



  

  //////////////////////////
  // FUNCTIONS
  //////////////////////////

  function modificationsNotPresent(docs, modification) {
    // modifications should be considered "not present" even if only one doc is missing the modification (could mess up future functions/events for that doc)
    // modifications useEffect (e.g. in Clients.js)
    const modificationsNotPresent = true
    return modificationsNotPresent
  }





  let pageTitleName = "Clients"
  let pageTitle =
    <PageTitle
      icon={<PeopleAltTwoTone sx={{fontSize: "4rem"}} />}
      pageTitleName={pageTitleName}
      contentBelowTitle={
        <ButtonStyled
          onClick={scrapeClients}
          disabled={isScrapingClients}
        >
            Update Client List
        </ButtonStyled>
      }
    />


  return (
    <>
      <PageContainer
        title={pageTitle}
      >
          <TableClients
            docsOriginal={docsFinal}
            setDocsOriginal={setDocsFinal} // setDataOriginal (MRT)

            isLoading={isLoadingTable}
            setIsLoading={setIsLoadingTable}

            initialSettings={{
              tableView: "table",
            }}

            enableTableHeader={false}
          />
      </PageContainer>

      <LoadingModal open={isScrapingClients} />
    </>
  )
}

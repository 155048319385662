import React from 'react'
// import { Register as RegisterComponent } from './components/Register'
import { RegisterContent as RegisterComponent } from './components/RegisterContent'
import { PageContainer } from '../../components/layout/PageContainer'

export default function Register() {


  return (
    <>
      <PageContainer justifyContent="center" toolbarSpacing={false}>
        <RegisterComponent />
      </PageContainer>
    </>
  )
}

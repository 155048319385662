import React, { useState, useEffect } from "react"
import PropTypes  from "prop-types"
import { Box, Stack, Typography } from "@mui/material"
import { formatDate } from "../../../utils/formatDate"
import { AutorenewRounded, PublishedWithChangesRounded } from "@mui/icons-material"
import { IconButtonStyled } from "../../../components/utils/IconButtonStyled"
import { ButtonStyled } from "../../../components/utils/ButtonStyled"


export function InvoicePullAndExportButtons({ invoice=null, handleInvoicePull=()=>{}, isFetchingLocally=false  }) {
  if (invoice === null || Object.keys(invoice).length == 0) return

  const row = {}
  row.original = invoice

  const pulled = row.original.statements > 0 || Array.isArray(row.original.statements) && row.original.statements?.length > 0
  const docType = row.original.docType || ""
  // const isChecked = row.getIsSelected()

  return (
    <Stack alignItems="start">
      <Stack direction="row" alignItems="center">
        {pulled && 
          <>
            {/* {docType == "REVENUE" &&
              <IconButtonStyled
                // color={isChecked ? "secondary" : "primary"}
                onClick={() => handleExportInvoice(row, "REVENUE")}
                toolTip="Export as CSV"
              >
                <FileDownloadRounded />
              </IconButtonStyled>
            }
            {docType == "JIB" &&
              <IconButtonStyled
                // color={isChecked ? "secondary" : "primary"}
                onClick={() => handleExportInvoice(row, "JIB")}
                toolTip="Export as CSV"
              >
                <FileDownloadRounded />
              </IconButtonStyled>
            } */}

            <IconButtonStyled
              onClick={() => handleInvoicePull(row.original)}
              disabled={isFetchingLocally}
              toolTip={row.original.statementsRepulled?.bool ?`Repull (last ${formatDate(new Date(row.original.statementsRepulled.date))})` : `Repull`}
              sx = {{
                opacity: row.original.statementsRepulled?.bool ? 0.6 : 0.6,
              }}
            >
              {row.original.statementsRepulled?.bool ? <PublishedWithChangesRounded /> : <AutorenewRounded />}
            </IconButtonStyled>
          </>
        }

        {!pulled &&
          <ButtonStyled
            onClick={() => handleInvoicePull(row.original)}
            disabled={isFetchingLocally}
            startIcon={<AutorenewRounded />}
            label="Pull"
          />
        }
      </Stack>
    </Stack>
  )
    // <LinkWrapped to={`/invoices/${row.original._id}`}>{row.original.amtOriginal}</LinkWrapped>
}
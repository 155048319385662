import React, { useState, useEffect, useContext } from "react"
import { BrowserRouter, Routes, Route, useParams, Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Box, Stack, Typography } from "@mui/material"

import { StateContext } from "../../state-management/app/useContext/StateContext"
import { DispatchContext } from "../../state-management/app/useContext/DispatchContext"
import { LoadingDotsIcon } from "../../components/utils/LoadingDotsIcon"
import { PageContainer } from "../../components/layout/PageContainer"



export default function Profile ({

}) {
  
  // const searchParams = new URLSearchParams(window.location.search)
  const history = useNavigate()
  const goBack = () => {
    history.goBack()
  }

  // const appState = useContext(StateContext)
  // const appDispatch = useContext(DispatchContext)
  const [isLoading, setIsLoading] = useState(true) 
  const [isFetching, setIsFetching] = useState(false)
  // const [navState, setNavState] = useState(searchParams?.get("query") == "true" ? 1 : 0)
  const [navState, setNavState] = useState(0)


// TEMPLATE (get rid of this!)
  useEffect(() => {
    setIsLoading(false)
  }, [])

  if (isLoading) return <PageContainer title=""><LoadingDotsIcon /></PageContainer>

  return (
    <>
      <PageContainer
        title="Profile"
        navIsHidden={true}
        navItems={["NavItem1", "NavItem2"]} // array of strings/labels 
        navState={navState}
        setNavState={setNavState}
      >
      </PageContainer>
    </>
  )
}

import { format, parseISO } from "date-fns"
// import { zonedTimeToUtc } from "date-fns-tz"

// let today = format(new Date(), 'MM/dd/yyyy')


export function formatDate(date, yearLength = 4) {
  // console.log("DATE", date)

  if (!date) {
    return ""
  }

  try {
    if (yearLength == 2) {
      return format(new Date(date), 'M/d/yy')
    }
    else {
      return format(new Date(date), 'M/d/yyyy')
    }
  }
  catch {
    return date
  }
}

// "Jun 20"
export function formatDateFromInvoice(date) {
  if (!date) {
    return ""
  }

  let month = date.split(" ").at(0)
  let year = date.split(" ").at(1)

  const months = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December'
  }

  const monthNumber = 1 + Object.values(months).findIndex(mo => mo.slice(0,3) == month.slice(0,3))

  if (year.length == 2) {
    year = `20${year}`
  }

  return formatDate(`${monthNumber}/1/${year}`)
}
import React from "react";

const XrefModalTable = ({ xrefErrors = [] }) => {
	return (
		<div className="modal-content">
			<h2>Well Index Cross Reference Validation</h2>
			{/* show xref errors table */}
			{console.log("xrefErrors in modal", xrefErrors)}
			<table>
				<thead>
					<tr>
						<th>Doc Type</th>
						<th>Docnum</th>
						<th>Operator</th>
						<th>Amount</th>
						<th>Xref Type</th>
						<th>Discrepancy</th>
					</tr>
				</thead>
				<tbody>
					{xrefErrors.map((error, i) => (
						<tr key={i}>
							<td>{error.doctype}</td>
							<td>
								{!error.invoiceurl ? (
									error.docnum
								) : (
									<a href={error.invoiceurl.replace("//www.", "//app.")} target="_blank" rel="noopener noreferrer">{error.docnum}</a>
								)}
							</td>
							<td>{error.operator}</td>
							<td>
								{!error.statementurl ? (
									error.amount
								) : (
									<a href={error.statementurl.replace("//www.", "//app.")} target="_blank" rel="noopener noreferrer">{error.amount}</a>
								)}
							</td>
							<td>{error.xrefType}</td>
							<td>{error.discrepancy}</td>
						</tr>
					))}
				</tbody>
			</table>

			{/* <button onClick={() => handleXrefValidation(false)}>Revalidate</button> */}
			{/* shouldn't need to remove errors because handleXrefValidation initializes xrefErrors to [] */}
			{/* <button onClick={() => setXrefErrors([])}>Clear Errors</button> */}
		</div>
	);
};

export default XrefModalTable;

import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

import { useImmerReducer } from 'use-immer'

import { StateContext } from '../../../state-management/app/useContext/StateContext';
import { DispatchContext } from '../../../state-management/app/useContext/DispatchContext';

import { Avatar } from '@mui/material';

import { ButtonStyled } from '../../../components/utils/ButtonStyled';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { IconButton, InputAdornment, Stack } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LinkWrapped } from '../../../components/utils/LinkWrapped';

// import { Logo } from '../../../components/utility/Logo';
// import landingPageMain from "../../../../staticmaybe/images/illustrations/landingpage-main.svg"
const logoSvg = "https://res.cloudinary.com/dxnehm70c/image/upload/v1661308010/MB_logo_bluem_ikg6lp.png"
const Logo = ""


export function SignInNew({
  setSignInRegister = () => {},
  buttonSx,
}) {

  const logoSvg = "https://res.cloudinary.com/dxnehm70c/image/upload/v1661308010/MB_logo_bluem_ikg6lp.png"
  const Logo = ""

  // const searchParams = new URLSearchParams(window.location.search)
  const history = useNavigate()
  const goBack = () => {
    history.goBack()
  }

  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)
  const [isLoading, setIsLoading] = useState(false) 
  const [isFetching, setIsFetching] = useState(false)

  //////////////////
  //////////////////
  // OLD LOGIN PAGE

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [loginRegister, setLoginRegister] = useState(true)

  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const initialState = {
    username: {
      value: "",
      hasErrors: false,
      message: "",
      isUnique: false,
      checkCount: 0
    },
    email: {
      value: "",
      hasErrors: false,
      message: "",
      isUnique: false,
      checkCount: 0
    },
    password: {
      value: "",
      hasErrors: false,
      message: "",
    },
    submitCount: 0
  }
  function ourReducer(draft, action) {
    switch (action.type) {
      case "usernameImmediately":
        draft.username.hasErrors = false // since running after every field value change, want a "clean slate" each time (assume no errors)
        draft.username.value = action.value
        // if (draft.username.value.length > 30) {
        //   draft.username.hasErrors = true
        //   draft.username.message = "Username cannot exceed 30 characters."
        // }
        if (draft.username.value && !/^([a-zA-Z0-9]+)$/.test(draft.username.value)) {
          draft.username.hasErrors = true
          draft.username.message = "Usernames only contain letters and numbers."
        }
        return
      // case "usernameAfterDelay":
      //   if (draft.username.value.length < 3) {
      //     draft.username.hasErrors = true
      //     draft.username.message = "Username must be at least 3 characters."
      //   }
      //   if (!draft.username.hasErrors && !action.noRequest) {
      //     draft.username.checkCount++
      //   }
      //   return
      // case "usernameUniqueResults":
      //   if (action.value) {
      //     draft.username.hasErrors = true
      //     draft.username.isUnique = false
      //     draft.username.message = "That username is already taken."
      //   } else {
      //     draft.username.isUnique = true
      //   }
      //   return

      case "emailImmediately":
        draft.email.hasErrors = false
        draft.email.value = action.value
        return
      case "emailAfterDelay":
        if (!/^\S+@\S+$/.test(draft.email.value)) {
          draft.email.hasErrors = true
          draft.email.message = "You must provide a valid email address."
        }
        if (!draft.email.hasErrors && !action.noRequest) {
         draft.email.checkCount++ 
        }
        return
      case "emailUniqueResults":
        if (action.value) {
          draft.email.hasErrors = true
          draft.email.isUnique = false
          draft.email.message = "That email is already being used."
        } else {
          draft.email.isUnique = true
        }
        return

      case "passwordImmediately":
        draft.password.hasErrors = false
        draft.password.value = action.value
        if (draft.password.value.length > 50) {
          draft.password.hasErrors = true
          draft.password.message = "Password cannot exceed 50 characters."
        }
        return
      case "passwordAfterDelay":
        if (draft.password.value.length < 6) {
          draft.password.hasErrors = true
          draft.password.message = "Password must be at least 6 characters."
        }
        return

      case "submitForm":
        if (!draft.username.hasErrors && draft.username.isUnique && !draft.email.hasErrors && draft.email.isUnique && !draft.password.hasErrors) {
          draft.submitCount++
        }
        return
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState)

  const inputRef = useRef(null);


  //////////////////
  //// USEEFFECTS
  //////////////////

  // useEffect(() => {
  //   if (state.username.value) {
  //     const delay = setTimeout(() => dispatch({type: "usernameAfterDelay"}), 800) // web browser
  //     return () => clearTimeout(delay) // clean-up
  //   }
  // }, [state.username.value])

  // useEffect(() => {
  //   if (state.username.checkCount) {
  //     const ourRequest = axios.CancelToken.source()
  //     async function fetchResults() {
  //       try {
  //         const response = await axios.post('/doesUsernameExist', {username: state.username.value}, {cancelToken: ourRequest.token})
  //         dispatch({type: "usernameUniqueResults", value: response.data})
  //       } catch(e) {
  //         console.log("There was a problem or the request was cancelled.")
  //       }
  //     }
  //     fetchResults()
  //     return () => ourRequest.cancel()
  //   }
  // }, [state.username.checkCount])

  useEffect(() => {
    if (state.email.value) {
      const delay = setTimeout(() => dispatch({type: "emailAfterDelay"}), 800) // web browser
      return () => clearTimeout(delay) // clean-up
    }
  }, [state.email.value])

  useEffect(() => {
    if (state.email.checkCount) {
      const ourRequest = axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await axios.post('/doesEmailExist', {email: state.email.value}, {cancelToken: ourRequest.token})
          dispatch({type: "emailUniqueResults", value: response.data})
        } catch(e) {
          console.log("There was a problem or the request was cancelled.")
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    }
  }, [state.email.checkCount])

  useEffect(() => {
    if (state.password.value) {
      const delay = setTimeout(() => dispatch({type: "passwordAfterDelay"}), 800) // web browser
      return () => clearTimeout(delay) // clean-up
    }
  }, [state.password.value])

  useEffect(() => {
    if (state.submitCount) {
      const ourRequest = axios.CancelToken.source()
      async function fetchResults() {
        try {
          const response = await axios.post('/register', {username: state.username.value, email: state.email.value, password: state.password.value}, {cancelToken: ourRequest.token})
          appDispatch({type: "login", data: response.data})
          appDispatch({type: "flashMessage", value: "Congrats! Welcome to your new account."})
          // history.push('/')
        } catch(e) {
          console.log("There was a problem or the request was cancelled.")
          appDispatch({type: "flashMessage", value: "Sorry, something went wrong. Please try again."})
        }
      }
      fetchResults()
      return () => ourRequest.cancel()
    }
  }, [state.submitCount])

  useEffect(() => {
    setPasswordVisible(false)
  }, [loginRegister])



  //////////////////
  //// FUNCTIONS
  //////////////////
  async function handleLogin(e) {
    e.preventDefault()
    setIsLoggingIn(true)
    try {
      // console.log(username, password)
      const response = await axios.post('/login', { username, password })
      // console.log("LOGIN1", response)
      // console.log("LOGIN2", response.data)
      if (response.data) {

        // window.location.reload(false);
        // change navbar (based on now being logged in)
        appDispatch({type: "login", data: response.data}) // via context
        appDispatch({type: "flashMessage", value: "You have successfully logged in."})
        history('/')
      } else {
        console.log("Incorrect username / password.")
        appDispatch({type: "flashMessage", value: "Invalid username/password."})
      }
    } catch(e) {
      console.log(e.response?.data || e)
    } finally {
      setIsLoggingIn(false)
    }
  }



  // NEW (TEMPLATE)
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  //   console.log({
  //     email: data.get('email'),
  //     password: data.get('password'),
  //   });
  // };





  return (
    <>
        <Box
          sx={{
            p: 3,
            px: 5,
            backgroundColor: "background.default",
            borderRadius: 4
          }}
        >
            <Box
              sx={{
                height: "100%",
                margin: "auto",
                // my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',

                mx: 'auto',
                maxWidth: "500px"
              }}
            >
                <Typography component="h1" variant="h5">
                  Sign In
                </Typography>

                {/* <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}> */}
                <Box component="form" noValidate onSubmit={e => handleLogin(e)} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    autoFocus

                    // onChange={e => setUsername(e.target.value)}
                    // value={username}

                    error={state.username.hasErrors}
                    helperText={state.username.hasErrors && state.username.message}
                    onChange={e => {setUsername(e.target.value); dispatch({type: "usernameImmediately", value: e.target.value})}} value={username}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={!showPassword ? "password" : "text"}
                    id="password"
                    autoComplete="current-password"
                    
                    ref={inputRef}
                    onChange={e => setPassword(e.target.value)}
                    value={password} 

                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{mr: 0.5}}>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                      </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={isLoggingIn}
                    sx={{...buttonSx, mt: 3, mb: 2 }}
                    // onClick={e => handleLogin(e)}
                  >
                    {isLoggingIn ? "Signing in..." : "Sign in"}
                  </Button>
                  

                  {/* BOTTOM LINKS */}
                  <Grid container>
                    {/* <Grid item xs>
                      <LinkWrapped to="/forgotpassword">
                        Forgot password?
                      </LinkWrapped>
                    </Grid> */}
                    <Grid item>
                      {/* <LinkWrapped to="/register"> */}
                      {/* <LinkWrapped onClick={() => setSignInRegister("register")}> */}
                      {/* <ButtonStyled variant="text" onClick={() => setSignInRegister("register")}>
                        Don't have an account? Sign Up
                      </ButtonStyled> */}
                      {/* </LinkWrapped> */}
                    </Grid>
                  </Grid>

                </Box>
            </Box>
        </Box>
    </>
  );
}



function LeftSideContent() {
  return (
    <Grid
      item
      xs={false}
      sm={4}
      md={7}
      sx={{
        display: {
          xs: "none",
          md: "block"
        }
        // backgroundImage: 'url(https://source.unsplash.com/random)',
        // backgroundImage: landingPageMain,
        // backgroundRepeat: 'no-repeat',
        // backgroundColor: (t) =>
          // t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
      }}
    >
      {/* <Logo label={false} width="40%" sx={{height: 1, backgroundColor: "hsla(211, 100%, 90%, 1)"}} /> */}
    </Grid>
  )
}
import { useEffect, useState } from 'react'
import axios from 'axios'
import { handleErrorCatching } from '../handleErrorCatching'





export function useFetchClinicDocs(url, options) {
  const {
    sortFn = null
  } = options

  const [docs, setDocs] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    if (isFetching) {
      const controller = new AbortController()
      async function fetchDocs() {
        try {
          console.log("fetching (clinicalTrial)...", url)
          const response = await axios.get(url, {signal: controller.signal})
          console.log('response.data (clinicalTrial): ', response.data)
          let fetchedDocs = []
          fetchedDocs = response.data.StudyFieldsResponse?.StudyFields || []
          console.log('fetchedDocs (clinicalTrial): ', fetchedDocs)
          if (fetchedDocs.length >= 0) {
            // let fetchedDocs = response.data
            // if (sortFn) {
            //   fetchedDocs = response.data.sort(sortFn)
            // }
            setDocs(fetchedDocs) // may need to watch 'scrapedClients' on parent component to update UI (e.g. useEffect, update docsFinal)
          }
        } catch(e) {
          handleErrorCatching(e, "clinicalTrials")
        }
        finally {
          setIsFetching(false)
        }
      }
      fetchDocs()
      return () => {
        controller.abort()
      }
    }
  }, [])

  return { docs, isFetching }
}
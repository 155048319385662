import axios from "axios"


///////////////////
// HELPER FUNCTIONS
///////////////////


///////////////////
// MAIN
///////////////////

// SINGLE
///////////////////

// CREATE
export const createDoc = async (doc, collection, controller, consoleLog = false) => {
  const response = await axios.post(`/${collection}`, {doc: doc}, {signal: controller.signal})
  if (consoleLog) {
    console.log(`createDoc (${collection}): `, response.data)
  }
  return response.data
}

// READ (using "_id")
export const getDoc = async (id, collection, controller, consoleLog = false) => {
  const response = await axios.get(`/${collection}/${id}`, {signal: controller.signal})
  if (consoleLog) {
    console.log(`getDoc (${collection}): `, response.data)
  }
  return response.data
}

// UPDATE (using "_id")
export const updateDoc = async (doc, collection, controller, consoleLog = false) => {
  const response = await axios.put(`/${collection}/${doc.id}`, {doc: doc}, {signal: controller.signal})
  if (consoleLog) {
    console.log(`updateDoc (${collection}): `, response)
  }
  
  if (response.data !== undefined) {
    return response.data
  }
  else {
    return response
  }
}

// DELETE (using "_id")
export const deleteDoc = async (id, collection, controller, consoleLog = false) => {
  const response = await axios.delete(`/${collection}/${id}`, {signal: controller.signal})
  if (consoleLog) {
    console.log(`deleteDoc (${collection}): `, response.data)
  }
  return response.data
}



export const getDocById = async (collection, idType = "_id", id, controller, consoleLog = false) => {
  const response = await axios.get(`/${collection}/${id}/${idType}`, {signal: controller.signal})
  if (consoleLog) {
    console.log(`getDoc (${collection}): `, response.data)
  }
  return response.data // doc
}



// MANY
///////////////////

// GET MANY
export const getDocs = async (collection, controller, consoleLog = false) => {
  const response = await axios.get(`/${collection}`, {signal: controller.signal})
  if (consoleLog) {
    console.log(`getDocs (${collection}): `, response.data)
  }
  return response.data
}


export const getDocsById = async (collection, idType = "_id", id, controller, project=false, consoleLog = false) => {

  // const createQueryString = (data) => {
  //   return Object.keys(data).map(key => {
  //     let val = data[key]
  //     if (val !== null && typeof val === 'object') val = createQueryString(val)
  //     return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}`
  //   }).join('&')
  // }

  // // Create Query Strings
  // let projectQueryStr = ""
  // if (project) {
  //   // projectQueryStr = new URLSearchParams(project).toString()  // convert to query string  (https://stackoverflow.com/questions/1714786/query-string-encoding-of-a-javascript-object)
  //   projectQueryStr = createQueryString({project: project})  // convert to query string  (https://stackoverflow.com/questions/1714786/query-string-encoding-of-a-javascript-object)
  //   console.log(projectQueryStr)
  // }


  // Combine Query Strings
  let queryStr = "?"
  if (project) {
    queryStr = queryStr + "project=true"
  }
  if (queryStr.length == 1) {
    queryStr = ""
  }

  // Request
  const url = `/${collection}/${id}/${idType}/many${queryStr}`
  // /invoices/74899/clientELid/many?project=true
  // /invoices/71484/clientELid/many?project=true
  console.log(url)
  const response = await axios.get(url, {signal: controller.signal})
  if (consoleLog) {
    console.log(`getDocs (${collection}): `, response.data)
  }
  return response.data
}
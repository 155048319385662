import axios from "axios";
import { getStatementsByInvoiceId } from "./useFetchStatementsByInvoiceId";

export async function getInvoiceById(id, controller = undefined) {
  try {
    const response = await axios.get(`/invoices/${id}`, {
      signal: controller?.signal,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
}

export async function getInvoiceWithStatements(
  invoiceId_,
  controller = undefined
) {
  try {
    // Fetch the invoice by its ID
    const invoice = await getInvoiceById(invoiceId_, controller);
    console.log("fetched invoice", invoice);

    // Fetch the statements by the invoice ID
    const statements = await getStatementsByInvoiceId(invoice._id, controller);
    console.log("fetched statements", statements);

    // Combine invoice with its statements
    const invoiceWithStatements = { ...invoice, statements };

    return invoiceWithStatements;
  } catch (e) {
    throw e; // Or handle the error as needed
  }
}

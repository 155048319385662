import React, { useState, useEffect, useMemo, useContext } from "react"
import { Stack } from "@mui/material"

import { StateContext } from "../../../state-management/app/useContext/StateContext"

import { LinkWrapped } from "../../../components/utils/LinkWrapped"
import { MRT } from "../../../components/utils/MRT"
import { Pod } from "../../../components/utils/Pod"
import { ButtonStyled } from "../../../components/utils/ButtonStyled"
import { SCRAPETYPE } from "../../../utils/constants"
import { getDocs, updateDoc } from "../../../services"
import { handleErrorCatching } from "../../../utils/handleErrorCatching"
import { MRT_NoData } from "../../../components/utils/MRT_NoData"
import { LoadingModal } from "../../../components/utils/LoadingModal"
import { useScrapeInvoices } from "../../../utils/hooks/webscraping/useScrapeInvoices"
import { getDocsById } from "../../../services/axios"




export function TableClients({
  docsOriginal,
  setDocsOriginal,

  isLoading, // isLoadingTable
  setIsLoading,

  tablePodViewVisible = true,
  initialSettings = {
    tableView: "pod"
  },
  
  enableTableHeader = true,

  sx = {},
}) {
  const DOC_SINGULAR = "client"
  const DOCS_PLURAL = "clients"

  //////////////////////////
  // STATE
  //////////////////////////

  // GENERAL
  const appState = useContext(StateContext)

  // TABLE
  const defaultColumnVisibility = {
    createdDate: false,
    updatedDate: false,
  }
  const [columnVisibility, setColumnVisibility] = useState(defaultColumnVisibility)

  // ADDITIONAL
  const [client, setClient] = useState({})
  const { scrapeInvoices, scrapedInvoices, isScrapingInvoices, initialFetchSuccess, setInitialFetchSuccess } = useScrapeInvoices(SCRAPETYPE.getInvoicesOfClients, undefined, appState, { initialFetch: true})
  
  const [clientToUpdate, setClientToUpdate] = useState({})

  
  //////////////////////////
  // EFFECTS
  //////////////////////////

  // Update Client On DB (initialInvonicesFetched = true); update docsOriginal/docsFinal (aka clients)
  useEffect(() => {
    if (initialFetchSuccess) {
      const controller = new AbortController()
      async function getDocs() {
        try {
          let updatedClient = {...client, initialInvoicesFetched: true}
          const responseData = await updateDoc(updatedClient, "clients", controller, true)

          // update state
          updatedClient = responseData
          setDocsOriginal(docsOriginal.map(doc => doc.ELid == updatedClient.ELid ? updatedClient : doc))
        } catch(e) {
          handleErrorCatching(e, "retrievedDocs, scrapeInvoices")
        } finally {
          setClient({})
          setInitialFetchSuccess(0)
        }
      }
      getDocs()
      return () => { controller.abort() }
    }
  }, [initialFetchSuccess])


  // Mark Client as "Initial Invoices Fetched"
  useEffect(() => {
    console.log("clientToUpdate: ", clientToUpdate)
    if (clientToUpdate._id) {
      const controller = new AbortController()
      async function updateClient() {
        try {
          let updatedClient = {...clientToUpdate, initialInvoicesFetched: true}
          const responseData = await updateDoc(updatedClient, "clients", controller, true)

          // update state
          updatedClient = responseData
          setDocsOriginal(docsOriginal.map(doc => doc.ELid == updatedClient.ELid ? updatedClient : doc))
        } catch(e) {
          handleErrorCatching(e, "retrievedDocs, scrapeInvoices")
        } finally {
          setClientToUpdate({})
          setInitialFetchSuccess(0)
        }
      }
      updateClient()
      return () => { controller.abort() }
    }
  }, [clientToUpdate])



  //////////////////////////
  // FUNCTIONS
  //////////////////////////

  function handleScrapeInvoices(clientFromRow) {
    setClient(clientFromRow) // for useEffect (update client on DB)
    scrapeInvoices({client: clientFromRow, requestType: "initialFetch"})
  }

  function handleInitialFetchMayAlreadyBeDone(clientFromRow) {
    setClientToUpdate(clientFromRow)
  }
  


  //////////////////////////
  // TABLE
  //////////////////////////
  const columns = useMemo(
    () => [
      {
        accessorKey: 'ELid',
        header: 'ELid',
        size: 20
      },
      {
        accessorKey: 'name',
        header: 'name',
      },
      {
        accessorKey: 'initialInvoicesFetched',
        header: 'Invoices Status',
        Cell: ({ row }) =>
          !row.original.initialInvoicesFetched ?
            <Stack direction="row" alignItems="center" gap={2}>
              <ButtonStyled
                onClick={() => handleScrapeInvoices(row.original)}
                disabled={isScrapingInvoices}
              >
                Get Initial Invoices
              </ButtonStyled>
              <LinkWrapped to={`/clients/${row.original.ELid}`}>Go to Client</LinkWrapped>
              <ButtonStyled
                onClick={() => handleInitialFetchMayAlreadyBeDone(row.original)}
                disabled={isScrapingInvoices}
              >
                Mark Client as "Initial Invoices Fetched"
              </ButtonStyled>
            </Stack>
          :
            <LinkWrapped to={`/clients/${row.original.ELid}`}>Go to Client</LinkWrapped>
      }
    ],
    [isScrapingInvoices, docsOriginal],
  );


  let globalButtons = 
    <>
    </>

  return (
    <>
      <Pod 
        elevation={0}
        sx={{
          minWidth: 320, minHeight: 100, maxHeight: 600, width: 1, 
          borderRadius: 0,
          ...sx
        }}
      >
        {docsOriginal.length == 0 && !isLoading ?
          <MRT_NoData docsPlural={DOCS_PLURAL} />
        :
          <MRT
          // tableType="invoices"
            data={docsOriginal}
            columns={columns}
            dataOriginal={docsOriginal}
            setDataOriginal={setDocsOriginal}

            isLoading={isLoading}
            setIsLoading={setIsLoading}
            estimatedNumOfRows={5}

            // tableView={tableView}
            // setTableView={setTableView}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}

            globalButtons={globalButtons}

            enableTopToolbar={enableTableHeader ? true : false}
            enableColumnResizing={enableTableHeader ? true : false}
            enableTableHead={enableTableHeader ? true : false}
            enableGlobalFilter={enableTableHeader ? true : false}
            enableFilters={enableTableHeader ? true : false}
            enableHiding={enableTableHeader ? true : false}
            enableDensityToggle={enableTableHeader ? true : false}
            enableFullScreenToggle={enableTableHeader ? true : false}
            enableToolbarInternalActions={enableTableHeader ? true : false}
          />
        }
      </Pod>


      <LoadingModal open={isScrapingInvoices} />
    </>
  )
}

import React, { useState, useEffect, useMemo, useContext } from "react"
import { Button, Stack, Tooltip, Typography } from "@mui/material"
import { TextSnippetTwoTone } from "@mui/icons-material"
import { useNavigate, createSearchParams, useParams, useLocation } from "react-router-dom"

// import { format, parseISO } from "date-fns"
// import { zonedTimeToUtc } from "date-fns-tz"
// import { ModalMoreInfo } from "../../notes/components/ModalMoreInfo"
// import { ModalMoreInfoColumnLinkedPage } from "../../notes/components/ModalMoreInfoColumnLinkedPage"

import { LinkWrapped } from "../../../components/utils/LinkWrapped"


import { StateContext } from "../../../state-management/app/useContext/StateContext"
import { DispatchContext } from "../../../state-management/app/useContext/DispatchContext"
// import { ButtonMoreInfoColumnHeader } from "../../../components/utility/ButtonMoreInfoColumnHeader"
import { MRT } from "../../../components/utils/MRT"
import { Pod } from "../../../components/utils/Pod"
// import { ChipNavItem } from "../../../components/utility/ChipNavItem"
// import { TabNavItem } from "../../../components/utility/TabNavItem"



export function TableGeneric({
  isLoading = false,
  setIsLoading,
  isFetching,
  setIsFetching,

  tablePodViewVisible = true,
  initialSettings = {
    tableView: "table"
  },
  
  docsOriginal,
  setDocsOriginal,
  
  // ONLY NEEDED IF MAIN.JS
  mainPage = true,
  navState = {}, // Notes.js
  navItems = [], // Notes.js
  setNavItems = () => {}, // Notes.js
  
  columns, 

  sx = {},
  maxHeight = "",
}) {

  const docSingular = "item"
  const docsPlural = "items"

  const history = useNavigate()
  // let location = useLocation();
  // const searchParams = new URLSearchParams(window.location.search)
  // const params = useParams()
  // console.log("params: ", params)
  // const id = params.id
  
  // const [isLoading, setIsLoading] = useState(false) 
  // const [isFetching, setIsFetching] = useState(false)
  

  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)

  const [open, setOpen] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState({})


  // const [docs, setDocs] = useState(docsOriginal)


  // const [tableView, setTableView] = useState(tablePodViewVisible ? appState.tablePodView.notes : initialSettings.tableView)
  const [tableView, setTableView] = useState(initialSettings.tableView)
  const defaultColumnVisibility = {
    // createdDate: false,
    // updatedDate: false,
    // type: false,
    // relatedIds: false,
    // value: false,
  }
  const [columnVisibility, setColumnVisibility] = useState(defaultColumnVisibility)


  // UPDATE TABLE VIEW (VIA tableView)
  useEffect(() => {
    if (tableView == "table") {
      setColumnVisibility({
        // createdDate: false,
        // type: false,
        // note: false
      })
    }
    else {
      setColumnVisibility(defaultColumnVisibility)
    }
  }, [tableView])




  function handleClick(doc, type="document") {
    setSelectedDoc(doc)

    if (type == "document") {
      setOpen(true)
    }
    else if (type == "note") {
      // setIsNoteOpen(true)
    }
    else if (type == "createNote") {
      // setIsCreateNoteOpen(true)
    }
  }



  // const columns = useMemo(
  //   () => [
  //     // {
  //     //   accessorKey: 'createdDate',
  //     //   header: 'Created',
  //     //   size: 40,
  //     //   enableColumnFilter: false,
  //     //   Cell: ({ row }) => row.original.createdDate ? <Box component="span" sx={{color: row.original.status == "inactive" ? "#00000080" : "inherit", fontStyle: row.original.status == "inactive" ? "italic" : "normal"}}>{format(new Date(row.original.createdDate), 'MM/dd/yyyy')} {zonedTimeToUtc(row.original.updatedDate, 'America/Chicago').toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}</Box> : ""        // Sort by date (or even string ?)
  //     // },
  //     // {
  //     //   accessorKey: 'updatedDate',
  //     //   header: 'Last Updated',
  //     //   size: 40,
  //     //   enableColumnFilter: false,
  //     //   // Cell: ({ row }) => row.original.updatedDate ? <Box component="span" sx={{color: row.original.status == "inactive" ? "#00000080" : "inherit", fontStyle: row.original.status == "inactive" ? "italic" : "normal"}}>{format(new Date(row.original.updatedDate), 'MM/dd/yyyy')} {format(parseISO(row.original.updatedDate.slice(0, -5)), 'h:mm a')}</Box> : ""
  //     //   Cell: ({ row }) => row.original.updatedDate ? <Box component="span" sx={{color: row.original.status == "inactive" ? "#00000080" : "inherit", fontStyle: row.original.status == "inactive" ? "italic" : "normal"}}>{format(new Date(row.original.updatedDate), 'MM/dd/yyyy')} {zonedTimeToUtc(row.original.updatedDate, 'America/Chicago').toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}</Box> : ""
  //     //   // Cell: ({ row }) => row.original.updatedDate ? format(new Date(row.original.updatedDate), 'MM/dd/yyyy') : ""
  //     //   // Sort by date (or even string ?)
  //     // },
  //     // {
  //     //   accessorKey: '_id',
  //     //   header: 'ID',
  //     //   size: 20
  //     // },
  //     {
  //       accessorKey: 'ELId',
  //       header: 'ELId',
  //       size: 20
  //     },
  //     {
  //       accessorKey: 'name',
  //       header: 'name',
  //       size: 20
  //     },
  //     {
  //       accessorKey: 'results',
  //       header: 'Invoices',
  //       size: 20,
  //       Cell: ({ row }) => <LinkWrapped to={`/clients/${row.original._id}`}>{row.original.results?.length}</LinkWrapped>
  //     },
  //   ],
  //   [navItems],
  // );


  let globalButtons = 
    <>
    </>

  
  {/* CONSIDER PUTTING IN GLOBALBUTTONS INSTEAD */}
  let tableHeader =
    <>
    </>


  let podChip = 
    <></>
    // <Tooltip title="Add New Note">
    //   <IconButton color="primary" size="small" sx={{opacity: 0.6, mt: "-4px!important"}}
    //     onClick={() => {setSelectedDoc({_id: "new", type: "general", createdDate: new Date(), updatedDate: new Date()}); setIsNoteOpen(true)}}
    //   >
    //     <AddCircleOutlineRounded />
    //   </IconButton>
    // </Tooltip>

  return (
    <>
      <Pod 
        title={docsOriginal.length ? "" : docsPlural} sx={{minWidth: 320, minHeight: 100, maxHeight: maxHeight ? 400 : "auto", width: 1, ...sx}}
        // title={docsOriginal.length ? "" : docsPlural} sx={{minWidth: 320, minHeight: 100, maxHeight: 600, width: 1, ...sx}}
        chip={docsOriginal.length ? "" : podChip}
        // sx={{pt: "0.25rem", ...sx}}

        // title={!mainPage && tableHeader}
        // chip={<IconButton color="primary" size="small" onClick={() => {setSelectedDoc({...initialInterest}); setOpenOwnerInterestModal(true); console.log("pressed button")}} sx={{mt: "-4px!important"}}><AddRounded /></IconButton>}
      >
        {docsOriginal.length == 0 ?
            <>
              <Stack justifyContent="center" alignItems="center" gap={1}>
                    <TextSnippetTwoTone
                      onClick={() => {setSelectedDoc({_id: "new", type: "general", createdDate: new Date(), updatedDate: new Date()}); setOpen(true)}}
                      sx={{
                        color: "#00000008",
                        // opacity: 0.6,
                        // fontSize: "80px",
                        // cursor: "pointer",
                        width: "50%!important",
                        height: "50%!important",
                        maxWidth: 120,
                        maxHeight: 120,
                        "&:hover": {
                          // backgroundColor: "#00000005",
                          // color: "#00000030",
                        }
                      }}
                    />
                  {/* </Tooltip> */}
                  <Typography variant="body2" sx={{opacity: 0.2, mb: "8px"}}>
                    No {docsPlural}
                  </Typography>
                {/* </IconButton> */}
              </Stack>
            </>
          :
            <MRT
              // data={[docExample, docExample2]}
              // tableType="notes"
              // filterDocs={filterDocs}
              // filterDocsStatus={filterDocsStatus}

              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              tableView={tableView}
              setTableView={setTableView}

              globalButtons={globalButtons}

              // data={[...notes].sort((a,b) => new Date(b.updatedDate) - new Date(a.updatedDate))}
              // data={notes.sort((a,b) => new Date(b.updatedDate) - new Date(a.updatedDate))}
              data={docsOriginal}
              columns={columns}
              
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              isRefetching={isFetching}
              setIsRefetching={setIsFetching}

              rowAction={1}

              // open={open} // VSDocument
              // setOpen={setOpen} // VSDocument
              // isNoteOpen={isNoteOpen} // VSNote
              // setIsNoteOpen={setIsNoteOpen} // VSNote
              // isCreateDocumentOpen={isCreateDocumentOpen} // Create Document
              // setIsCreateDocumentOpen={setIsCreateDocumentOpen} // Create Document
              // isCreateNoteOpen={isCreateNoteOpen} // Create Note
              // setIsCreateNoteOpen={setIsCreateNoteOpen} // Create Note

              selectedDoc={selectedDoc}
              setSelectedDoc={setSelectedDoc}

              handleClick={handleClick}

              dataOriginal={docsOriginal}
              setDataOriginal={setDocsOriginal}

              // initialShowGlobalFilter={true}
              enableColumnResizing={false}

              enableTableHead={mainPage ? true : false}
              // enableTopToolbar={mainPage ? true : false}
              // maxHeight={maxHeight}

              enableGlobalFilter={mainPage ? true : false}
              enableFilters={mainPage ? true : false}
              enableHiding={mainPage ? true : false}
              enableDensityToggle={mainPage ? true : false}
              enableFullScreenToggle={mainPage ? true : false}
              enableToolbarInternalActions={mainPage ? true : false}

            />
        }

        {/* <ViewSingleNote doc={selectedDoc} open={isNoteOpen} setOpen={setIsNoteOpen} notes={docsOriginal} setDocs={setDocsOriginal} />
        <ModalMoreInfo open={openModalMoreInfo} setOpen={setOpenModalMoreInfo} />
        <ModalMoreInfoColumnLinkedPage open={openModalMoreInfoColumnLinkedPage} setOpen={setOpenModalMoreInfoColumnLinkedPage} /> */}
      </Pod>


    </>
  )
}

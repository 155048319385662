import AWS from 'aws-sdk'; // Import entire SDK (optional)
// import AWS from 'aws-sdk/global'; // Import global AWS namespace (recommended)
import S3 from 'aws-sdk/clients/s3'; // Import only the S3 client
import { da } from 'date-fns/locale';

export const s3Bucket = process.env.REACT_APP_AWS_S3_BUCKET;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const s3 = new AWS.S3({
  params: { Bucket: s3Bucket },
});


// generic crud functions for S3
export const getFileFromS3 = async (fileName) => {
  const params = {
    Bucket: s3Bucket,
    Key: fileName,
  };

  try {
    const data = await s3.getObject(params).promise();
    // NOTE: data.Body is a Buffer
    // NOTE: you will need to ingest a blob from the data.Body
    return data;
  } catch (error) {
    console.error('Error fetching the file from S3:', error);
    return null;
  }
}

export const uploadFileToS3 = async (file, fileName) => {
  const params = {
    Bucket: s3Bucket,
    Key: fileName,
    Body: file,
  };

  try {
    const uploadResult = await s3.upload(params).promise();
    console.log('File uploaded successfully');
    return uploadResult;
  } catch (error) {
    console.error('Error uploading the file to S3:', error);
    return null;
  }
}

// specific crud functions for S3
export const getWellIndexFromS3 = async () => {
  const fileName = `well-index/well-index.xlsx`;
  return getFileFromS3(fileName);
}

export const uploadWellIndexToS3 = async (file) => {
  const fileName = `well-index/well-index.xlsx`;
  return uploadFileToS3(file, fileName);
}

export const getCustomStatementsFromS3 = async (clientELid, year, month) => {
  const fileName = `custom-statements/${clientELid}/${year}-${month}.xlsx`;
  return getFileFromS3(fileName);
}

export const uploadCustomStatementsToS3 = async (file, clientELid, year, month) => {
  const fileName = `custom-statements/${clientELid}/${year}-${month}.xlsx`;
  return uploadFileToS3(file, fileName);
}

export const getCustomStatementsTemplateFromS3 = async () => {
  const fileName = `templates/CustomStatements-Client-Year-Month.xlsx`;
  return getFileFromS3(fileName);
}
import { createTheme, colors } from "@mui/material";
import { COLORS } from "./utils/constants";

// blue: 407BFF

// STORYLINK ATTRIBUTION (ILLUSTRATIONS)
// <a href="https://storyset.com/people">People illustrations by Storyset</a>

// WORK IN PROGRESS (work-in-progress.svg)
// https://storyset.com/illustration/working/rafiki/animate?share=49488

// MODAL (Add MyWell) (floating-files.svg)
// https://storyset.com/illustration/duplicate/pana/animate?share=54030

// MODAL (What are MyWells?) (reading-website.svg)
// https://storyset.com/illustration/information-tab/bro/animate?share=54428


// MODALS: BORDER-TOP (gray line before CardButtons)
// border-top: #dadce0 1px solid
// borderTop: "#dadce0 1px solid"

const scaleFactor = 0.3
const shadowKeyUmbraOpacity = 0.2 * scaleFactor;
const shadowKeyPenumbraOpacity = 0.14 * scaleFactor;
const shadowAmbientShadowOpacity = 0.12 * scaleFactor;
function createShadow(...px) {
  return [
    `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px rgba(0,0,0,${shadowKeyUmbraOpacity})`,
    `${px[4]}px ${px[5]}px ${px[6]}px ${px[7]}px rgba(0,0,0,${shadowKeyPenumbraOpacity})`,
    `${px[8]}px ${px[9]}px ${px[10]}px ${px[11]}px rgba(0,0,0,${shadowAmbientShadowOpacity})`,
  ].join(',');
}
// Values from https://github.com/material-components/material-components-web/blob/be8747f94574669cb5e7add1a7c54fa41a89cec7/packages/mdc-elevation/_variables.scss
const shadows = [
  'none',
  createShadow(0, 2, 1, -1, 0, 1, 1, 0, 0, 1, 3, 0),
  createShadow(0, 3, 1, -2, 0, 2, 2, 0, 0, 1, 5, 0),
  createShadow(0, 3, 3, -2, 0, 3, 4, 0, 0, 1, 8, 0),
  createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
  createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
  createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
  createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
  createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
  createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
  createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3),
  createShadow(0, 6, 7, -4, 0, 11, 15, 1, 0, 4, 20, 3),
  createShadow(0, 7, 8, -4, 0, 12, 17, 2, 0, 5, 22, 4),
  createShadow(0, 7, 8, -4, 0, 13, 19, 2, 0, 5, 24, 4),
  createShadow(0, 7, 9, -4, 0, 14, 21, 2, 0, 5, 26, 4),
  createShadow(0, 8, 9, -5, 0, 15, 22, 2, 0, 6, 28, 5),
  createShadow(0, 8, 10, -5, 0, 16, 24, 2, 0, 6, 30, 5),
  createShadow(0, 8, 11, -5, 0, 17, 26, 2, 0, 6, 32, 5),
  createShadow(0, 9, 11, -5, 0, 18, 28, 2, 0, 7, 34, 6),
  createShadow(0, 9, 12, -6, 0, 19, 29, 2, 0, 7, 36, 6),
  createShadow(0, 10, 13, -6, 0, 20, 31, 3, 0, 8, 38, 7),
  createShadow(0, 10, 13, -6, 0, 21, 33, 3, 0, 8, 40, 7),
  createShadow(0, 10, 14, -6, 0, 22, 35, 3, 0, 8, 42, 7),
  createShadow(0, 11, 14, -7, 0, 23, 36, 3, 0, 9, 44, 8),
  createShadow(0, 11, 15, -7, 0, 24, 38, 3, 0, 9, 46, 8),
];

// normal
// https://res.cloudinary.com/dxnehm70c/image/upload/v1661308010/MB_logo_bluem_ikg6lp.png 
// split
// https://res.cloudinary.com/dxnehm70c/image/upload/v1661308009/MB_logo_bluem-split_rvqge8.png


// export const theme = createTheme({
//     palette: {
//       primary: {
//         main: COLORS.PRIMARY.MAIN, // hsl 211, 100%, 50%
//         light: COLORS.PRIMARY.LIGHT, // hsl 211, 100%, 50%
//         dark: COLORS.PRIMARY.DARK, // hsl 211, 100%, 50%
//         contrastText: COLORS.PRIMARY.CONTRAST_TEXT,
//         // main: COLORS.MAIN,  // 50%  
//         // light: "#65a0cd", // 60%
//         // dark: "#316d9b", // 40%
//         // contrastText: "#fff",
//       },
//       success: {
//         main: "#2e7d32",
//         // main: "hsla(123, 46%, 34%, 1)", // original
//         hover: "hsla(123, 46%, 34%, .16)",
//         hoverDense: "hsla(123, 46%, 34%, .6)",
//       },
//       error: {
//         main: "#d32f2f",
//         // backgroundLight: "#faf0f0",
//         // main: "hsla(0, 65%, 51%, 1)", // original
//         hover: "hsla(0, 65%, 51%, .16)",
//         hoverDense: "hsla(0, 65%, 51%, .6)",
//       },
//       warning: {
//         main: "#ed6c02",
//         // main: "hsla(27, 98%, 47%, 1)", // original
//         hover: "hsla(27, 98%, 47%, .16)",
//         hoverDense: "hsla(27, 98%, 47%, .6)"
//       },

//       primaryOriginal: {
//         main: "#1976d2",
//         light: "#42a5f5",
//         dark: "#1565c0",
//         contrastText: "#fff",
//       },
//       primaryBootstrap: {
//         main: "#3E88C1",  // 50%  
//         light: "#65a0cd", // 60%
//         dark: "#316d9b", // 40%
//         contrastText: "#fff",
//       },

//       neutral: {
//         main: colors.grey[500],
//         light: colors.grey[300], // not working via "neutral.light"
//         dark: colors.grey[600], // not working via "neutral.dark"
//         contrastText: "#fff"
//       },
//       grey: {
//         350: "#cfcfcf",
//         850: "#2e2e2e"
//       },
//     },


//     shadows: shadows,


//     components: {
//       MuiPaper: {
//         styleOverrides: {
//           rounded: {
//             borderRadius: "12px"
//           }
//         }
//       },
//       MuiIconButton: {
//         styleOverrides: {
//           root: {
//             // backgroundColor: "rgba(0,0,0,0.04)"
//           }
//         }
//       }
//       // MuiButton: {
//       //   styleOverrides: {
//       //    root: {
//       //       lineHeight: 0
//       //     }
//       //   }
//       // },
//       // root: {
//       //   "& .MuiPaper-root": {
//       //     borderRadius: "100px",
//       //     boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);"
//       //   }
//       // }
//       // MuiFormLabel: {
//       //   styleOverrides: {
//       //     root: {
//       //        //TODO:  make box & label blue (when filled) (same as when active ?...or slightly darker actually)
//       //     }
//       //   }
//       // }
//       // MuiSvgIcon: {
//       //   styleOverrides: {
//       //     root: {
//       //       fontSize: {
//       //         "x-small": theme.typography?.pxToRem?.(16) || '1rem',
//       //       }
//       //     }
//       //   }
//       // },
//     },
// })
  

export const themeLight = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: COLORS.PRIMARY.LIGHT.MAIN, // hsl 211, 100%, 50%
        light: COLORS.PRIMARY.LIGHT.LIGHT, // hsl 211, 100%, 50%
        dark: COLORS.PRIMARY.LIGHT.DARK, // hsl 211, 100%, 50%
        contrastText: COLORS.PRIMARY.LIGHT.CONTRAST_TEXT,
        // main: COLORS.MAIN,  // 50%  
        // light: "#65a0cd", // 60%
        // dark: "#316d9b", // 40%
        // contrastText: "#fff",
      },
      success: {
        main: "#2e7d32",
        // main: "hsla(123, 46%, 34%, 1)", // original
        hover: "hsla(123, 46%, 34%, .16)",
        hoverDense: "hsla(123, 46%, 34%, .6)",
      },
      error: {
        main: "#d32f2f",
        // backgroundLight: "#faf0f0",
        // main: "hsla(0, 65%, 51%, 1)", // original
        hover: "hsla(0, 65%, 51%, .16)",
        hoverDense: "hsla(0, 65%, 51%, .6)",
      },
      warning: {
        main: "#ed6c02",
        // main: "hsla(27, 98%, 47%, 1)", // original
        hover: "hsla(27, 98%, 47%, .16)",
        hoverDense: "hsla(27, 98%, 47%, .6)"
      },
      
      primaryOriginal: {
        main: "#1976d2",
        light: "#42a5f5",
        dark: "#1565c0",
        contrastText: "#fff",
      },
      primaryBootstrap: {
        main: "#3E88C1",  // 50%  
        light: "#65a0cd", // 60%
        dark: "#316d9b", // 40%
        contrastText: "#fff",
      },

      neutral: {
        main: colors.grey[500],
        light: colors.grey[300], // not working via "neutral.light"
        dark: colors.grey[600], // not working via "neutral.dark"
        contrastText: "#fff"
      },
      grey: {
        350: "#cfcfcf",
        850: "#2e2e2e"
      },
    },


    shadows: shadows,


    components: {
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: "12px"
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            // backgroundColor: "rgba(0,0,0,0.04)"
          }
        }
      }
      // MuiButton: {
      //   styleOverrides: {
      //    root: {
      //       lineHeight: 0
      //     }
      //   }
      // },
      // root: {
      //   "& .MuiPaper-root": {
      //     borderRadius: "100px",
      //     boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);"
      //   }
      // }
      // MuiFormLabel: {
      //   styleOverrides: {
      //     root: {
      //        //TODO:  make box & label blue (when filled) (same as when active ?...or slightly darker actually)
      //     }
      //   }
      // }
      // MuiSvgIcon: {
      //   styleOverrides: {
      //     root: {
      //       fontSize: {
      //         "x-small": theme.typography?.pxToRem?.(16) || '1rem',
      //       }
      //     }
      //   }
      // },
    },
})
  
// dark mode color generators (for light mode colors): https://colorffy.com/dark-theme-generator
// dark color palettes (general, not MUI): https://coolors.co/palettes/trending/dark
export const themeDark = createTheme({
    palette: {
      mode: 'dark',
      background: {
        // default: "#121212",
        // paper: "#282828",
        default: "#282828",
        paper: "#3f3f3f",

        // default: "#28303b",
        // paper: "#2c3743",

        // default: "#1d2637",
        // paper: "#262f3d",
        // default: "#303030",
        // paper: "#424242",
      },
      divider: "rgba(255, 255, 255, 0.12)",
      text: {
        primary: "rgba(255, 255, 255, 0.8)",
        secondary: "rgba(255, 255, 255, 0.7)",
        disabled: "rgba(255, 255, 255, 0.5)",
        hint: "rgba(255, 255, 255, 0.5)",
      },
      // action: {
      //   active: colors.lightBlue[200],
      //   activeOpacity: 1,
      //   hover: colors.lightBlue[100],
      //   hoverOpacity: 0.7,
      //   focus: colors.lightBlue[600],
      //   focusOpacity: 1,
      //   selected: colors.lightBlue[300],
      //   selectedOpacity: 1
      // },

      primary: {
        main: COLORS.PRIMARY.DARK.MAIN, // hsl 211, 100%, 50%
        // light: COLORS.PRIMARY.DARK.LIGHT, // hsl 211, 100%, 50%
        // dark: COLORS.PRIMARY.DARK.DARK, // hsl 211, 100%, 50%
        contrastText: COLORS.PRIMARY.DARK.CONTRAST_TEXT,
        // main: COLORS.MAIN,  // 50%  
        // light: "#65a0cd", // 60%
        // dark: "#316d9b", // 40%
        // contrastText: "#fff",
      },
      error: {
        main: "#d32f2f", // default dark mode changes = too bright
      },


      primaryOriginal: {
        main: "#1976d2",
        light: "#42a5f5",
        dark: "#1565c0",
        contrastText: "#fff",
      },
      primaryBootstrap: {
        main: "#3E88C190",  // 50%  
        // light: "#65a0cd", // 60%
        // dark: "#316d9b", // 40%
        contrastText: "#fff",
      },

      neutral: {
        main: colors.grey[500],
        light: colors.grey[300], // not working via "neutral.light"
        dark: colors.grey[600], // not working via "neutral.dark"
        contrastText: "#fff"
      },
      grey: {
        350: "#cfcfcf",
        850: "#2e2e2e"
      },
    },


    shadows: shadows,


    components: {
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: "12px"
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            // backgroundColor: "rgba(0,0,0,0.04)"
          }
        }
      }
      // MuiButton: {
      //   styleOverrides: {
      //    root: {
      //       lineHeight: 0
      //     }
      //   }
      // },
      // root: {
      //   "& .MuiPaper-root": {
      //     borderRadius: "100px",
      //     boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75);"
      //   }
      // }
      // MuiFormLabel: {
      //   styleOverrides: {
      //     root: {
      //        //TODO:  make box & label blue (when filled) (same as when active ?...or slightly darker actually)
      //     }
      //   }
      // }
      // MuiSvgIcon: {
      //   styleOverrides: {
      //     root: {
      //       fontSize: {
      //         "x-small": theme.typography?.pxToRem?.(16) || '1rem',
      //       }
      //     }
      //   }
      // },
    },
})
  

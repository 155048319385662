import React from 'react'
import { Box, Stack } from '@mui/material'
import { PageHeader } from './PageHeader'
import { PageNav } from './PageNav'
import { MyDivider } from '../utils/MyDivider'

export function PageContainer({
  direction = "column",
  alignItems = "start", // original for this project was "center" (likely for login/register/forgotpassword)
  justifyContent = "start",
  flexGrow = 1,
  toolbarSpacing = true,
  sx,

  title, // PageTitle component (usually)
  headerIsHidden,
  dividerIsHidden = true,
  navIsHidden,
  navItems,
  selectedNavItem,
  navState,
  setNavState,

  children
}) {
  return (
    <>
      <Stack
        direction={direction}
        alignItems={alignItems}
        justifyContent={justifyContent}
        sx={{
          flexGrow: flexGrow,
          // mt: !toolbarSpacing && "-120px",

          // px: 6,
          // maxWidth: "1600px",

          ...sx
        }}
      > 

        <PageHeader
          title={title}
          hidden={headerIsHidden}
        />

        <MyDivider
          headerDivider
          hidden={dividerIsHidden}
        />

        <PageNav
          navItems={navItems}
          selectedNavItem={selectedNavItem}
          hidden={navIsHidden} 
          navState={navState}
          setNavState={setNavState}
        />

        {/* <Box
          sx={{
            width: 1,
            border: "1px solid red",
          }}
        > */}
          {children}
        {/* </Box> */}

      </Stack>
    </>
  )
}

import React, { useState, useEffect, useMemo, useContext } from "react"
import { Stack, Box } from "@mui/material"

import { StateContext } from "../../../state-management/app/useContext/StateContext"

import { nanoid } from "nanoid"
import { MRT } from "../../../components/utils/MRT"
import { Pod } from "../../../components/utils/Pod"
import {get} from 'lodash';
import { ifNegNumConvertToParenthesesFormat, ifParenthesesStrConvertToNegNum } from "../../../utils/ifParenthesesStrConvertToNegNum"



export function TableStatementDetails({
  docsOriginal = [],
  setDocsOriginal,

  isLoading, // isLoadingTable
  setIsLoading,

  tablePodViewVisible = true,
  initialSettings = {
    tableView: "table"
  },

  enableTableHeader,

  sx = {},
  
  // additional
  docType,
  keys = [],
}) {
  const DOC_SINGULAR = "statement"
  const DOCS_PLURAL = "statements"

  //////////////////////////
  // STATE
  //////////////////////////

  // GENERAL
  const appState = useContext(StateContext)

  // TABLE
  const defaultColumnVisibility = {
    createdDate: false,
    updatedDate: false,
  }
  const [columnVisibility, setColumnVisibility] = useState(defaultColumnVisibility)

  // ADDITIONAL
  const [docsFinal, setDocsFinal] = useState(docsOriginal)
  const [docsAreGroupedByCategory, setDocsAreGroupedByCategory] = useState(false)
  const [statementTotals, setStatementTotals] = useState({})
  


  //////////////////////////
  // EFFECTS
  //////////////////////////
    
  // GROUP DOCS BY CATEGORY (PODVIEW)
  useEffect(() => {
    if (docsFinal?.length > 0 && !docsFinal?.at(0)?.groupedCategory) {
      const groupsByCategory = []

      docsFinal.forEach(doc => {
        let index = groupsByCategory.findIndex(el => el?.groupedCategory == doc.category)
        if (index > -1) {
          if (doc.typeCategory == "Revenue" || docType == "JIB") {
            groupsByCategory.at(index).interests.push(doc)
          }
        }
        else {
          groupsByCategory.push({
            groupedCategory: doc.category,
            interests: [doc]
          })
        }
      })
      setDocsFinal(groupsByCategory)
      setDocsAreGroupedByCategory(true)
      setIsLoading(false)
    }
    
  }, [docsFinal])


  // GET STATEMENT TOTALS
  useEffect(() => {
    if (docsAreGroupedByCategory && docsFinal?.length > 0) {
      if (docType == "REVENUE") {
        setStatementTotals(getStatementTotalsRevenue(docsFinal))
      }
      else if (docType == "JIB") {
        setStatementTotals(getStatementTotalsJib(docsFinal))
      }
    }
  }, [docsAreGroupedByCategory])



  //////////////////////////
  // FUNCTIONS
  //////////////////////////
  //////////////////////////
  // TABLE
  //////////////////////////

  const DEFAULT_COLUMN_WIDTH = "120px"
  const COLUMN_WIDTH = {
    TYPEDESC: "300px",
    TYPEDESCJIB: "400px",
  }

  
  function getStatementTotalsRevenue(docs=[]) {
    let statementTotalsTemp = {
      category:  `Total for Statement`,
      volume: 0,
      value: 0
    }

    docs.forEach(doc => {
      let category = doc.groupedCategory || ""
      let totals = {
        category:  `Total for ${category.toUpperCase()}`,
        volume: 0,
        value: 0
      }
      
      const interests = doc.interests || []
      interests.forEach(int => {
        let ownerVolume = Number(ifParenthesesStrConvertToNegNum(int.owner?.volume)) || 0
        let ownerValue = Number(ifParenthesesStrConvertToNegNum(int.owner?.value)) || 0
  
        int.taxDeducts.forEach(lineItem => {
          ownerVolume = ownerVolume + Number(ifParenthesesStrConvertToNegNum(lineItem.owner?.volume)) || 0
          ownerValue = ownerValue + Number(ifParenthesesStrConvertToNegNum(lineItem.owner?.value)) || 0
        })
        
        totals.volume = totals.volume + ownerVolume
        totals.value = totals.value + ownerValue
      })

      statementTotalsTemp.volume = statementTotalsTemp.volume + totals.volume
      statementTotalsTemp.value = statementTotalsTemp.value + totals.value
    })

    return statementTotalsTemp
  }
  function getStatementTotalsJib(docs=[]) {
    let statementTotalsTemp = {
      category:  `Total for Statement`,
      amtGross: 0,
      amtOriginal: 0
    }

    docs.forEach(doc => {
      let category = doc.groupedCategory || ""
      let totals = {
        category:  `Total for ${category.toUpperCase()}`,
        amtGross: 0,
        amtOriginal: 0
      }
      
      const interests = doc.interests || []
      interests.forEach(int => {
        let amtGross = Number(ifParenthesesStrConvertToNegNum(int.amtGross)) || 0
        let amtOriginal = Number(ifParenthesesStrConvertToNegNum(int.amtOriginal)) || 0
        
        totals.amtGross = totals.amtGross + amtGross
        totals.amtOriginal = totals.amtOriginal + amtOriginal
      })

      statementTotalsTemp.amtGross = statementTotalsTemp.amtGross + totals.amtGross
      statementTotalsTemp.amtOriginal = statementTotalsTemp.amtOriginal + totals.amtOriginal
    })

    return statementTotalsTemp
  }


  function CategorySection({category=""}) {
    return (
      <Box sx={{flexGrow: 1, width: 1, p: 1, pl: 0.9, backgroundColor: "#00000080", color: appState.theme == "light" ? "#ffffff" : "text.default", fontStyle: "italic",
          // borderRadius: 0.5,
        }}
      >
        {category.toUpperCase()}
      </Box>
    )
  }

  function HeaderSectionRevenue() {
    return (
      <Stack direction="column" justifyContent="start" alignItems="stretch" sx={{flexGrow: 1}}>

        <Stack direction="row" justifyContent="space-between" sx={{ml: 2, fontWeight: 500, backgroundColor: "#00000030"}}>
          <ColumnHeaderCell width="140px">Code</ColumnHeaderCell>
          <ColumnHeaderCell width={COLUMN_WIDTH.TYPEDESC}>Type Desc</ColumnHeaderCell>
          <ColumnHeaderCell>Production Date</ColumnHeaderCell>
          <ColumnHeaderCell>BTU</ColumnHeaderCell>
          <Stack direction="column">
            <Box>Property Values</Box>
            <Stack direction="row">
              <ColumnHeaderCell>Volume</ColumnHeaderCell>
              <ColumnHeaderCell>Price</ColumnHeaderCell>
              <ColumnHeaderCell>Value</ColumnHeaderCell>
            </Stack>
          </Stack>
          <Stack direction="column">
            <Box>Owner Share</Box>
            <Stack direction="row">
              <ColumnHeaderCell>Owner %</ColumnHeaderCell>
              <ColumnHeaderCell>Distribution %</ColumnHeaderCell>
              <ColumnHeaderCell>Volume</ColumnHeaderCell>
              <ColumnHeaderCell>Value</ColumnHeaderCell>
            </Stack>
          </Stack>
          <Box width={8} />
        </Stack>

      </Stack>
    )
  }
  function HeaderSectionJib() {
    return (
      <Stack direction="column" justifyContent="start" alignItems="stretch" sx={{flexGrow: 1}}>

        <Stack direction="row" justifyContent="space-between" sx={{ml: 2, fontWeight: 500, backgroundColor: "#00000030"}}>
          <ColumnHeaderCell width="140px">Account</ColumnHeaderCell>
          <ColumnHeaderCell width={COLUMN_WIDTH.TYPEDESCJIB}>Description</ColumnHeaderCell>
          <ColumnHeaderCell>Partner Percent</ColumnHeaderCell>
          <ColumnHeaderCell>Activity Month</ColumnHeaderCell>
          <ColumnHeaderCell>Gross Amount</ColumnHeaderCell>
          <ColumnHeaderCell>Original</ColumnHeaderCell>
        </Stack>

      </Stack>
    )
  }

  function ColumnHeaderCell({width=DEFAULT_COLUMN_WIDTH, sx={}, children}) {
    return <Stack direction="row" justifyContent="start" alignItems="end" sx={{whiteSpace: "nowrap", fontWeight: 500, width: width, ...sx}}>{children}</Stack>
  }
  function ColumnRowCell({width=DEFAULT_COLUMN_WIDTH, disallowOverflow, sx={}, children}) {
    return <Stack direction="row" justifyContent="start" alignItems="end" sx={{whiteSpace: "nowrap", width: width, overflowX: disallowOverflow && "auto", textOverflow: "ellipsis", ...sx}}>{children}</Stack>
  }


  function LineItemsSectionRevenue({interests = []}) {
    return (
      interests.map(int =>
        <Stack key={`${nanoid()}`} direction="column" justifyContent="start" alignItems="stretch" sx={{flexGrow: 1}}>

          <Stack direction="row" justifyContent="space-between" sx={{ml: 2, fontWeight: 550, backgroundColor: "#00000010"}}>
            <ColumnRowCell width="140px" disallowOverflow>{int.code}</ColumnRowCell>
            <ColumnRowCell width={COLUMN_WIDTH.TYPEDESC}>{int.type}</ColumnRowCell>
            <ColumnRowCell>{int.date}</ColumnRowCell>
            <ColumnRowCell>{int.btu}</ColumnRowCell>
            <ColumnRowCell>{int.property.volume}</ColumnRowCell>
            <ColumnRowCell>{int.property.price}</ColumnRowCell>
            <ColumnRowCell>{int.property.value}</ColumnRowCell>
            <ColumnRowCell>{int.owner.interestPercent}</ColumnRowCell>
            <ColumnRowCell>{int.owner.distributionPercent}</ColumnRowCell>
            <ColumnRowCell>{int.owner.volume}</ColumnRowCell>
            <ColumnRowCell>{int.owner.value}</ColumnRowCell>
          </Stack>

          {int.taxDeducts?.map(li =>
            <Stack key={`${nanoid()}`} direction="row" justifyContent="space-between" sx={{ml: 3}}>
              <ColumnRowCell width="140px" disallowOverflow>{li.code}</ColumnRowCell>
              <ColumnRowCell width={COLUMN_WIDTH.TYPEDESC}>{li.type}</ColumnRowCell>
              <ColumnRowCell sx={{opacity: 0.3}}>{li.date}</ColumnRowCell>
              <ColumnRowCell>{li.btu}</ColumnRowCell>
              <ColumnRowCell>{li.property.volume}</ColumnRowCell>
              <ColumnRowCell>{li.property.price}</ColumnRowCell>
              <ColumnRowCell>{li.property.value}</ColumnRowCell>
              <ColumnRowCell sx={{opacity: 0.3}}>{li.owner.interestPercent}</ColumnRowCell>
              <ColumnRowCell sx={{opacity: 0.3}}>{li.owner.distributionPercent}</ColumnRowCell>
              <ColumnRowCell>{li.owner.volume}</ColumnRowCell>
              <ColumnRowCell>{li.owner.value}</ColumnRowCell>
            </Stack>
          )}
        </Stack>
      )
    )

  }
  function LineItemsSectionJib({interests = []}) {
    // console.log("INTERESTS", interests)

    return (
      interests.map(int =>
        <Stack key={`${nanoid()}`} direction="column" justifyContent="start" alignItems="stretch" sx={{flexGrow: 1}}>

          <Stack direction="row" justifyContent="space-between" sx={{ml: 2, backgroundColor: "#00000010"}}>
            <ColumnRowCell width="140px">{int.account}</ColumnRowCell>
            <ColumnRowCell width={COLUMN_WIDTH.TYPEDESCJIB}>{int.desc}</ColumnRowCell>
            <ColumnRowCell>{int.partnerPercent}</ColumnRowCell>
            <ColumnRowCell>{int.date}</ColumnRowCell>
            <ColumnRowCell>{int.amtGross}</ColumnRowCell>
            <ColumnRowCell>{int.amtOriginal}</ColumnRowCell>
          </Stack>

        </Stack>
      )
    )
  }


  function TotalsSectionRevenue({row}) {

    const category = row.original.groupedCategory || ""
    const totals = {
      category:  `Total for ${category.toUpperCase()}`,
      volume: 0,
      value: 0
    }
    
    const interests = row.original.interests || []
    interests.forEach(int => {
      let ownerVolume = Number(ifParenthesesStrConvertToNegNum(int.owner.volume)) || 0
      let ownerValue = Number(ifParenthesesStrConvertToNegNum(int.owner.value)) || 0

      int.taxDeducts.forEach(lineItem => {
        ownerVolume = ownerVolume + Number(ifParenthesesStrConvertToNegNum(lineItem.owner.volume)) || 0
        ownerValue = ownerValue + Number(ifParenthesesStrConvertToNegNum(lineItem.owner.value)) || 0
      })
      
      totals.volume = totals.volume + ownerVolume
      totals.value = totals.value + ownerValue
    })

    return (
      <Stack direction="column" justifyContent="start" alignItems="stretch" sx={{flexGrow: 1}}>

        <Stack direction="row" justifyContent="space-between" sx={{ml: 3, fontWeight: 550, backgroundColor: "background.default", borderTop: "1px solid"}}>
          <ColumnRowCell width="140px"></ColumnRowCell>
          <ColumnRowCell width={COLUMN_WIDTH.TYPEDESC}></ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell>{totals.category}</ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell>{ifNegNumConvertToParenthesesFormat(Number(totals.volume).toFixed(2).toLocaleString())}</ColumnRowCell>
          <ColumnRowCell>{ifNegNumConvertToParenthesesFormat(Number(totals.value).toFixed(2).toLocaleString())}</ColumnRowCell>
        </Stack>

      </Stack>
    )
  }
  function TotalsSectionJib({row}) {

    const category = row.original.groupedCategory || ""
    const totals = {
      category:  `Total for ${category.toUpperCase()}`,
      amtGross: 0,
      amtOriginal: 0
    }
    
    const interests = row.original.interests || []
    interests.forEach(int => {
      let amtGross = Number(ifParenthesesStrConvertToNegNum(int.amtGross)) || 0
      let amtOriginal = Number(ifParenthesesStrConvertToNegNum(int.amtOriginal)) || 0

      totals.amtGross = totals.amtGross + amtGross
      totals.amtOriginal = totals.amtOriginal + amtOriginal
    })

    return (
      <Stack direction="column" justifyContent="start" alignItems="stretch" sx={{flexGrow: 1}}>

        <Stack direction="row" justifyContent="space-between" sx={{ml: 2, fontWeight: 550, backgroundColor: "#background.default", borderTop: "1px solid"}}>         
          <ColumnRowCell width="140px"></ColumnRowCell>
          <ColumnRowCell width={COLUMN_WIDTH.TYPEDESCJIB}>{totals.category}</ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell>{ifNegNumConvertToParenthesesFormat(Number(totals.amtGross).toFixed(2).toLocaleString())}</ColumnRowCell>
          <ColumnRowCell>{ifNegNumConvertToParenthesesFormat(Number(totals.amtOriginal).toFixed(2).toLocaleString())}</ColumnRowCell>
        </Stack>

      </Stack>
    )
  }


  function StatementTotalsSectionRevenue({sx={}}) {
    return (
      <Stack direction="column" justifyContent="start" alignItems="stretch" sx={{flexGrow: 1, mt: 4, ...sx}}>

        <Stack direction="row" justifyContent="space-between" sx={{ml: 3, fontWeight: 550, backgroundColor: "#background.default"}}>
          <ColumnRowCell width="140px"></ColumnRowCell>
          <ColumnRowCell width={COLUMN_WIDTH.TYPEDESC}></ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell>{statementTotals.category}</ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell>{ifNegNumConvertToParenthesesFormat(Number(statementTotals.volume).toFixed(2).toLocaleString())}</ColumnRowCell>
          <ColumnRowCell>{ifNegNumConvertToParenthesesFormat(Number(statementTotals.value).toFixed(2).toLocaleString())}</ColumnRowCell>
        </Stack>

      </Stack>
    )
  }
  function StatementTotalsSectionJib({sx={}}) {
    return (
      <Stack direction="column" justifyContent="start" alignItems="stretch" sx={{flexGrow: 1, mt: 4, ...sx}}>

        <Stack direction="row" justifyContent="space-between" sx={{ml: 2, fontWeight: 550, backgroundColor: "#background.default"}}>
          <ColumnRowCell width="140px"></ColumnRowCell>
          <ColumnRowCell width={COLUMN_WIDTH.TYPEDESCJIB}>{statementTotals.category}</ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell></ColumnRowCell>
          <ColumnRowCell>{ifNegNumConvertToParenthesesFormat(Number(statementTotals.amtGross).toFixed(2).toLocaleString())}</ColumnRowCell>
          <ColumnRowCell>{ifNegNumConvertToParenthesesFormat(Number(statementTotals.amtOriginal).toFixed(2).toLocaleString())}</ColumnRowCell>
        </Stack>

      </Stack>
    )
  }

  function CellFn(row, accessorKey) {
    const value = get(row.original, accessorKey)

    if (row.original.typeCategory == "Revenue") {
      return <b>{value}</b>
    }
    else {
      return value
    }
  }


  const columnsRevenuePod = useMemo(
    () => [
      // "statementBreakdown" (object) = info for Modal
          // keys = varied names (e.g. drip condensate, gas (mcf), etc.)
          // value = array of objects
            // code, type, date, btu, property {volume, price, value}, owner {interestPercent, distributionPercent, volume, value}
      // {
      //   accessorKey: 'tableHeader',
      //   header: 'tableHeader',
      //   // Cell: ({ row }) => CellFn(row, 'category') ## TODO: must create own table header
      // },
      {
        accessorKey: 'category',
        header: 'category',
        Cell: ({ row }) => {
          const category = row.original.groupedCategory || ""
          const interests = row.original.interests || []

          return (
            <Stack direction="column" justifyContent="start" alignItems="start" sx={{flexGrow: 1}}>
              {category == keys?.at(0) && <StatementTotalsSectionRevenue sx={{mt: 1, mb: 2}} />} {/* if keys?.at(0) = "super-category", will be false and not show */}
              <CategorySection category={category} />
              <HeaderSectionRevenue />
              <LineItemsSectionRevenue interests={interests} />
              <TotalsSectionRevenue row={row} />
              {category == keys?.at(-1) && <StatementTotalsSectionRevenue />}
            </Stack>
          )
        }
      },

    ],
    [docsFinal, statementTotals],
  );

  const columnsJibPod = useMemo(
    () => [
      // "statementBreakdown" (object) = info for Modal
          // keys = varied names (e.g. drip condensate, gas (mcf), etc.)
          // value = array of objects
            // code, type, date, btu, property {volume, price, value}, owner {interestPercent, distributionPercent, volume, value}
      // {
      //   accessorKey: 'tableHeader',
      //   header: 'tableHeader',
      //   // Cell: ({ row }) => CellFn(row, 'category') ## TODO: must create own table header
      // },
      {
        accessorKey: 'category',
        header: 'category',
        Cell: ({ row }) => {
          const category = row.original.groupedCategory || ""
          const interests = row.original.interests || []

          // console.log("interests", interests)

          return (
            <Stack direction="column" justifyContent="start" alignItems="start" sx={{flexGrow: 1}}>
              {category == keys?.at(0) && <StatementTotalsSectionJib sx={{mt: 1, mb: 2}} />} {/* if keys?.at(0) = "super-category", will be false and not show */}
              <CategorySection category={category} />
              <HeaderSectionJib />
              <LineItemsSectionJib interests={interests} />
              <TotalsSectionJib row={row} />
              {category == keys?.at(-1) && <StatementTotalsSectionJib />}
            </Stack>
          )
        }
      },

    ],
    [docsFinal, statementTotals],
  );

  const columns = 
    docType == "REVENUE" ? columnsRevenuePod :  columnsJibPod


  let globalButtons = 
    <>
    </>



  return (
    <>
      <Pod 
        elevation={0}
        sx={{
          minWidth: 320, minHeight: 100, maxHeight: 600, width: 1, 
          borderRadius: 0,
          ...sx
        }}
      >
          {docType == "JIB" && <StatementTotalsSectionJib sx={{mt: 1, mb: 2}} />   }

          <MRT
            //tableType="invoices"
            data={docsFinal}
            columns={columns}
            dataOriginal={docsOriginal}
            setDataOriginal={setDocsOriginal}

            isLoading={isLoading}
            setIsLoading={setIsLoading}
            // estimatedNumOfRows={5}
            
            // tableView={tableView}
            // setTableView={setTableView}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}

            globalButtons={globalButtons}

            enableTopToolbar={enableTableHeader ? true : false}
            enableColumnResizing={false}
            // enableColumnResizing={enableTableHeader ? true : false}
            enableTableHead={enableTableHeader ? true : false}
            enableGlobalFilter={enableTableHeader ? true : false}
            enableFilters={enableTableHeader ? true : false}
            enableHiding={enableTableHeader ? true : false}
            enableDensityToggle={enableTableHeader ? true : false}
            enableFullScreenToggle={enableTableHeader ? true : false}
            enableToolbarInternalActions={enableTableHeader ? true : false}


            // additional
            // setHoveredRow={() => false}
            enableRowHover={false}
          />
      </Pod>


    </>
  )
}

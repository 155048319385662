import React, { useState } from "react";
import {
	Box,
	Button,
	TextField,
	MenuItem,
	Modal,
	Typography,
	FormControl,
	InputLabel,
	Select,
} from "@mui/material";
// custom imports
import { uploadCustomStatementsToS3 } from "../utils/s3client";
import "./Modal.css";

const months = [
	{ name: "January", value: 1 },
	{ name: "February", value: 2 },
	{ name: "March", value: 3 },
	{ name: "April", value: 4 },
	{ name: "May", value: 5 },
	{ name: "June", value: 6 },
	{ name: "July", value: 7 },
	{ name: "August", value: 8 },
	{ name: "September", value: 9 },
	{ name: "October", value: 10 },
	{ name: "November", value: 11 },
	{ name: "December", value: 12 },
];

const currentYear = new Date().getFullYear();
const minYear = 2000;

const UploadStatementsModal = ({ open, onClose, clientELid }) => {
	const [file, setFile] = useState(null);
	const [year, setYear] = useState("");
	const [month, setMonth] = useState("");
	const [yearError, setYearError] = useState(false);

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		if (
			selectedFile &&
			selectedFile.type ===
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
		) {
			setFile(selectedFile);
		} else {
			alert("Please select a valid .xlsx file");
		}
	};

	const handleYearChange = (event) => {
    const yearValue = event.target.value;
    if (yearValue < minYear || yearValue > currentYear) {
      setYearError(true);
    } else {
      setYearError(false);
    }
    setYear(yearValue);
	};

	const handleMonthChange = (event) => {
		setMonth(event.target.value);
	};

	const handleSubmit = async () => {
		if (file && year && month) {
			const result = await uploadCustomStatementsToS3(
				file,
				clientELid,
				year,
				month
			);
			if (result) {
				alert("File uploaded successfully");
				setFile(null);
				onClose();
			} else {
				alert("Error uploading file");
				setFile(null);
			}
		}
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
		>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					border: "2px solid #000",
					boxShadow: 24,
					p: 4,
				}}
			>
				<Typography id="modal-title" variant="h6" component="h2">
					Upload Statements File
				</Typography>
				<Box component="form" sx={{ mt: 2 }}>
					<Button variant="contained" component="label" disabled={file}>
						{!file ? "Select File" : "File Selected"}
						<input
							type="file"
							hidden
							onChange={handleFileChange}
							accept=".xlsx"
							disabled={file}
						/>
					</Button>
					<div
						style={{
							display: "flex",
							width: "100%",
							border: "1px solid #cccccc50",
							padding: "12px",
							borderRadius: "5px",
							marginTop: "12px",
							alignContent: "center",
							justifyContent: "center",
							alignItems: "center",
							gap: "12px",
						}}
					>
						<label htmlFor="yearInput">Year:</label>
						<input
							type="number"
							id="yearInput"
							value={year}
							onChange={handleYearChange}
							min={minYear}
							max={currentYear}
							style={{
								width: "100%",
								border: "1px solid #ccccccc50",
								padding: "12px",
								borderRadius: "5px",
								backgroundColor: "#aaaaaa50",
								color: "#cccccc",
								fontSize: "16px",
							}}
						/>
					</div>
					{/* <TextField
						fullWidth
						label="Year"
						value={year}
						onChange={handleYearChange}
						margin="normal"
						type="number"
						inputProps={{ min: 1900, max: new Date().getFullYear() }}
					/> */}
					<FormControl fullWidth margin="normal">
						<InputLabel>Month</InputLabel>
						<Select value={month} onChange={handleMonthChange}>
							{months.map((month) => (
								<MenuItem key={month.value} value={month.value}>
									{month.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<Button
						variant="contained"
						color="primary"
						fullWidth
						onClick={handleSubmit}
						disabled={!file || !year || !month || yearError}
					>
						Upload
					</Button>

					<Button
						variant="outlined"
						color="primary"
						fullWidth
						onClick={onClose}
						sx={{ mt: 2 }}
					>
						Cancel
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default UploadStatementsModal;

import { ifParenthesesStrConvertToNegNum } from "./ifParenthesesStrConvertToNegNum"



export function getStatementDetailsTotalsRevenue(statement) {
  function isWI(int) { // WORKING INTEREST
    const bool = int.type.toLowerCase().includes("working") || int.code.toLowerCase().includes(".wi")
    // console.log(bool)
    return bool
  }
  function isORRI(int) { // OVERRIDING ROYALTY INTEREST
    const bool = int.type.toLowerCase().includes("over") || int.code.toLowerCase().includes(".or")
    // console.log(bool)
    return bool
  }
  function isSeveranceTax(lineItem) {
    const bool = lineItem.type.toLowerCase().includes("sev") || lineItem.code.toLowerCase().includes(".sv")
    // console.log(bool)
    return bool
  }

  
  const errors = []

  // Note:  'value' > 'total' (depending on section of function... statement.owner.value: value, FE: total)
  let statementTotals = {
    // category:  `Total for Statement`,
    volume: 0,
    total: 0,
    tax: 0,
    deductions: 0,
    netted: 0,
  }

  const keys = Object.keys(statement.statementDetails)
  if (keys.length === 0) {
    errors.push(`*statementDetails empty*`)
  }
  keys.forEach(key => {
    let totals = {
      // category:  `Total for ${category.toUpperCase()}`,
      volume: 0,
      value: 0,
      taxes: 0,
      deducts: 0,
      netted: 0,
    }

    statement.statementDetails[key]?.forEach(int => {
      // let category = doc.groupedCategory || ""
    
      let ownerVolume = Number(ifParenthesesStrConvertToNegNum(int.owner?.volume)) || 0
      let ownerValue = Number(ifParenthesesStrConvertToNegNum(int.owner?.value)) || 0 // netted
      let intTaxes = 0
      let intDeducts = 0
      let intNetted = 0

      int.taxDeducts.forEach(lineItem => {
        const ownerVolumeLineItem = Number(ifParenthesesStrConvertToNegNum(lineItem.owner?.volume)) || 0
        const ownerValueLineItem = Number(ifParenthesesStrConvertToNegNum(lineItem.owner?.value)) || 0
        if (lineItem.typeCategory.toLowerCase().includes("tax")) {
          // if (!isWorkingInterest(int)) {
          // if (!isORRI(int)) {
          // if (isSeveranceTax(lineItem)) {
          // if (isORRI(int) && !isSeveranceTax(lineItem)) {
          if (true) {
            intTaxes += ownerValueLineItem
          }
          // console.log(intTaxes, int.code, int.date, lineItem.code, lineItem.date)
        }
        else if (lineItem.typeCategory.toLowerCase().includes("deduct")) {
          intDeducts += ownerValueLineItem
        }
        ownerVolume += ownerVolumeLineItem
        ownerValue += ownerValueLineItem
      })

      if (int.typeCategory.toLowerCase().includes("net")) {
        intNetted += ownerValue
      }
      
      totals.volume += ownerVolume
      totals.value += ownerValue
      totals.taxes += intTaxes
      totals.deducts += intDeducts
      totals.netted += intNetted

      // console.log(totals.taxes, int.code, int.date, int.type)
    })

    statementTotals.volume += totals.volume
    statementTotals.total += totals.value
    statementTotals.tax += totals.taxes
    statementTotals.deductions += totals.deducts
    statementTotals.netted += totals.netted
  })

  statementTotals = {
    errors: errors,

    volume: Number(statementTotals.volume.toFixed(2)),
    total: Number(statementTotals.total.toFixed(2)),
    tax: Number(statementTotals.tax.toFixed(2)),
    deductions: Number(statementTotals.deductions.toFixed(2)),
    netted: Number(statementTotals.netted.toFixed(2)),
  }

  return statementTotals
}

export function getStatementDetailsTotalsJib(statement) {
  const errors = []

  let statementTotals = {
    total: 0,
    afe: 0,
    operations: 0,
  }

  const keys = Object.keys(statement.statementDetails, errors)
  if (keys.length === 0) {
    errors.push(`*statementDetails empty*`)
  }

  keys.forEach(key => {
    let totals = {
      // category:  `Total for ${category.toUpperCase()}`,
      total: 0,
      afe: 0,
      operations: 0
    }

    statement.statementDetails[key]?.forEach(int => {
      // let category = doc.groupedCategory || ""
    
      let intTotal = Number(ifParenthesesStrConvertToNegNum(int.amtOriginal)) || 0
      let intAfe = 0
      let intOperations = 0

      if (statement.afe || statement.afeOp || statement.afeId) {
        intAfe += intTotal
      }
      else {
        intOperations += intTotal
      }

      totals.total += intTotal
      totals.afe += intAfe
      totals.operations += intOperations
    })

    statementTotals.total += totals.total
    statementTotals.afe += totals.afe
    statementTotals.operations += totals.operations
  })

  statementTotals = {
    errors: errors,

    total: Number(statementTotals.total.toFixed(2)),
    afe: Number(statementTotals.afe.toFixed(2)),
    operations: Number(statementTotals.operations.toFixed(2)),
  }

  return statementTotals
}




export function checkStatementAndStatementDetailsMatchRevenue(statement) {
  const errors = []
  let statementAndStatementDetailsMatch = true

  // statement (text from scrape)
  // const statementVolume = Number(ifParenthesesStrConvertToNegNum(statement.volume)) || 0
  const statementTotal = Number(ifParenthesesStrConvertToNegNum(statement.total)) || 0
  const statementTaxes = Number(ifParenthesesStrConvertToNegNum(statement.tax)) || 0
  const statementDeductions = Number(ifParenthesesStrConvertToNegNum(statement.deductions)) || 0
  const statementNetted = Number(ifParenthesesStrConvertToNegNum(statement.netted)) || 0

  // statementDetails
  const { errors: statementErrors, volume, total, tax, deductions, netted } = getStatementDetailsTotalsRevenue(statement)
  if (statementErrors.length > 0) {
    statementErrors.forEach(error => errors.push(error)) // e.g. no statementDetails (empty {})
    statementAndStatementDetailsMatch = false
  }

  
  // check statement and statementDetails match
  // if (statementVolume != volume) {
  //   errors.push(`*volume mismatch*`)
  //   statementAndStatementDetailsMatch = false
  // }
  if (statementTotal != total) {
    console.log(`${statement.statementId}-statementTotal`, statementTotal, total)
    errors.push(`*total mismatch* (listed: ${statementTotal}, calculated: ${total})`)
    statementAndStatementDetailsMatch = false
  }
  if (statementTaxes != tax) {
    console.log(`${statement.statementId}-statementTaxes`, statementTaxes, tax)
    errors.push(`*tax mismatch* (listed: ${statementTaxes}, calculated: ${tax})`)
    statementAndStatementDetailsMatch = false
  }
  if (statementDeductions != deductions) {
    console.log(`${statement.statementId}-statementDeductions`, statementDeductions, deductions)
    errors.push(`*deductions mismatch* (listed: ${statementDeductions}, calculated: ${deductions})`)
    statementAndStatementDetailsMatch = false
  }
  if (statementNetted != netted) {
    console.log(`${statement.statementId}-statementNetted`, statementNetted, netted)
    // errors.push(`*netted mismatch*`)
    errors.push(`*netted mismatch* (listed: ${statementNetted}, calculated: ${netted})`)
    statementAndStatementDetailsMatch = false
  }

  return { errors, statementAndStatementDetailsMatch }
}

export function checkStatementAndStatementDetailsMatchJib(statement) {
  const errors = []
  let statementAndStatementDetailsMatch = true

  // statement (text from scrape)
  const statementTotal = Number(ifParenthesesStrConvertToNegNum(statement.amtOriginal)) || 0 // amtOriginal (if scraped site changes in future, can add totalTotal to scrape)
  const statementAfe = Number(ifParenthesesStrConvertToNegNum(statement.totalAfe)) || 0  // total not displayed for scraping (in table... but it is ABOVE table... TODO)
  const statementOperations = Number(ifParenthesesStrConvertToNegNum(statement.totalOperations)) || 0  // total not displayed for scraping (in table... but it is ABOVE table... TODO)

  // statementDetails
  const { errors: statementErrors, total, afe, operations } = getStatementDetailsTotalsJib(statement)
  if (statementErrors.length > 0) {
    if (statementTotal == 0) {
      // do not put error -- this is a statement that only consists of Show Net $0 Lines (and currently, CCG has decided to scrape invoices without that checked) (if this decision changes, then this if statement can be removed)
      // console.log(`${statement.statementId}-statementTotal`, statementTotal, total)
    }
    else {
      statementErrors.forEach(error => errors.push(error)) // e.g. no statementDetails (empty {})
      statementAndStatementDetailsMatch = false
    }
  }

  // check statement and statementDetails match
  if (statementTotal != total) {
    // if (statement.statementId == "12563058122") {
    //   console.log(`${statement.statementId}-statementTotal`, statementTotal, total)
    // }
    errors.push(`*total mismatch*`)
    statementAndStatementDetailsMatch = false
  }
  if (statement.totalAfe !== undefined) {
    if (statementAfe != afe) {
      console.log(`${statement.statementId}-statementAfe`, statementAfe, afe)
      errors.push(`*afe mismatch*`)
      statementAndStatementDetailsMatch = false
    }
  }
  if (statement.totalOperations !== undefined) {
    if (statementOperations != operations) {
      console.log(`${statement.statementId}-statementOperations`, statementOperations, operations)
      errors.push(`*operations mismatch*`)
      statementAndStatementDetailsMatch = false
    }
  }

  return { errors, statementAndStatementDetailsMatch }
}





export function checkErrorsInvoice(invoice=null) {
  let errors = []
  let invoiceAndStatementDetailsMatch = true

  if (invoice == null || Object.keys(invoice).length == 0) return { errors, invoiceAndStatementDetailsMatch }
  
  // if pulled/repulled (...handled befoer here (TableInvoices.js))


  // (1) invoice level errors (REVENUE, JIB)
  const error1Requirements = invoice.amtOriginal && invoice.totals?.total
  const error1 = error1Requirements ? ifParenthesesStrConvertToNegNum(invoice.amtOriginal) != invoice.totals.total?.toFixed(2) : false
  if (error1) {
    console.log("*amtOriginal*", invoice.docNum, ifParenthesesStrConvertToNegNum(invoice.amtOriginal), invoice.totals.total?.toFixed(2))
    errors.push(`*amtOriginal != statementDetails*`)
    invoiceAndStatementDetailsMatch = false
  }
  if (invoice.docType == "REVENUE") {
    const error2Requirements = invoice.totalNetted && invoice.totals?.netted
    const error2 = error2Requirements ? ifParenthesesStrConvertToNegNum(invoice.totalNetted) != invoice.totals.netted?.toFixed(2) : false
    if (error2) {
      // console.log("*afe*", invoice.docNum, ifParenthesesStrConvertToNegNum(invoice.amtOriginal), invoice.totals.total?.toFixed(2))
      errors.push(`*totalNetted != statementDetails*`)
      invoiceAndStatementDetailsMatch = false
    }
  }
  if (invoice.docType == "JIB") {
    // AS IS:  Extra Columns can be added to AFE (e.g. Cash Call), would result in error.  Ignore this error for now.
    // const error2Requirements = invoice.totalAfe && invoice.totals?.afe
    // const error2 = error2Requirements ? ifParenthesesStrConvertToNegNum(invoice.totalAfe) != invoice.totals.afe?.toFixed(2) : false
    // if (error2) {
    //   // console.log("*afe*", invoice.docNum, ifParenthesesStrConvertToNegNum(invoice.amtOriginal), invoice.totals.total?.toFixed(2))
    //   errors.push(`*totalAfe != statementDetails*`)
    //   invoiceAndStatementDetailsMatch = false
    // }
    const error3Requirements = invoice.totalOperations && invoice.totals?.operations
    const error3 = error3Requirements ? ifParenthesesStrConvertToNegNum(invoice.totalOperations) != invoice.totals.operations?.toFixed(2) : false
    if (error3) {
      // console.log("*operations*", invoice.docNum, ifParenthesesStrConvertToNegNum(invoice.amtOriginal), invoice.totals.total?.toFixed(2))
      errors.push(`*totalOperations != statementDetails*`)
      invoiceAndStatementDetailsMatch = false
    }
  }


  // (2) statement & statementDetail level errors (REVENUE/JIB SEPARATED)
  if (Array.isArray(invoice.statements) && invoice.statements.length > 0) {
    if (invoice.docType === "REVENUE") {
      invoice.statements.forEach(statement => {
        const { errors: statementErrors, statementAndStatementDetailsMatch } = checkStatementAndStatementDetailsMatchRevenue(statement)
        errors.push(...statementErrors)
        if (!statementAndStatementDetailsMatch) invoiceAndStatementDetailsMatch = false
      })
    }
    else if (invoice.docType === "JIB") {
      invoice.statements.forEach(statement => {
        const { errors: statementErrors, statementAndStatementDetailsMatch } = checkStatementAndStatementDetailsMatchJib(statement)
        errors.push(...statementErrors)
        if (!statementAndStatementDetailsMatch) invoiceAndStatementDetailsMatch = false
      })
    }
  }

  // }

  // temp fix for duplicate errors
  errors = errors.filter((error, index) => errors.indexOf(error) === index)

  return { errors, invoiceAndStatementDetailsMatch }
}




// export function getStatementDetailsTotalsJib(statement) {
//   let statementTotals = {
//     afe: 0,
//     operations: 0,
//     total: 0,
//   }

//   let afe = 0
//   let operations = 0
//   let total = 0

//   if (statement.afe || statement.afeOp || statement.afeId) {
//     afe += ifParenthesesStrConvertToNegNum(statement.amtOriginal || 0)
//   }
//   else {
//     operations += ifParenthesesStrConvertToNegNum(statement.amtOriginal || 0)
//   }
//   total += ifParenthesesStrConvertToNegNum(statement.amtOriginal || 0)
  
//   statementTotals.afe = afe
//   statementTotals.operations = operations
//   statementTotals.total = total

//   return statementTotals
// }

import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import PropTypes  from "prop-types"
import { Box, Stack, Toolbar, Typography } from "@mui/material"
import SiteTopNav from "./SiteTopNav"
import { ComponentTransition } from "./animations/ComponentTransition"


SiteLoggedIn.propTypes = {}
export function SiteLoggedIn({
  children,
}) {
  
  const drawerWidth = 200

  return (
    <>
      {/* APP BAR */}
      <SiteTopNav drawerWidth={drawerWidth} />

      {/* SIDE DRAWER */}
      {/* <SiteDrawer width={drawerWidth} /> */}

      {/* [PAGE] */}
      {/* - consider Container (centered, horizontal padding... maybe on per-page level)  */}
      <Stack id="pageStack" 
        sx={{
          // marginTop: 6,
          // paddingLeft: {xs: 4, sm: 12},
          // paddingRight: {xs: 4, sm: 12},
          flexGrow: 1,
          // height: "100%",
          // overflowX: "auto",
        }}
      >

        <ComponentTransition>
          {children}        
        </ComponentTransition>

      </Stack>
    </>
  )
}

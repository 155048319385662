import React  from "react"
import { Button, IconButton, Tooltip } from "@mui/material"
import { HelpRounded } from "@mui/icons-material"


export function IconButtonStyled({
  state = "nostate",
  activeState = "nostate",
  onClick = () => {},
  label,
  disabled = false,
  toolTip = false,
  size = "small",
  color = "primary",

  edge,

  sx = {},
  
  children = <HelpRounded />
}) {

  const buttonSx = {
    borderRadius: "400px",
    opacity: state == activeState ? 1 : 0.5,
    "&:hover": {
      // color: state == activeState ? "#ffffff" : "primary.main",
      // backgroundColor: state == activeState ? (theme) => theme.palette.primary.main + "80" : "default"
    },

    backgroundColor: (disabled && state == activeState) && "grey.400"
  }
  

  return (
    <>
      {toolTip ?
        <Tooltip title={toolTip}>
          <IconButton
            size={size}
            color={color}
            onClick={onClick}
            disabled={disabled}
            sx={{...buttonSx, ...sx}}
          >
            {children}
          </IconButton>
        </Tooltip>
      :
        <IconButton
          size={size}
          color={color}
          onClick={onClick}
          disabled={disabled}
          sx={{...buttonSx, ...sx}}
        >
          {children}
        </IconButton>
      }
    </>
  )
}

import React  from "react"
import { Button, Tooltip } from "@mui/material"


export function ButtonStyled({
  state = "nostate",
  activeState = "nostate",
  onClick = () => {},
  label,
  disabled = false,
  toolTip = false,
  size = "small",
  color = "primary",
  variant,
  sx = {},
  startIcon,
  
  children
}) {

  const buttonSx = {
    borderRadius: "400px",
    // color: state == activeState ? "#ffffff" : "primary.main",
    // backgroundColor: state == activeState ? (theme) => theme.palette.primary.main + "60" : "default",
    opacity: state == activeState ? 1 : 0.5,
    "&:hover": {
      // color: state == activeState ? "#ffffff" : "primary.main",
      // backgroundColor: state == activeState ? (theme) => theme.palette.primary.main + "80" : "default"
    },

    backgroundColor: (disabled && state == activeState) && "grey.400"
  }
  

  return (
    <>
      {toolTip ?
        <Tooltip title={toolTip}>
          <Button
            size={size}
            color={color}
            variant={variant ? variant : state == activeState ? "contained" : "text"}
            onClick={onClick}
            disabled={disabled}
            startIcon={startIcon}
            sx={{...buttonSx, ...sx}}
          >
            {label && label}
            {children}
          </Button>
        </Tooltip>
      :
        <Button
          size={size}
          color={color}
          variant={variant ? variant : state == activeState ? "contained" : "text"}
          onClick={onClick}
          disabled={disabled}
          startIcon={startIcon}
          sx={{...buttonSx, ...sx}}
        >
          {label && label}
          {children}
        </Button>
      }
    </>
  )
}

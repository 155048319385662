import React from "react"
import { Stack, Typography } from "@mui/material"



export function PageTitle({
  icon,
  pageTitleName,
  contentRightOfIconTitle,
  contentRightOfTitle,
  contentBelowTitle,
  contentRightOfPage
}) {
  
  

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="start" sx={{pt: 1, mb: 2}}>
      {/* LEFT */}
      <Stack gap={1}>

        {/* ICON + TITLE */}
        <Stack direction="row" gap={0} alignItems="center">
          <Stack direction="row" gap={0} alignItems="center">

            {/* ICON */}
            {icon}

            {/* TITLE */}
            <Stack gap={0}>
              <Stack direction="row" gap={2} alignItems="center">
                <Stack direction="row" gap={0.5} p={0.5} alignItems="center">
                  <Typography variant="h4" sx={{
                    // whiteSpace: "nowrap",
                    display: "inline",
                    pl: "8px",
                    fontWeight: 600
                  }}>
                    {pageTitleName}
                  </Typography>
                </Stack>
              </Stack>


              {contentRightOfTitle}
            </Stack>

          </Stack>

          {contentRightOfIconTitle}
        </Stack>

        {/* BELOW ICON+TITLE */}
        <Stack direction="row" gap={2}>
          {contentBelowTitle}
        </Stack>

      </Stack>

      {/* RIGHT */}
      {contentRightOfPage}
    </Stack>
  )
}

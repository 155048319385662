import React, { useState, useEffect, useContext, useRef, useCallback } from "react"
import { Box, Stack, TextField, Typography } from "@mui/material"
import { ModalGeneric } from "../../../components/utils/ModalGeneric"
import { TextFieldStyled } from "../../../components/utils/TextFieldStyled"
import { formatToCamelCase } from "../../../utils/formatToCamelCase"
import { formatToTanStackTableColumn } from "../../../utils/formatToTanStackTableColumn"
import { StateContext } from "../../../state-management/app/useContext/StateContext"
import axios from "axios"
import { onKeyDown } from "../../../utils/onKeyDown"


export function ModalInvoicesCustomColumn({
  open = false,
  setOpen,

  customColumns = [],
  setCustomColumns,
}) {

  const callbackRef = useCallback(inputElement => {
    if (inputElement) {
      setTimeout(() => {
        inputElement.focus();
        // inputRef.current?.focus();
        // console.log("focused");
      }, 1);
    }
  }, []);
  
  const appState = useContext(StateContext)

  const [customColumn, setCustomColumn] = useState("")
  const [isCreatingColumn, setIsCreatingColumn] = useState(false)

  const ref = useRef(null)


  async function handleSave() {
    let camelCase = formatToCamelCase(customColumn)
    let formattedCustomColumn = formatToTanStackTableColumn(camelCase, true)
    
    const controller = new AbortController()
    async function setCustomProperty() {
      try {
        setIsCreatingColumn(true)
        let customPropertyName = camelCase
        console.log("customPropertyName", customPropertyName)
        let response = await axios.post("/invoices/custom/property", {customPropertyName: camelCase, token: appState.user.token}, {signal: controller.signal})
        console.log("response (set custom property)", response)
        if (Boolean(response) == true) {
          console.log("true!")
          console.log("formattedCustomColumn", formattedCustomColumn)
          // setCustomColumns([...customColumns, formattedCustomColumn])
          
          // window.alert("Custom column created. Please refresh the page to allow for the change to properly take place.")
          window.location.reload()
        }
      } catch(e) {
        console.log("There was a problem or the request was cancelled. (set custom property)", e)
      } finally {
        setIsCreatingColumn(false)
        setOpen(false)
      }
    }
    await setCustomProperty()
    // console.log("done with function")
    // return () => {
    //   controller.abort()
    // }
  }

  // useEffect(() => {
  //   if (open && ref.current) {
  //     ref.current.focus()
  //   }
  //   console.log("REF", ref)
  // }, [open, ref.current])


  return (
    <>
      <ModalGeneric open={open} setOpen={setOpen} handleSave={handleSave} disabledSave={!customColumn || isCreatingColumn}>

        <Stack>
          <TextFieldStyled
            autoFocus // doesn't work
            inputRef={callbackRef} // .focus() works very slowly if using useEffect (above)
            // inputRef={(e) => ref.current = e} // .focus() works very slowly if using useEffect (above)
            value={customColumn}
            onChange={(e) => setCustomColumn(e.target.value)}
            onKeyDown={(e) => onKeyDown(e, handleSave)}
            label="New Custom Column"
            fullWidth={true}
            />

          {/* <Typography variant="body2">
            {formatToCamelCase(customColumn)}
          </Typography> */}
        </Stack>

      </ModalGeneric>
    </>
  )
}

{/* <Stack sx={{width: 0.95, margin: "auto"}} >
  {docState == "read" ?
    <ReadNote doc={updatedDoc} />
  : docState == "update" || docState == "create" ?
    <UpdateNote docState={docState} doc={updatedDoc} updatedDoc={updatedDoc} setUpdatedDoc={setUpdatedDoc} />
  : ""
  }
</Stack> */}


// function Content({}) {
//   return (
//     <>
//       <TextField autoFocus />

//       <TextFieldStyled
//         value={customColumn}
//         onChange={(e) => setCustomColumn(e.target.value)}
//         onKeyDown={(e) => onKeyDown(e, handleSave)}
//         label="New Custom Column"
//         fullWidth={true}
//       />

//       <Typography variant="body2">
//         {formatToCamelCase(customColumn)}
//       </Typography>
//     </>
//   )
// }
import { useCallback, useEffect, useRef } from "react";

// source: https://www.youtube.com/watch?v=0c6znExIqRw&ab_channel=WebDevSimplified  (NOTE:  comment about clearing effect for on unmount ?)
// USE
// const [count, setCount] = useState(10)
// (1) const { clear, reset } = useTimeout(() => setCount(0), 1000)
// (2) useTimeout(() => setCount(0), 1000)

export function useTimeout(callback, delay) {
  const callbackRef = useRef(callback)
  const timeoutRef = useRef()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay)
  }, [delay])
  
  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current)
  }, [])

  useEffect(() => {
    set()
    return clear
  }, [delay, set, clear])

  const reset = useCallback(() => {
    clear()
    set()
  }, [clear, set])

  return  {reset, clear }
}
import React, { useEffect, useState } from 'react'
import { Button, Card, CardActions, CardContent, IconButton, Modal, Stack, Typography } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { TypographyError } from './TypographyError'

export function ModalGeneric({
  open,
  setOpen,
  handleClose = () => setOpen(false),

  handleSave = () => {},
  showSave = true,
  labelSave = "Save",
  disabledSave,
  saveErrors = [],

  showCancel = true,

  xButton = true,

  children
}) {

  // function keyPress(e){
  //   if(e.key == "Enter"){
  //     handleSave()
  //   }
  // } // TODO:  esc = exits modal


  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Card
        // sx={{
        //   position: 'absolute',
        //   top: '50%',
        //   left: '50%',
        //   transform: 'translate(-50%, -50%)',
        //   width: 400,
        //   bgcolor: 'background.paper',
        //   boxShadow: 24,
        //   borderRadius: 4,
        //   pt: 3,
        //   px: 3,
        //   pb: 1,
        // }}
        sx={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: {xs: "70%", sm: "400px"},
          width: {sm: "400px"}
          // display: "flex",
        }}
      >
        {/* <CardActionArea>
          {img && 
            <CardMedia
              component="img"
              height="250"
              image={img}
              sx={{
                objectFit: "cover",
                paddingTop: imgPaddingTop,
                paddingBottom: imgPaddingBottom,
                // objectFit: "contain",
              }}
            />
          }
        </CardActionArea> */}
        <CardContent sx={{position: "relative"}}>
          {/* X */}
          {xButton && 
            <IconButton color="inherit" size="medium"
                onClick={() => handleClose()}
                edge="end"
                sx={{opacity: 0.6,
                  position: "absolute", top: "6px", right: "18px", zIndex: 1350
                }}
              >
                <CloseRounded />
            </IconButton>
          }

          <Stack
            sx={{
              mt: 5
            }}
          >
            {children}

            {saveErrors.map((error, i) => <TypographyError i={i}>{error}</TypographyError>)}

            
          </Stack>


        </CardContent>

        <CardActions>
        {/* <CardActions sx={{"& .MuiButton-root": {flexGrow: 1}}}> */}
        {/* <CardActions sx={{borderTop: "#dadce0 0.5px solid", mt: 2, "& .MuiButton-root": {flexGrow: 1}}}> */}
          {showSave && <Button color="primary" sx={{borderRadius: 400}} onClick={handleSave} disabled={disabledSave}>{labelSave}</Button>}
          {showCancel && <Button color="primary" sx={{borderRadius: 400}} onClick={handleClose}>Cancel</Button>}
          {/* <Button color="error" sx={{borderRadius: 400}} onClick={handleDelete}>Delete</Button> */}
        </CardActions>

      </Card>
    </Modal>
  )
}
import { isValidDateStr } from "./isValidDate"


export function filterDates(row, id, filterValue) {
  // console.log("filterDates ran")
  const columnValue = row.getValue(id)

  const isYearOnly = (filterValue) => filterValue.trim().length == 4 && !filterValue.includes("-") && !filterValue.includes("/")

  if (!filterValue[0] && !filterValue[1]) {
    return true
  }
  
  if (filterValue[0] && !filterValue[1]) {
    if (isYearOnly(filterValue[0])) return columnValue.includes(filterValue[0])
    return isDateOrLater(columnValue, filterValue[0])
  }
  if (!filterValue[0] && filterValue[1]) {
    if (isYearOnly(filterValue[1])) return columnValue.includes(filterValue[1])
    return isDateOrEarlier(columnValue, filterValue[1])
  }
  
  if (filterValue[0] && filterValue[1]) {
    return isWithinDateRange(columnValue, filterValue[0], filterValue[1])
  }
}


export function isDateOrLater(date, fromDate) {
  const isValidColumnDate = isValidDateStr(date)
  const isValidFromDate = isValidDateStr(fromDate)
  if (!isValidColumnDate) return false
  if (!isValidFromDate) return true

  date = new Date(date)
  fromDate = new Date(fromDate)
  return date.getTime() >= fromDate.getTime()
}

export function isDateOrEarlier(date, toDate) {
  const isValidColumnDate = isValidDateStr(date)
  const isValidToDate = isValidDateStr(toDate)
  if (!isValidColumnDate) return false
  if (!isValidToDate) return true
  
  date = new Date(date)
  toDate = new Date(toDate)
  return date.getTime() <= toDate.getTime()
}

export function isWithinDateRange(date, fromDate, toDate, inclusive = true) {
  return isDateOrLater(date, fromDate) && isDateOrEarlier(date, toDate)
}
